<app-breadcrumb></app-breadcrumb>
<section>
	<div class="gallery">
		<div class="container">
			<div class="row justify-content-center">
				<div class="col-sm-12">
					<div class="gallery-box">
						<div class="gallery-content">
							<div class="row gallery-list" *ngIf="gallery.length == 0 || loading">
								<ng-container *ngFor="let item of getArray(); let i=index">
									<div class="col-sm-12 col-md-6 col-lg-4">
										<div class="card gallery-item">
											<ngx-skeleton-loader count="1" class="card-img-top" appearance="" animation="progress"></ngx-skeleton-loader>
											<div class="card-body">
												<h5 class="card-title">
													<a>
														<ngx-skeleton-loader count="4" appearance="" animation="progress"></ngx-skeleton-loader>
													</a>
												</h5>
											</div>
										</div>
									</div>
								</ng-container>
							</div>
							<div class="row gallery-list" *ngIf="gallery.length > 0 && !loading">
								<ng-container *ngFor="let item of gallery;">
									<div class="col-sm-12 col-md-6 col-lg-4">
										<div class="card gallery-item">
											<ng-container *ngIf="isImage(item.images); else videoTemplate">
												<img class="card-img-top" [src]="getMedia(item.images)" [alt]="item.name">
												<div class="card-body">
													<h5 class="card-title">
														<a [routerLink]="'/gallery/' + httpService.compressSLUG(item.slug)">{{item.name}}</a>
													</h5>
												</div>
											</ng-container>
											<ng-template #videoTemplate>
												<iframe
													height="315"
													[src]="getMedia(item.images)"
													[title]="item.name"
													frameborder="0"
													allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
													allowfullscreen
												></iframe>
												<div class="card-body">
													<h5 class="card-title">
														<a [routerLink]="'/gallery/' + httpService.compressSLUG(item.slug)">{{item.name}}</a>
													</h5>
												</div>
											</ng-template>
										</div>
									</div>
								</ng-container>
							</div>
							<div class="load-more" *ngIf="hasPage">
								<button class="btn btn-md btn-theme" (click)="loadMoreItem($event)">Load More</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
