<!--==============================
    Breadcumb
============================== -->
<div class="breadcumb-wrapper breadcumb-layout1 bg-fluid pt-200 pb-200">
	<div class="container">
		<div class="breadcumb-content">
			<h1 class="breadcumb-title">{{title}}</h1>
			<ul class="breadcumb-menu-style1 mx-auto">
				<ng-container *ngFor="let item of links; let i=index">
					<ng-container *ngIf="item.isLink === true && item.path !== '';">
						<li>
							<a [routerLink]="item.path" routerLinkActive="active">{{item.name}}</a>
						</li>
					</ng-container>
					<ng-container *ngIf="item.isLink === false && item.path === '';">
						<li class="active">{{item.name}}</li>
					</ng-container>
				</ng-container>
			</ul>
		</div>
	</div>
</div>
