import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';

import { EmiterService, HttpService, JsonSchemaService } from 'src/app/services';

@Component({
  selector: 'app-blog-detail',
  templateUrl: './blog-detail.component.html',
  styleUrls: ['./blog-detail.component.scss']
})
export class BlogDetailComponent implements OnInit {
	blog:any = {}
	constructor(
		private metaService: Meta,
		private titleService: Title,
		private schemaService: JsonSchemaService,
		private route: ActivatedRoute,
		public httpService: HttpService,
		private http: HttpClient,
		private router: Router,
		public emmiter:EmiterService
	) { 
		this.router.events.subscribe((event:any)=>{
			const query:any=this.route.snapshot.params;
			if(event instanceof NavigationStart){
				if(query.slug){
					this.slug=this.httpService.decmpressSLUG(query.slug);
				}
			}
			if(event instanceof NavigationEnd){
				if(query.slug){
					this.slug=this.httpService.decmpressSLUG(query.slug);
				}
				this.emmiter.emit("isloading", {type:"isloading", data:true});
				this.getBlogDetail(this.slug);
			}
		})
	}
	
	public slug:any='';

	ngOnInit(): void {
		this.emmiter.emit("isloading",{type:"isloading",data:true});
	}
	
	getBlogDetail(slug: any) {
		let params = {
			slug : slug ? slug : this.slug,
		}
		this.http.post(this.httpService.mainUrl + 'getBlogDetail', params).subscribe((res:any) => {
			if(res.status === '1'){
				this.blog = res.data;
				const meta_description = this.blog.meta_description ? this.blog.meta_description : "";
				const meta_keywords = this.blog.meta_keywords ? this.blog.meta_keywords : "";
				const meta_schema = this.blog.meta_schema ? this.blog.meta_schema : "";
				const meta_subject = this.blog.meta_subject ? this.blog.meta_subject : "";
				const meta_title = this.blog.meta_title ? this.blog.meta_title : "";
				const og_url = this.blog.slug ? 'http://165.232.180.214/divine/' + this.blog.slug : "";
				const og_image = this.blog.image ? this.httpService.imgURL + this.blog.image : "http://165.232.180.214/divine/assets/blog-2-large.png";
				this.blog.image = og_image;

				const data = {
					title: meta_title,
					subject: meta_subject,
					keywords: meta_keywords,
					descrption: meta_description,
					robots: 'index,follow',
					ogUrl: og_url,
					ogTitle: meta_title,
					ogDescription: meta_description,
					ogImage: og_image,
					twTitle: meta_title,
					twDescription: meta_description,
					schema: {
						"@context": "https://schema.org",
						"@id": og_url,
						"@type": "LocalBusiness",
						name: "Divine Migration",
						url: og_url,
						image: og_image,
						telephone: "+1 123 456 7890",
						address: {
							"@type": "PostalAddress",
							streetAddress: "ABC, First Evenue, USA",
							addressLocality: "USA",
							postalCode: "36051",
							addressCountry: "USA",
							addressRegion: "California"
						},
						priceRange: "10000-1000000",
						sameAs: [
							"https://www.facebook.com/rktechnology/",
							"https://www.linkedin.com/company/rk-technology/",
							"https://www.twitter.com/rktechnology"
						],
						openingHoursSpecification: [
							{
								"@type": "OpeningHoursSpecification",
								dayOfWeek: [
									"Monday",
									"Tuesday",
									"Wednesday",
									"Thursday",
									"Friday"
								],
								opens: "09:00",
								closes: "19:00"
							},
							{
								"@type": "OpeningHoursSpecification",
								dayOfWeek: ["Saturday"],
								opens: "09:00",
								closes: "13:00"
							}
						]
					},
				}
				// settings title
				this.titleService.setTitle(data.title)
				// setting json schema
				this.schemaService.removeStructuredData();
				this.schemaService.insertSchema(data.schema)
				if (data.title) {
					this.metaService.updateTag({ name: 'title', content: data.title })
				} else {
					this.metaService.removeTag("name='title'")
				}

				if (data.subject) {
					this.metaService.updateTag({ name: 'subject', content: data.subject })
				} else {
					this.metaService.removeTag("name='subject'")
				}

				if (data.keywords) {
					this.metaService.updateTag({ name: 'keywords', content: data.keywords })
				} else {
					this.metaService.removeTag("name='keywords'")
				}

				if (data.descrption) {
					this.metaService.updateTag({ name: 'description', content: data.descrption })
				} else {
					this.metaService.removeTag("name='description'")
				}

				if (data.robots) {
					this.metaService.updateTag({ name: 'robots', content: data.robots })
				} else {
					this.metaService.updateTag({ name: 'robots', content: "follow,index" })
				}

				if (data.ogUrl) {
					this.metaService.updateTag({ property: 'og:url', content: data.ogUrl })
				} else {
					this.metaService.updateTag({ property: 'og:url', content: this.router.url })
				}

				if (data.ogTitle) {
					this.metaService.updateTag({ property: 'og:title', content: data.ogTitle })
				} else {
					this.metaService.removeTag("property='og:title'")
				}

				if (data.ogDescription) {
					this.metaService.updateTag({ property: 'og:description', content: data.ogDescription })
				} else {
					this.metaService.removeTag("property='og:description'")
				}

				if (data.twTitle) {
					this.metaService.updateTag({ name: 'twitter:title', content: data.twTitle })
				} else {
					this.metaService.removeTag("name='twitter:title'")
				}

				if (data.twDescription) {
					this.metaService.updateTag({ name: 'twitter:description', content: data.twDescription })
				} else {
					this.metaService.removeTag("name='twitter:description'")
				}

				if (data.ogImage) {
					this.metaService.updateTag({ name: 'og:image', content: data.ogImage })
				} else {
					this.metaService.removeTag("name='og:image'")
				}
				if (data.ogImage) {
					this.metaService.updateTag({ name: 'twitter:image', content: data.ogImage })
				} else {
					this.metaService.removeTag("name='twitter:image'")
				}
			}
		})
	}

}