import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { HttpService, ToastService } from 'src/app/services';
@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
	public serviceSlider: any = [];
	public serviceISlider: any = [];
	currentYear = new Date().getFullYear();
	constructor(
		public httpService: HttpService,
		public toaster: ToastService,
		private http: HttpClient,
		
	) {}
	public contactForm = new FormGroup({
		name: new FormControl(null),
		email: new FormControl(null),
		subject: new FormControl(null),
		mobile: new FormControl(null),
		message: new FormControl(null)
	});
	perPageBlog: number = 4;
	perPage: number = 6;
	hasPage: boolean = true;
	pageNo: number = 1;
	ngOnInit(): void {
		this.getService();
		this.getIService();
	}
	getService() {
		this.http
			.post(this.httpService.mainUrl + 'getServices', {
				pageNo: this.pageNo,
				perPage: this.perPage,
			})
			.subscribe((res: any) => {
				if (res.status === '1') {
					const data =
						res.data && Array.isArray(res.data) ? res.data : [];
					
						data.map((item: any) => {
							if(item.type === true){

								this.serviceSlider.push(item);
							}
						});
					
				}
			});
	}
	getIService() {
		this.http
			.post(this.httpService.mainUrl + 'getServices', {
				pageNo: this.pageNo,
				perPage: this.perPage,
			})
			.subscribe((res: any) => {
				if (res.status === '1') {
					const data =
						res.data && Array.isArray(res.data) ? res.data : [];
					
						data.map((item: any) => {
							if(item.type === false){

								this.serviceISlider.push(item);
							}
						});
					
				}
			});
	}
	submitForm(event:any){
		event.preventDefault();
		if(!this.contactForm.valid){
			this.toaster.generateToast('info', 'Validation Error!', "Please fill out the form!");
			return
		}
		this.http.post(this.httpService.mainUrl + 'submitQuery', this.contactForm.value).subscribe((res:any) => {
			if(res.status === '1'){
				this.toaster.generateToast('success', 'Success!', res.message);
				this.contactForm.patchValue({
					name: "",
					email: "",
					subject: "",
					mobile: "",
					message: "",
				})
			}else {
				this.toaster.generateToast('warning', 'Failed!', res.message);
			}
		})

	}
}
