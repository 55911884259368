import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { HttpService, ToastService } from 'src/app/services';
const dummyData:any = [
	{
		"_id": "625fe9c365808f3e69db8941",
		"images": [
			{
				"image": "y7MW7d8fb1Y",
				"type": "video"
			},
			{
				"image": "Gallery/1O95aC.png",
				"type": "image"
			},
			{
				"image": "Gallery/y6z8rl.jpeg",
				"type": "image"
			},
			{
				"image": "Gallery/5CIcD7.jpeg",
				"type": "image"
			}
		],
		"status": true,
		"name": "Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. Pellentesque in ipsum id orci porta dapibus.",
		"slug": "curabitur-non-nulla-sit-amet-nisl-tempus-convallis-quis-ac-lectus.-pellentesque-in-ipsum-id-orci-porta-dapibus.",
		"createdAt": "2022-04-20T11:08:51.809Z",
		"updatedAt": "2022-04-22T07:33:35.181Z",
		"meta": {
			"title": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
			"description": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
			"keywords": "Gallery, Divine Gallery, Photo Gallery, Video Gallery",
			"image": "http://165.232.180.214:8081/Gallery/1O95aC.png",
			"canonical": "https://www.divine.eu/service/curabitur-non-nulla-sit-amet-nisl-tempus-convallis-quis-ac-lectus.-pellentesque-in-ipsum-id-orci-porta-dapibus.",
			"subject": "",
			"url": "https://www.divine.eu/service/curabitur-non-nulla-sit-amet-nisl-tempus-convallis-quis-ac-lectus.-pellentesque-in-ipsum-id-orci-porta-dapibus.",
			"og": {
				"title": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
				"description": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
				"url": "https://www.divine.eu/service/curabitur-non-nulla-sit-amet-nisl-tempus-convallis-quis-ac-lectus.-pellentesque-in-ipsum-id-orci-porta-dapibus.",
				"image": "http://165.232.180.214:8081/Gallery/1O95aC.png"
			},
			"twitter": {
				"card": "",
				"title": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
				"description": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
				"image": "http://165.232.180.214:8081/Gallery/1O95aC.png",
				"image_alt": "undefined"
			}
		}
	},
	{
		"_id": "625fe9c365808f3e69db8941",
		"images": [

			{
				"image": "Gallery/1O95aC.png",
				"type": "image"
			},
			{
				"image": "Gallery/y6z8rl.jpeg",
				"type": "image"
			},
			{
				"image": "y7MW7d8fb1Y",
				"type": "video"
			},
			{
				"image": "Gallery/5CIcD7.jpeg",
				"type": "image"
			}
		],
		"status": true,
		"name": "Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. Pellentesque in ipsum id orci porta dapibus.",
		"slug": "curabitur-non-nulla-sit-amet-nisl-tempus-convallis-quis-ac-lectus.-pellentesque-in-ipsum-id-orci-porta-dapibus.",
		"createdAt": "2022-04-20T11:08:51.809Z",
		"updatedAt": "2022-04-22T07:33:35.181Z",
		"meta": {
			"title": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
			"description": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
			"keywords": "Gallery, Divine Gallery, Photo Gallery, Video Gallery",
			"image": "http://165.232.180.214:8081/Gallery/1O95aC.png",
			"canonical": "https://www.divine.eu/service/curabitur-non-nulla-sit-amet-nisl-tempus-convallis-quis-ac-lectus.-pellentesque-in-ipsum-id-orci-porta-dapibus.",
			"subject": "",
			"url": "https://www.divine.eu/service/curabitur-non-nulla-sit-amet-nisl-tempus-convallis-quis-ac-lectus.-pellentesque-in-ipsum-id-orci-porta-dapibus.",
			"og": {
				"title": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
				"description": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
				"url": "https://www.divine.eu/service/curabitur-non-nulla-sit-amet-nisl-tempus-convallis-quis-ac-lectus.-pellentesque-in-ipsum-id-orci-porta-dapibus.",
				"image": "http://165.232.180.214:8081/Gallery/1O95aC.png"
			},
			"twitter": {
				"card": "",
				"title": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
				"description": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
				"image": "http://165.232.180.214:8081/Gallery/1O95aC.png",
				"image_alt": "undefined"
			}
		}
	},
	{
		"_id": "625fe9c365808f3e69db8941",
		"images": [
			{
				"image": "Gallery/1O95aC.png",
				"type": "image"
			},
			{
				"image": "Gallery/y6z8rl.jpeg",
				"type": "image"
			},
			{
				"image": "Gallery/5CIcD7.jpeg",
				"type": "image"
			},
			{
				"image": "y7MW7d8fb1Y",
				"type": "video"
			},
		],
		"status": true,
		"name": "Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. Pellentesque in ipsum id orci porta dapibus.",
		"slug": "curabitur-non-nulla-sit-amet-nisl-tempus-convallis-quis-ac-lectus.-pellentesque-in-ipsum-id-orci-porta-dapibus.",
		"createdAt": "2022-04-20T11:08:51.809Z",
		"updatedAt": "2022-04-22T07:33:35.181Z",
		"meta": {
			"title": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
			"description": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
			"keywords": "Gallery, Divine Gallery, Photo Gallery, Video Gallery",
			"image": "http://165.232.180.214:8081/Gallery/1O95aC.png",
			"canonical": "https://www.divine.eu/service/curabitur-non-nulla-sit-amet-nisl-tempus-convallis-quis-ac-lectus.-pellentesque-in-ipsum-id-orci-porta-dapibus.",
			"subject": "",
			"url": "https://www.divine.eu/service/curabitur-non-nulla-sit-amet-nisl-tempus-convallis-quis-ac-lectus.-pellentesque-in-ipsum-id-orci-porta-dapibus.",
			"og": {
				"title": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
				"description": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
				"url": "https://www.divine.eu/service/curabitur-non-nulla-sit-amet-nisl-tempus-convallis-quis-ac-lectus.-pellentesque-in-ipsum-id-orci-porta-dapibus.",
				"image": "http://165.232.180.214:8081/Gallery/1O95aC.png"
			},
			"twitter": {
				"card": "",
				"title": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
				"description": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
				"image": "http://165.232.180.214:8081/Gallery/1O95aC.png",
				"image_alt": "undefined"
			}
		}
	},
	{
		"_id": "625fe9c365808f3e69db8941",
		"images": [
			{
				"image": "Gallery/y6z8rl.jpeg",
				"type": "image"
			},
			{
				"image": "Gallery/1O95aC.png",
				"type": "image"
			},
			{
				"image": "y7MW7d8fb1Y",
				"type": "video"
			},
			{
				"image": "Gallery/5CIcD7.jpeg",
				"type": "image"
			},

		],
		"status": true,
		"name": "Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. Pellentesque in ipsum id orci porta dapibus.",
		"slug": "curabitur-non-nulla-sit-amet-nisl-tempus-convallis-quis-ac-lectus.-pellentesque-in-ipsum-id-orci-porta-dapibus.",
		"createdAt": "2022-04-20T11:08:51.809Z",
		"updatedAt": "2022-04-22T07:33:35.181Z",
		"meta": {
			"title": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
			"description": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
			"keywords": "Gallery, Divine Gallery, Photo Gallery, Video Gallery",
			"image": "http://165.232.180.214:8081/Gallery/1O95aC.png",
			"canonical": "https://www.divine.eu/service/curabitur-non-nulla-sit-amet-nisl-tempus-convallis-quis-ac-lectus.-pellentesque-in-ipsum-id-orci-porta-dapibus.",
			"subject": "",
			"url": "https://www.divine.eu/service/curabitur-non-nulla-sit-amet-nisl-tempus-convallis-quis-ac-lectus.-pellentesque-in-ipsum-id-orci-porta-dapibus.",
			"og": {
				"title": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
				"description": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
				"url": "https://www.divine.eu/service/curabitur-non-nulla-sit-amet-nisl-tempus-convallis-quis-ac-lectus.-pellentesque-in-ipsum-id-orci-porta-dapibus.",
				"image": "http://165.232.180.214:8081/Gallery/1O95aC.png"
			},
			"twitter": {
				"card": "",
				"title": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
				"description": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
				"image": "http://165.232.180.214:8081/Gallery/1O95aC.png",
				"image_alt": "undefined"
			}
		}
	},
	{
		"_id": "625fe9c365808f3e69db8941",
		"images": [
			{
				"image": "Gallery/5CIcD7.jpeg",
				"type": "image"
			},
			{
				"image": "y7MW7d8fb1Y",
				"type": "video"
			},
			{
				"image": "Gallery/1O95aC.png",
				"type": "image"
			},
			{
				"image": "Gallery/y6z8rl.jpeg",
				"type": "image"
			},

		],
		"status": true,
		"name": "Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. Pellentesque in ipsum id orci porta dapibus.",
		"slug": "curabitur-non-nulla-sit-amet-nisl-tempus-convallis-quis-ac-lectus.-pellentesque-in-ipsum-id-orci-porta-dapibus.",
		"createdAt": "2022-04-20T11:08:51.809Z",
		"updatedAt": "2022-04-22T07:33:35.181Z",
		"meta": {
			"title": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
			"description": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
			"keywords": "Gallery, Divine Gallery, Photo Gallery, Video Gallery",
			"image": "http://165.232.180.214:8081/Gallery/1O95aC.png",
			"canonical": "https://www.divine.eu/service/curabitur-non-nulla-sit-amet-nisl-tempus-convallis-quis-ac-lectus.-pellentesque-in-ipsum-id-orci-porta-dapibus.",
			"subject": "",
			"url": "https://www.divine.eu/service/curabitur-non-nulla-sit-amet-nisl-tempus-convallis-quis-ac-lectus.-pellentesque-in-ipsum-id-orci-porta-dapibus.",
			"og": {
				"title": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
				"description": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
				"url": "https://www.divine.eu/service/curabitur-non-nulla-sit-amet-nisl-tempus-convallis-quis-ac-lectus.-pellentesque-in-ipsum-id-orci-porta-dapibus.",
				"image": "http://165.232.180.214:8081/Gallery/1O95aC.png"
			},
			"twitter": {
				"card": "",
				"title": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
				"description": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
				"image": "http://165.232.180.214:8081/Gallery/1O95aC.png",
				"image_alt": "undefined"
			}
		}
	},
	{
		"_id": "625fe9c365808f3e69db8941",
		"images": [
			{
				"image": "y7MW7d8fb1Y",
				"type": "video"
			},
			{
				"image": "Gallery/1O95aC.png",
				"type": "image"
			},
			{
				"image": "Gallery/y6z8rl.jpeg",
				"type": "image"
			},
			{
				"image": "Gallery/5CIcD7.jpeg",
				"type": "image"
			}
		],
		"status": true,
		"name": "Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. Pellentesque in ipsum id orci porta dapibus.",
		"slug": "curabitur-non-nulla-sit-amet-nisl-tempus-convallis-quis-ac-lectus.-pellentesque-in-ipsum-id-orci-porta-dapibus.",
		"createdAt": "2022-04-20T11:08:51.809Z",
		"updatedAt": "2022-04-22T07:33:35.181Z",
		"meta": {
			"title": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
			"description": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
			"keywords": "Gallery, Divine Gallery, Photo Gallery, Video Gallery",
			"image": "http://165.232.180.214:8081/Gallery/1O95aC.png",
			"canonical": "https://www.divine.eu/service/curabitur-non-nulla-sit-amet-nisl-tempus-convallis-quis-ac-lectus.-pellentesque-in-ipsum-id-orci-porta-dapibus.",
			"subject": "",
			"url": "https://www.divine.eu/service/curabitur-non-nulla-sit-amet-nisl-tempus-convallis-quis-ac-lectus.-pellentesque-in-ipsum-id-orci-porta-dapibus.",
			"og": {
				"title": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
				"description": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
				"url": "https://www.divine.eu/service/curabitur-non-nulla-sit-amet-nisl-tempus-convallis-quis-ac-lectus.-pellentesque-in-ipsum-id-orci-porta-dapibus.",
				"image": "http://165.232.180.214:8081/Gallery/1O95aC.png"
			},
			"twitter": {
				"card": "",
				"title": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
				"description": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
				"image": "http://165.232.180.214:8081/Gallery/1O95aC.png",
				"image_alt": "undefined"
			}
		}
	},
	{
		"_id": "625fe9c365808f3e69db8941",
		"images": [
			{
				"image": "y7MW7d8fb1Y",
				"type": "video"
			},
			{
				"image": "Gallery/1O95aC.png",
				"type": "image"
			},
			{
				"image": "Gallery/y6z8rl.jpeg",
				"type": "image"
			},
			{
				"image": "Gallery/5CIcD7.jpeg",
				"type": "image"
			}
		],
		"status": true,
		"name": "Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. Pellentesque in ipsum id orci porta dapibus.",
		"slug": "curabitur-non-nulla-sit-amet-nisl-tempus-convallis-quis-ac-lectus.-pellentesque-in-ipsum-id-orci-porta-dapibus.",
		"createdAt": "2022-04-20T11:08:51.809Z",
		"updatedAt": "2022-04-22T07:33:35.181Z",
		"meta": {
			"title": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
			"description": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
			"keywords": "Gallery, Divine Gallery, Photo Gallery, Video Gallery",
			"image": "http://165.232.180.214:8081/Gallery/1O95aC.png",
			"canonical": "https://www.divine.eu/service/curabitur-non-nulla-sit-amet-nisl-tempus-convallis-quis-ac-lectus.-pellentesque-in-ipsum-id-orci-porta-dapibus.",
			"subject": "",
			"url": "https://www.divine.eu/service/curabitur-non-nulla-sit-amet-nisl-tempus-convallis-quis-ac-lectus.-pellentesque-in-ipsum-id-orci-porta-dapibus.",
			"og": {
				"title": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
				"description": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
				"url": "https://www.divine.eu/service/curabitur-non-nulla-sit-amet-nisl-tempus-convallis-quis-ac-lectus.-pellentesque-in-ipsum-id-orci-porta-dapibus.",
				"image": "http://165.232.180.214:8081/Gallery/1O95aC.png"
			},
			"twitter": {
				"card": "",
				"title": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
				"description": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
				"image": "http://165.232.180.214:8081/Gallery/1O95aC.png",
				"image_alt": "undefined"
			}
		}
	},
	{
		"_id": "625fe9c365808f3e69db8941",
		"images": [

			{
				"image": "Gallery/1O95aC.png",
				"type": "image"
			},
			{
				"image": "Gallery/y6z8rl.jpeg",
				"type": "image"
			},
			{
				"image": "y7MW7d8fb1Y",
				"type": "video"
			},
			{
				"image": "Gallery/5CIcD7.jpeg",
				"type": "image"
			}
		],
		"status": true,
		"name": "Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. Pellentesque in ipsum id orci porta dapibus.",
		"slug": "curabitur-non-nulla-sit-amet-nisl-tempus-convallis-quis-ac-lectus.-pellentesque-in-ipsum-id-orci-porta-dapibus.",
		"createdAt": "2022-04-20T11:08:51.809Z",
		"updatedAt": "2022-04-22T07:33:35.181Z",
		"meta": {
			"title": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
			"description": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
			"keywords": "Gallery, Divine Gallery, Photo Gallery, Video Gallery",
			"image": "http://165.232.180.214:8081/Gallery/1O95aC.png",
			"canonical": "https://www.divine.eu/service/curabitur-non-nulla-sit-amet-nisl-tempus-convallis-quis-ac-lectus.-pellentesque-in-ipsum-id-orci-porta-dapibus.",
			"subject": "",
			"url": "https://www.divine.eu/service/curabitur-non-nulla-sit-amet-nisl-tempus-convallis-quis-ac-lectus.-pellentesque-in-ipsum-id-orci-porta-dapibus.",
			"og": {
				"title": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
				"description": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
				"url": "https://www.divine.eu/service/curabitur-non-nulla-sit-amet-nisl-tempus-convallis-quis-ac-lectus.-pellentesque-in-ipsum-id-orci-porta-dapibus.",
				"image": "http://165.232.180.214:8081/Gallery/1O95aC.png"
			},
			"twitter": {
				"card": "",
				"title": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
				"description": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
				"image": "http://165.232.180.214:8081/Gallery/1O95aC.png",
				"image_alt": "undefined"
			}
		}
	},
	{
		"_id": "625fe9c365808f3e69db8941",
		"images": [
			{
				"image": "Gallery/1O95aC.png",
				"type": "image"
			},
			{
				"image": "Gallery/y6z8rl.jpeg",
				"type": "image"
			},
			{
				"image": "Gallery/5CIcD7.jpeg",
				"type": "image"
			},
			{
				"image": "y7MW7d8fb1Y",
				"type": "video"
			},
		],
		"status": true,
		"name": "Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. Pellentesque in ipsum id orci porta dapibus.",
		"slug": "curabitur-non-nulla-sit-amet-nisl-tempus-convallis-quis-ac-lectus.-pellentesque-in-ipsum-id-orci-porta-dapibus.",
		"createdAt": "2022-04-20T11:08:51.809Z",
		"updatedAt": "2022-04-22T07:33:35.181Z",
		"meta": {
			"title": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
			"description": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
			"keywords": "Gallery, Divine Gallery, Photo Gallery, Video Gallery",
			"image": "http://165.232.180.214:8081/Gallery/1O95aC.png",
			"canonical": "https://www.divine.eu/service/curabitur-non-nulla-sit-amet-nisl-tempus-convallis-quis-ac-lectus.-pellentesque-in-ipsum-id-orci-porta-dapibus.",
			"subject": "",
			"url": "https://www.divine.eu/service/curabitur-non-nulla-sit-amet-nisl-tempus-convallis-quis-ac-lectus.-pellentesque-in-ipsum-id-orci-porta-dapibus.",
			"og": {
				"title": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
				"description": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
				"url": "https://www.divine.eu/service/curabitur-non-nulla-sit-amet-nisl-tempus-convallis-quis-ac-lectus.-pellentesque-in-ipsum-id-orci-porta-dapibus.",
				"image": "http://165.232.180.214:8081/Gallery/1O95aC.png"
			},
			"twitter": {
				"card": "",
				"title": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
				"description": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
				"image": "http://165.232.180.214:8081/Gallery/1O95aC.png",
				"image_alt": "undefined"
			}
		}
	},
	{
		"_id": "625fe9c365808f3e69db8941",
		"images": [
			{
				"image": "Gallery/y6z8rl.jpeg",
				"type": "image"
			},
			{
				"image": "Gallery/1O95aC.png",
				"type": "image"
			},
			{
				"image": "y7MW7d8fb1Y",
				"type": "video"
			},
			{
				"image": "Gallery/5CIcD7.jpeg",
				"type": "image"
			},

		],
		"status": true,
		"name": "Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. Pellentesque in ipsum id orci porta dapibus.",
		"slug": "curabitur-non-nulla-sit-amet-nisl-tempus-convallis-quis-ac-lectus.-pellentesque-in-ipsum-id-orci-porta-dapibus.",
		"createdAt": "2022-04-20T11:08:51.809Z",
		"updatedAt": "2022-04-22T07:33:35.181Z",
		"meta": {
			"title": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
			"description": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
			"keywords": "Gallery, Divine Gallery, Photo Gallery, Video Gallery",
			"image": "http://165.232.180.214:8081/Gallery/1O95aC.png",
			"canonical": "https://www.divine.eu/service/curabitur-non-nulla-sit-amet-nisl-tempus-convallis-quis-ac-lectus.-pellentesque-in-ipsum-id-orci-porta-dapibus.",
			"subject": "",
			"url": "https://www.divine.eu/service/curabitur-non-nulla-sit-amet-nisl-tempus-convallis-quis-ac-lectus.-pellentesque-in-ipsum-id-orci-porta-dapibus.",
			"og": {
				"title": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
				"description": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
				"url": "https://www.divine.eu/service/curabitur-non-nulla-sit-amet-nisl-tempus-convallis-quis-ac-lectus.-pellentesque-in-ipsum-id-orci-porta-dapibus.",
				"image": "http://165.232.180.214:8081/Gallery/1O95aC.png"
			},
			"twitter": {
				"card": "",
				"title": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
				"description": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
				"image": "http://165.232.180.214:8081/Gallery/1O95aC.png",
				"image_alt": "undefined"
			}
		}
	},
	{
		"_id": "625fe9c365808f3e69db8941",
		"images": [
			{
				"image": "Gallery/5CIcD7.jpeg",
				"type": "image"
			},
			{
				"image": "y7MW7d8fb1Y",
				"type": "video"
			},
			{
				"image": "Gallery/1O95aC.png",
				"type": "image"
			},
			{
				"image": "Gallery/y6z8rl.jpeg",
				"type": "image"
			},

		],
		"status": true,
		"name": "Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. Pellentesque in ipsum id orci porta dapibus.",
		"slug": "curabitur-non-nulla-sit-amet-nisl-tempus-convallis-quis-ac-lectus.-pellentesque-in-ipsum-id-orci-porta-dapibus.",
		"createdAt": "2022-04-20T11:08:51.809Z",
		"updatedAt": "2022-04-22T07:33:35.181Z",
		"meta": {
			"title": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
			"description": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
			"keywords": "Gallery, Divine Gallery, Photo Gallery, Video Gallery",
			"image": "http://165.232.180.214:8081/Gallery/1O95aC.png",
			"canonical": "https://www.divine.eu/service/curabitur-non-nulla-sit-amet-nisl-tempus-convallis-quis-ac-lectus.-pellentesque-in-ipsum-id-orci-porta-dapibus.",
			"subject": "",
			"url": "https://www.divine.eu/service/curabitur-non-nulla-sit-amet-nisl-tempus-convallis-quis-ac-lectus.-pellentesque-in-ipsum-id-orci-porta-dapibus.",
			"og": {
				"title": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
				"description": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
				"url": "https://www.divine.eu/service/curabitur-non-nulla-sit-amet-nisl-tempus-convallis-quis-ac-lectus.-pellentesque-in-ipsum-id-orci-porta-dapibus.",
				"image": "http://165.232.180.214:8081/Gallery/1O95aC.png"
			},
			"twitter": {
				"card": "",
				"title": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
				"description": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
				"image": "http://165.232.180.214:8081/Gallery/1O95aC.png",
				"image_alt": "undefined"
			}
		}
	},
	{
		"_id": "625fe9c365808f3e69db8941",
		"images": [
			{
				"image": "y7MW7d8fb1Y",
				"type": "video"
			},
			{
				"image": "Gallery/1O95aC.png",
				"type": "image"
			},
			{
				"image": "Gallery/y6z8rl.jpeg",
				"type": "image"
			},
			{
				"image": "Gallery/5CIcD7.jpeg",
				"type": "image"
			}
		],
		"status": true,
		"name": "Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. Pellentesque in ipsum id orci porta dapibus.",
		"slug": "curabitur-non-nulla-sit-amet-nisl-tempus-convallis-quis-ac-lectus.-pellentesque-in-ipsum-id-orci-porta-dapibus.",
		"createdAt": "2022-04-20T11:08:51.809Z",
		"updatedAt": "2022-04-22T07:33:35.181Z",
		"meta": {
			"title": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
			"description": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
			"keywords": "Gallery, Divine Gallery, Photo Gallery, Video Gallery",
			"image": "http://165.232.180.214:8081/Gallery/1O95aC.png",
			"canonical": "https://www.divine.eu/service/curabitur-non-nulla-sit-amet-nisl-tempus-convallis-quis-ac-lectus.-pellentesque-in-ipsum-id-orci-porta-dapibus.",
			"subject": "",
			"url": "https://www.divine.eu/service/curabitur-non-nulla-sit-amet-nisl-tempus-convallis-quis-ac-lectus.-pellentesque-in-ipsum-id-orci-porta-dapibus.",
			"og": {
				"title": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
				"description": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
				"url": "https://www.divine.eu/service/curabitur-non-nulla-sit-amet-nisl-tempus-convallis-quis-ac-lectus.-pellentesque-in-ipsum-id-orci-porta-dapibus.",
				"image": "http://165.232.180.214:8081/Gallery/1O95aC.png"
			},
			"twitter": {
				"card": "",
				"title": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
				"description": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
				"image": "http://165.232.180.214:8081/Gallery/1O95aC.png",
				"image_alt": "undefined"
			}
		}
	},
];

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {
	gallery:any = [
		// {
		// 	"_id": "625fe9c365808f3e69db8941",
		// 	"images": [
		// 		{
		// 			"image": "y7MW7d8fb1Y",
		// 			"type": "video"
		// 		},
		// 		{
		// 			"image": "Gallery/1O95aC.png",
		// 			"type": "image"
		// 		},
		// 		{
		// 			"image": "Gallery/y6z8rl.jpeg",
		// 			"type": "image"
		// 		},
		// 		{
		// 			"image": "Gallery/5CIcD7.jpeg",
		// 			"type": "image"
		// 		}
		// 	],
		// 	"status": true,
		// 	"name": "Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. Pellentesque in ipsum id orci porta dapibus.",
		// 	"slug": "curabitur-non-nulla-sit-amet-nisl-tempus-convallis-quis-ac-lectus.-pellentesque-in-ipsum-id-orci-porta-dapibus.",
		// 	"createdAt": "2022-04-20T11:08:51.809Z",
		// 	"updatedAt": "2022-04-22T07:33:35.181Z",
		// 	"meta": {
		// 		"title": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
		// 		"description": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
		// 		"keywords": "Gallery, Divine Gallery, Photo Gallery, Video Gallery",
		// 		"image": "http://165.232.180.214:8081/Gallery/1O95aC.png",
		// 		"canonical": "https://www.divine.eu/service/curabitur-non-nulla-sit-amet-nisl-tempus-convallis-quis-ac-lectus.-pellentesque-in-ipsum-id-orci-porta-dapibus.",
		// 		"subject": "",
		// 		"url": "https://www.divine.eu/service/curabitur-non-nulla-sit-amet-nisl-tempus-convallis-quis-ac-lectus.-pellentesque-in-ipsum-id-orci-porta-dapibus.",
		// 		"og": {
		// 			"title": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
		// 			"description": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
		// 			"url": "https://www.divine.eu/service/curabitur-non-nulla-sit-amet-nisl-tempus-convallis-quis-ac-lectus.-pellentesque-in-ipsum-id-orci-porta-dapibus.",
		// 			"image": "http://165.232.180.214:8081/Gallery/1O95aC.png"
		// 		},
		// 		"twitter": {
		// 			"card": "",
		// 			"title": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
		// 			"description": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
		// 			"image": "http://165.232.180.214:8081/Gallery/1O95aC.png",
		// 			"image_alt": "undefined"
		// 		}
		// 	}
		// },
		// {
		// 	"_id": "625fe9c365808f3e69db8941",
		// 	"images": [

		// 		{
		// 			"image": "Gallery/1O95aC.png",
		// 			"type": "image"
		// 		},
		// 		{
		// 			"image": "Gallery/y6z8rl.jpeg",
		// 			"type": "image"
		// 		},
		// 		{
		// 			"image": "y7MW7d8fb1Y",
		// 			"type": "video"
		// 		},
		// 		{
		// 			"image": "Gallery/5CIcD7.jpeg",
		// 			"type": "image"
		// 		}
		// 	],
		// 	"status": true,
		// 	"name": "Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. Pellentesque in ipsum id orci porta dapibus.",
		// 	"slug": "curabitur-non-nulla-sit-amet-nisl-tempus-convallis-quis-ac-lectus.-pellentesque-in-ipsum-id-orci-porta-dapibus.",
		// 	"createdAt": "2022-04-20T11:08:51.809Z",
		// 	"updatedAt": "2022-04-22T07:33:35.181Z",
		// 	"meta": {
		// 		"title": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
		// 		"description": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
		// 		"keywords": "Gallery, Divine Gallery, Photo Gallery, Video Gallery",
		// 		"image": "http://165.232.180.214:8081/Gallery/1O95aC.png",
		// 		"canonical": "https://www.divine.eu/service/curabitur-non-nulla-sit-amet-nisl-tempus-convallis-quis-ac-lectus.-pellentesque-in-ipsum-id-orci-porta-dapibus.",
		// 		"subject": "",
		// 		"url": "https://www.divine.eu/service/curabitur-non-nulla-sit-amet-nisl-tempus-convallis-quis-ac-lectus.-pellentesque-in-ipsum-id-orci-porta-dapibus.",
		// 		"og": {
		// 			"title": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
		// 			"description": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
		// 			"url": "https://www.divine.eu/service/curabitur-non-nulla-sit-amet-nisl-tempus-convallis-quis-ac-lectus.-pellentesque-in-ipsum-id-orci-porta-dapibus.",
		// 			"image": "http://165.232.180.214:8081/Gallery/1O95aC.png"
		// 		},
		// 		"twitter": {
		// 			"card": "",
		// 			"title": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
		// 			"description": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
		// 			"image": "http://165.232.180.214:8081/Gallery/1O95aC.png",
		// 			"image_alt": "undefined"
		// 		}
		// 	}
		// },
		// {
		// 	"_id": "625fe9c365808f3e69db8941",
		// 	"images": [
		// 		{
		// 			"image": "Gallery/1O95aC.png",
		// 			"type": "image"
		// 		},
		// 		{
		// 			"image": "Gallery/y6z8rl.jpeg",
		// 			"type": "image"
		// 		},
		// 		{
		// 			"image": "Gallery/5CIcD7.jpeg",
		// 			"type": "image"
		// 		},
		// 		{
		// 			"image": "y7MW7d8fb1Y",
		// 			"type": "video"
		// 		},
		// 	],
		// 	"status": true,
		// 	"name": "Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. Pellentesque in ipsum id orci porta dapibus.",
		// 	"slug": "curabitur-non-nulla-sit-amet-nisl-tempus-convallis-quis-ac-lectus.-pellentesque-in-ipsum-id-orci-porta-dapibus.",
		// 	"createdAt": "2022-04-20T11:08:51.809Z",
		// 	"updatedAt": "2022-04-22T07:33:35.181Z",
		// 	"meta": {
		// 		"title": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
		// 		"description": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
		// 		"keywords": "Gallery, Divine Gallery, Photo Gallery, Video Gallery",
		// 		"image": "http://165.232.180.214:8081/Gallery/1O95aC.png",
		// 		"canonical": "https://www.divine.eu/service/curabitur-non-nulla-sit-amet-nisl-tempus-convallis-quis-ac-lectus.-pellentesque-in-ipsum-id-orci-porta-dapibus.",
		// 		"subject": "",
		// 		"url": "https://www.divine.eu/service/curabitur-non-nulla-sit-amet-nisl-tempus-convallis-quis-ac-lectus.-pellentesque-in-ipsum-id-orci-porta-dapibus.",
		// 		"og": {
		// 			"title": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
		// 			"description": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
		// 			"url": "https://www.divine.eu/service/curabitur-non-nulla-sit-amet-nisl-tempus-convallis-quis-ac-lectus.-pellentesque-in-ipsum-id-orci-porta-dapibus.",
		// 			"image": "http://165.232.180.214:8081/Gallery/1O95aC.png"
		// 		},
		// 		"twitter": {
		// 			"card": "",
		// 			"title": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
		// 			"description": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
		// 			"image": "http://165.232.180.214:8081/Gallery/1O95aC.png",
		// 			"image_alt": "undefined"
		// 		}
		// 	}
		// },
		// {
		// 	"_id": "625fe9c365808f3e69db8941",
		// 	"images": [
		// 		{
		// 			"image": "Gallery/y6z8rl.jpeg",
		// 			"type": "image"
		// 		},
		// 		{
		// 			"image": "Gallery/1O95aC.png",
		// 			"type": "image"
		// 		},
		// 		{
		// 			"image": "y7MW7d8fb1Y",
		// 			"type": "video"
		// 		},
		// 		{
		// 			"image": "Gallery/5CIcD7.jpeg",
		// 			"type": "image"
		// 		},

		// 	],
		// 	"status": true,
		// 	"name": "Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. Pellentesque in ipsum id orci porta dapibus.",
		// 	"slug": "curabitur-non-nulla-sit-amet-nisl-tempus-convallis-quis-ac-lectus.-pellentesque-in-ipsum-id-orci-porta-dapibus.",
		// 	"createdAt": "2022-04-20T11:08:51.809Z",
		// 	"updatedAt": "2022-04-22T07:33:35.181Z",
		// 	"meta": {
		// 		"title": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
		// 		"description": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
		// 		"keywords": "Gallery, Divine Gallery, Photo Gallery, Video Gallery",
		// 		"image": "http://165.232.180.214:8081/Gallery/1O95aC.png",
		// 		"canonical": "https://www.divine.eu/service/curabitur-non-nulla-sit-amet-nisl-tempus-convallis-quis-ac-lectus.-pellentesque-in-ipsum-id-orci-porta-dapibus.",
		// 		"subject": "",
		// 		"url": "https://www.divine.eu/service/curabitur-non-nulla-sit-amet-nisl-tempus-convallis-quis-ac-lectus.-pellentesque-in-ipsum-id-orci-porta-dapibus.",
		// 		"og": {
		// 			"title": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
		// 			"description": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
		// 			"url": "https://www.divine.eu/service/curabitur-non-nulla-sit-amet-nisl-tempus-convallis-quis-ac-lectus.-pellentesque-in-ipsum-id-orci-porta-dapibus.",
		// 			"image": "http://165.232.180.214:8081/Gallery/1O95aC.png"
		// 		},
		// 		"twitter": {
		// 			"card": "",
		// 			"title": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
		// 			"description": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
		// 			"image": "http://165.232.180.214:8081/Gallery/1O95aC.png",
		// 			"image_alt": "undefined"
		// 		}
		// 	}
		// },
		// {
		// 	"_id": "625fe9c365808f3e69db8941",
		// 	"images": [
		// 		{
		// 			"image": "Gallery/5CIcD7.jpeg",
		// 			"type": "image"
		// 		},
		// 		{
		// 			"image": "y7MW7d8fb1Y",
		// 			"type": "video"
		// 		},
		// 		{
		// 			"image": "Gallery/1O95aC.png",
		// 			"type": "image"
		// 		},
		// 		{
		// 			"image": "Gallery/y6z8rl.jpeg",
		// 			"type": "image"
		// 		},

		// 	],
		// 	"status": true,
		// 	"name": "Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. Pellentesque in ipsum id orci porta dapibus.",
		// 	"slug": "curabitur-non-nulla-sit-amet-nisl-tempus-convallis-quis-ac-lectus.-pellentesque-in-ipsum-id-orci-porta-dapibus.",
		// 	"createdAt": "2022-04-20T11:08:51.809Z",
		// 	"updatedAt": "2022-04-22T07:33:35.181Z",
		// 	"meta": {
		// 		"title": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
		// 		"description": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
		// 		"keywords": "Gallery, Divine Gallery, Photo Gallery, Video Gallery",
		// 		"image": "http://165.232.180.214:8081/Gallery/1O95aC.png",
		// 		"canonical": "https://www.divine.eu/service/curabitur-non-nulla-sit-amet-nisl-tempus-convallis-quis-ac-lectus.-pellentesque-in-ipsum-id-orci-porta-dapibus.",
		// 		"subject": "",
		// 		"url": "https://www.divine.eu/service/curabitur-non-nulla-sit-amet-nisl-tempus-convallis-quis-ac-lectus.-pellentesque-in-ipsum-id-orci-porta-dapibus.",
		// 		"og": {
		// 			"title": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
		// 			"description": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
		// 			"url": "https://www.divine.eu/service/curabitur-non-nulla-sit-amet-nisl-tempus-convallis-quis-ac-lectus.-pellentesque-in-ipsum-id-orci-porta-dapibus.",
		// 			"image": "http://165.232.180.214:8081/Gallery/1O95aC.png"
		// 		},
		// 		"twitter": {
		// 			"card": "",
		// 			"title": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
		// 			"description": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
		// 			"image": "http://165.232.180.214:8081/Gallery/1O95aC.png",
		// 			"image_alt": "undefined"
		// 		}
		// 	}
		// },
		// {
		// 	"_id": "625fe9c365808f3e69db8941",
		// 	"images": [
		// 		{
		// 			"image": "y7MW7d8fb1Y",
		// 			"type": "video"
		// 		},
		// 		{
		// 			"image": "Gallery/1O95aC.png",
		// 			"type": "image"
		// 		},
		// 		{
		// 			"image": "Gallery/y6z8rl.jpeg",
		// 			"type": "image"
		// 		},
		// 		{
		// 			"image": "Gallery/5CIcD7.jpeg",
		// 			"type": "image"
		// 		}
		// 	],
		// 	"status": true,
		// 	"name": "Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. Pellentesque in ipsum id orci porta dapibus.",
		// 	"slug": "curabitur-non-nulla-sit-amet-nisl-tempus-convallis-quis-ac-lectus.-pellentesque-in-ipsum-id-orci-porta-dapibus.",
		// 	"createdAt": "2022-04-20T11:08:51.809Z",
		// 	"updatedAt": "2022-04-22T07:33:35.181Z",
		// 	"meta": {
		// 		"title": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
		// 		"description": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
		// 		"keywords": "Gallery, Divine Gallery, Photo Gallery, Video Gallery",
		// 		"image": "http://165.232.180.214:8081/Gallery/1O95aC.png",
		// 		"canonical": "https://www.divine.eu/service/curabitur-non-nulla-sit-amet-nisl-tempus-convallis-quis-ac-lectus.-pellentesque-in-ipsum-id-orci-porta-dapibus.",
		// 		"subject": "",
		// 		"url": "https://www.divine.eu/service/curabitur-non-nulla-sit-amet-nisl-tempus-convallis-quis-ac-lectus.-pellentesque-in-ipsum-id-orci-porta-dapibus.",
		// 		"og": {
		// 			"title": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
		// 			"description": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
		// 			"url": "https://www.divine.eu/service/curabitur-non-nulla-sit-amet-nisl-tempus-convallis-quis-ac-lectus.-pellentesque-in-ipsum-id-orci-porta-dapibus.",
		// 			"image": "http://165.232.180.214:8081/Gallery/1O95aC.png"
		// 		},
		// 		"twitter": {
		// 			"card": "",
		// 			"title": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
		// 			"description": "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
		// 			"image": "http://165.232.180.214:8081/Gallery/1O95aC.png",
		// 			"image_alt": "undefined"
		// 		}
		// 	}
		// },
	];
	perPage:number = 6;
	pageNo: number = 1;
	loading: boolean = false;
	hasPage: boolean = true;
	constructor(
		public httpService: HttpService,
		public toaster: ToastService,
		private http: HttpClient,
		private metaTagService: Meta,
		private route: ActivatedRoute,
	) { }

	ngOnInit(): void {
		this.getTestimonialItems();
	}

	getArray() {
		const length = this.gallery.length > 0 ? this.gallery.length : 4;
		let array = Array(length).fill(0).map((x,i)=>i+1);
		return array;
	}
	// getDemoData() {
	// 	let i = 3;
	// 	dummyData.map((item:any) => {
	// 		if(i < this.perPage){
	// 			this.gallery.push(dummyData.splice(Math.floor(Math.random() * dummyData.length - 1), 1)[0]);
	// 		} else return;
	// 		i++;
	// 	})
	// 	this.loading = false;
	// }

	getTestimonialItems() {
		// setTimeout(() => {
		// 	this.getDemoData();
		// }, 1500)
		this.http.post(this.httpService.mainUrl + 'getGallery', {pageNo: this.pageNo, perPage: this.perPage}).subscribe((res: any) => {
			if(res.status === '1'){
				const data = res.data && Array.isArray(res.data) ? res.data : [];
				if(data.length < this.perPage){
					this.hasPage = false;
				}
				if(this.pageNo === 1){
					this.gallery = data;
				}else {
					data.map((item:any) => {
						this.gallery.push(item);
					})
				}
			}
		})
	}

	loadMoreItem(event:any){
		this.loading = false;
		event.preventDefault();
		this.pageNo = this.pageNo + 1;
		this.getTestimonialItems();
	}

	isImage(images:any) {
		return images[0].type === 'image' || false;
	}

	getMedia(image:any){
		const media = image[0];
		if(media.type === 'image'){
			return this.httpService.generateImagePreview(media.image)
		}
		if(media.type === 'video'){
			return this.httpService.getSafeURL(`https://www.youtube.com/embed/${media.image}`, 'RESOURCE');
		}
	}

}
