import { Injectable, OnInit, OnDestroy, SecurityContext } from '@angular/core';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import * as reducers from 'src/app/store/reducers';
import * as actions from 'src/app/store/actions';
import {
    Auth,
    imageUrl,
    mainUrl,
    Rights,
    chatUrl,
    Review,
    Cart,
    Order,
    chatApiUrl,
    chatImgUrl
} from 'src/app/store/models';
import { DomSanitizer } from '@angular/platform-browser';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class HttpService implements OnInit, OnDestroy {
	auth = Auth;
	order:any = [];
	cart:any = [];
	rights = Rights;
	imgURL = imageUrl;
	mainUrl = mainUrl;
	chatUrl = chatUrl;
	session:any;
	isAuthenticated:boolean = false;
	stateSubscription: Subscription;
	constructor(
		public store: Store<reducers.AppState>,
		private sanitizer: DomSanitizer
	) {
		this.stateSubscription = this.store.select('main').subscribe(state => {
			if(state && state.auth){
				this.auth = state.auth;
			}
			if(state && state.cart){
				this.cart = state && state.cart && Array.isArray(state.cart) && state.cart.length > 0 ? JSON.parse(JSON.stringify(state.cart)) : [];
			}
			if(state && state.order){
				this.order = state && state.order && Array.isArray(state.order) && state.order.length > 0 ? JSON.parse(JSON.stringify(state.order)) : [];
			}
			if(state && state.imgURL){
				this.imgURL = state.imgURL;
			}
			if(state && state.mainUrl){
				this.mainUrl = state.mainUrl;
			}
			if(state && state.chatUrl){
				this.chatUrl = state.chatUrl;
			}
			if(state && state.isLoggedIn != undefined){
				this.isAuthenticated = state.isLoggedIn;
			}
		});
	}

	ngOnInit() {
		this.stateSubscription = this.store.select('main').subscribe(state => {
			if(state && state.auth){
				this.auth = state.auth;
			}
			if(state && state.cart){
				this.cart = state && state.cart && Array.isArray(state.cart) && state.cart.length > 0 ? JSON.parse(JSON.stringify(state.cart)) : [];
			}
			if(state && state.order){
				this.order = state && state.order && Array.isArray(state.order) && state.order.length > 0 ? JSON.parse(JSON.stringify(state.order)) : [];
			}
			if(state && state.imgURL){
				this.imgURL = state.imgURL;
			}
			if(state && state.mainUrl){
				this.mainUrl = state.mainUrl;
			}
			if(state && state.chatUrl){
				this.chatUrl = state.chatUrl;
			}
			if(state && state.isLoggedIn != undefined){
				this.isAuthenticated = state.isLoggedIn;
			}
		});
	}

	get isMobile () {
		let isMobile:boolean = false;
		const usreAgent = window.navigator.userAgent;
		const finalAgent = usreAgent.substring(0,4);
		// console.log("finalAgent", finalAgent)
		const matchCase:any = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i;
		if(matchCase.test(finalAgent)) {
			isMobile = true;
		} else if(/Chrome/i.test(finalAgent)) {
			isMobile = true;
		} else{
			isMobile = false;
		}
		// console.log("isMobile", isMobile)
		return isMobile;
	}

	getAuth(){
		this.store.subscribe(state => {
			// console.log('state', state)
			this.auth = state && state.main && state.main.auth ? state.main.auth : typeof Auth;
			if (window.localStorage.getItem('maaschoice_auth')) {
				const data = JSON.parse(window.localStorage.getItem('maaschoice_auth') || '{}');
				if(data._id) {
					this.auth = data;
					actions.SET_AUTH({auth: data});
				}
			}
		});
		// console.log("this.auth", this.auth)
		return this.auth;
	}

	getCart(){
		this.store.subscribe(state => {
			// console.log('HttpService getCart state', state)
			this.cart = state && state.main && state.main.cart ? JSON.parse(JSON.stringify(state.main.cart.valueOf())) : [];
			// console.log('HttpService getCart this.cart.length', this.cart.length)
			if (window.sessionStorage.getItem('maaschoice_carts')) {
				const data = JSON.parse(window.sessionStorage.getItem('maaschoice_carts') || '{}');
				if(data && Array.isArray(data) && data.length > 0) {
					this.cart = data;
					actions.SET_CART({cart: data});
				}
			}
		});
		// console.log('HttpService getCart this.cart', this.cart)
		return this.cart;
	}

	getOrder(){
		this.store.subscribe(state => {
			this.order = state && state.main && state.main.order ? JSON.parse(JSON.stringify(state.main.order.valueOf())) : [];
			if (window.localStorage.getItem('maaschoice_orders')) {
				const data = JSON.parse(window.localStorage.getItem('maaschoice_orders') || '{}');
				if(data && Array.isArray(data) && data.length > 0) {
					this.order = data;
					actions.SET_ORDER({order: data});
				}
			}
		});
		// console.log('HttpService getCart this.order', this.order)
		return this.order;
	}

	generateImagePreview = (image:string) => {
		// console.log("\r\r generateImagePreview image \t", image)
		let finalUrl = '~/assets/img/not_found.png';
		if(image && image !== 'null'){
			finalUrl = imageUrl + image;
		}
		// console.log("\r\r generateImagePreview finalUrl \t", finalUrl)
		return finalUrl;
	}

	getSafeURL(url:any, type:any){
		let safeURL:any = "";
		if(type === 'RESOURCE'){
			// safeURL = this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, this.sanitizer.bypassSecurityTrustResourceUrl(url))
			safeURL = this.sanitizer.bypassSecurityTrustResourceUrl(url)
		}else if(type === 'URL'){
			// safeURL = this.sanitizer.sanitize(SecurityContext.URL, this.sanitizer.bypassSecurityTrustUrl(url))
			safeURL = this.sanitizer.bypassSecurityTrustUrl(url);
		}else if(type === 'SCRIPT'){
			// safeURL = this.sanitizer.sanitize(SecurityContext.SCRIPT, this.sanitizer.bypassSecurityTrustScript(url))
			safeURL = this.sanitizer.bypassSecurityTrustScript(url);
		}else if(type === 'STYLE'){
			// safeURL = this.sanitizer.sanitize(SecurityContext.STYLE, this.sanitizer.bypassSecurityTrustStyle(url))
			safeURL = this.sanitizer.bypassSecurityTrustStyle(url);
		}else if(type === 'HTML'){
			// safeURL = this.sanitizer.sanitize(SecurityContext.HTML, this.sanitizer.bypassSecurityTrustHtml(url))
			safeURL = this.sanitizer.bypassSecurityTrustHtml(url);
		}else if(type === 'NONE'){
			// safeURL = this.sanitizer.sanitize(SecurityContext.NONE, url);
			safeURL = this.sanitizer.sanitize(SecurityContext.NONE, url);
		}else {
			safeURL = url;
		}
		// console.log("safeURL", safeURL)
		return safeURL;
	}

	isJson(text:string) {
		try {
			JSON.parse(text)
		}catch(e){
			return false;
		}
		return true;
	}

	getSession(){
		this.store.select('main').subscribe(state => {
			// console.log('state', state)
			this.session = state && state.session ? state.session : null;
			if (window.localStorage.getItem('maaschoice_session')) {
				const data = JSON.parse(window.localStorage.getItem('maaschoice_session') || '{}');
				if(data) {
					this.session = data;
					actions.SET_SESSION({session: data});
				}
			}
		});
		// console.log("this.session", this.session)
		if(!this.session){
			this.session = uuidv4();
			actions.SET_SESSION({session: this.session});
		}
		return this.session;
	}

	compressSLUG(text: any){
		return encodeURIComponent(text);
	}

	decmpressSLUG(text: any){
		return decodeURIComponent(text);
	}

	ngOnDestroy() {
		this.stateSubscription.unsubscribe();
	}

}
