import { HttpClient } from '@angular/common/http';
import { Component, HostListener, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RoutesRecognized } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs';

import { EmiterService, HttpService, CanonicalService, JsonSchemaService } from 'src/app/services';
import { GET_COMPANY, SET_COMPANY, SET_CATEGORY, SET_SESSION, LOG_OUT } from 'src/app/store';
import { v4 as uuidv4 } from 'uuid';

import { BlogComponent } from './components/blog/blog.component'

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent {
	category: any = [];
	public user: any;
	public session: any;
	// public blogs:any;
	constructor(
		private router: Router,
		private http: HttpClient,
		private httpService: HttpService,
		private store: Store,
		private emiter: EmiterService,
		private canonicalService: CanonicalService,
		private metaService: Meta,
		public titleService: Title,
		public schemaService: JsonSchemaService,
		private route: ActivatedRoute,
		// private blogData: BlogComponent,

	) {
		// this.blogData.blogs().subscribe((data)=>{
		// 	console.warn("data",data);
			
		// 	this.blogs.data
		// })
		router.events.forEach((event) => {
			if (event instanceof NavigationStart) {
				if (event.url === '/logout') {
					this.logoutUser();
				}
				this.onLoad("");
				this.emiter.emit('isloading', true);
			}
			if (event instanceof NavigationEnd) {
				if (event.url === '/logout') {
					this.logoutUser();
				}
				this.onLoad("");
				this.emiter.emit('isloading', true);
			}
			if (event instanceof NavigationCancel) {
				this.emiter.emit('isloading', true);
			}
			if (event instanceof NavigationError) {
				this.emiter.emit('isloading', true);
			}
			if (event instanceof RoutesRecognized) {
				this.onLoad("");
				this.emiter.emit('isloading', true);
			}
		});
		this.store.subscribe((data: any) => {
			const company = data.main && data.main.company != undefined && data.main.company._id != undefined && data.main.company._id ? data.main.company : null;
			const user = data.main && data.main.auth != undefined && data.main.auth._id != undefined && data.main.auth._id ? data.main.auth : null;
			if (!company) {
				// this.fetchCompanyDetails();
			}
			if (user && user._id) {
				this.user = user;
			}
		})
		if (!this.user || !this.user._id) {
			this.session = this.generateSession();
			this.store.dispatch(SET_SESSION({ session: this.session }));
		}
	}

	ngOnInit() {
		this.getMenuCategory();

		let metaData:any = {};
		this.route.data.subscribe(item => {metaData = item});
		console.log("metaData", metaData)
		// this.metaTagService.addTags([
		// 	{ name: 'apple-mobile-web-app-title', content: metaData.title },
		// 	{ name: 'apple-mobile-web-app-capable', content: 'yes' },

		// 	{ name: 'news_keywords', content: metaData.keywords },
		// 	{ name: 'tweetmeme-title', content: metaData.title },
		// 	{ name: 'title', content: metaData.title },
		// 	{ name: 'subject', content: metaData.subject },
		// 	{ name: 'keywords', content: metaData.keywords },
		// 	{ name: 'descrption', content: metaData.descrption },
		// 	{ name: 'og:url', content: metaData.ogUrl },
		// 	{ name: 'og:title', content: metaData.ogTitle },
		// 	{ name: 'og:descrption', content: metaData.ogDescription },
		// 	{ name: 'og:image', content: metaData.ogImage },
		// 	{ name: 'twitter:card', content: 'summary' },
		// 	{ name: 'twitter:title', content: metaData.twTitle },
		// 	{ name: 'twitter:description', content: metaData.twDescription },
		// 	{ name: 'twitter:image', content: metaData.ogImage },
		// 	{ name: 'twitter:image:alt', content: metaData.twTitle },
		// 	{ name: 'schema', content: JSON.stringify(metaData.schema) },
		// 	{ name: 'subject', content: metaData.subject },
		// 	{ name: 'robots', content: metaData.robots },
		// ]);

		this.router.events.pipe(filter((event: any) => event instanceof NavigationEnd),).subscribe(() => {
			var rt: any = this.getChild(this.route)

			rt.data.subscribe((data: any) => {
				// settings title
				this.titleService.setTitle(data.title)
				// setting json schema
				this.schemaService.removeStructuredData();
				this.schemaService.insertSchema(data.schema)
				if (data.title) {
					this.metaService.updateTag({ name: 'title', content: data.title })
					this.metaService.updateTag({ name: 'twitter:image:alt', content: data.title })
					this.metaService.updateTag({ name: 'apple-mobile-web-app-title', content: data.title })
					this.metaService.updateTag({ name: 'tweetmeme-title', content: data.title })
				} else {
					this.metaService.removeTag("name='title'")
					this.metaService.removeTag("name='tweetmeme-title'")
					this.metaService.removeTag("name='apple-mobile-web-app-title'")
					this.metaService.removeTag("name='twitter:image:alt'")
				}

				if (data.subject) {
					this.metaService.updateTag({ name: 'subject', content: data.subject })
				} else {
					this.metaService.removeTag("name='subject'")
				}

				if (data.keywords) {
					this.metaService.updateTag({ name: 'keywords', content: data.keywords })
					this.metaService.updateTag({ name: 'news_keywords', content: data.keywords })
				} else {
					this.metaService.removeTag("name='keywords'")
					this.metaService.removeTag("name='news_keywords'")
				}

				if (data.descrption) {
					this.metaService.updateTag({ name: 'description', content: data.descrption })
				} else {
					this.metaService.removeTag("name='description'")
				}

				if (data.robots) {
					this.metaService.updateTag({ name: 'robots', content: data.robots })
				} else {
					this.metaService.updateTag({ name: 'robots', content: "follow,index" })
				}

				if (data.ogUrl) {
					this.metaService.updateTag({ property: 'og:url', content: data.ogUrl })
				} else {
					this.metaService.updateTag({ property: 'og:url', content: this.router.url })
				}

				if (data.ogTitle) {
					this.metaService.updateTag({ property: 'og:title', content: data.ogTitle })
				} else {
					this.metaService.removeTag("property='og:title'")
				}

				if (data.ogDescription) {
					this.metaService.updateTag({ property: 'og:description', content: data.ogDescription })
				} else {
					this.metaService.removeTag("property='og:description'")
				}

				if (data.twTitle) {
					this.metaService.updateTag({ name: 'twitter:title', content: data.twTitle })
				} else {
					this.metaService.removeTag("name='twitter:title'")
				}

				if (data.twDescription) {
					this.metaService.updateTag({ name: 'twitter:description', content: data.twDescription })
				} else {
					this.metaService.removeTag("name='twitter:description'")
				}

				if (data.ogImage) {
					this.metaService.updateTag({ name: 'twitter:image', content: data.ogImage })
					this.metaService.updateTag({ property: 'og:image', content: data.ogImage })
				} else {
					this.metaService.removeTag("property='og:image'")
					this.metaService.removeTag("name='twitter:image'")
				}
			})
			this.canonicalService.setCanonicalURL();
		})

	}

	onScroll(event: any) {
		var B = document.body; //IE 'quirks'
		var D = document.documentElement; //IE with doctype
		D = (D.clientHeight) ? D : B;
		var t: any, e: any, n: any, s: any = "";
		e = document.getElementsByClassName("header-sticky")[0];
		t = "is-sticky";
		// console.log("event.target", event)
		// let _thisScrollTop:any = event.target.scrollTop();
		// if (D.scrollTop > 720){
		// 	e.classList.add(t)
		// } else{
		// 	e.classList.remove(t);
		// }
		// if ( (window.innerHeight + window.pageYOffset) >= document.body.offsetHeight - 2 ) {
		// 	e.classList.remove(t);
		// } else {
		// 	e.classList.add(t);
		// }

		// const nav = document.getElementsByClassName('navbar-collapse')[0];
		// const classes = nav.classList.toString();
		// const isOpen = classes.indexOf('show') > -1;
		// if (D.scrollTop == 0) {
		// 	e.classList.remove(t);
		// }

	}

	onLoad(event: any) {

	}

	logoutUser() {
		this.store.dispatch(LOG_OUT());
		window.localStorage.clear();
		window.sessionStorage.clear();
		this.router.navigate(['/']);
	}

	fetchCompanyDetails() {
		this.http.post(this.httpService.mainUrl + 'fetchCompanyDetails', {}).subscribe((res: any) => {
			if (res.status == 1) {
				let data = res.data;
				data.mapURL = this.httpService.getSafeURL(`https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d29558.493311396942!2d${data.long}!3d${data.lat}!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xc010eafe67f38f17!2sRepute%20Foods%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1640250106126!5m2!1sen!2sin`, 'RESOURCE');
				this.store.dispatch(SET_COMPANY({ company: data }));
			}
		})
	}

	getMenuCategory() {
		this.http.post(this.httpService.mainUrl + 'getMenuCategory', {}).subscribe((result: any) => {
			this.emiter.emit("isloading", { type: "isloading", data: false });
			if (result.status == 1) {
				this.category = result.data && Array.isArray(result.data) ? result.data : [];
				this.store.dispatch(SET_CATEGORY({ category: result.data.valueOf() }));
			} else {
				this.category = [];
			}
		})
	}

	generateSession() {
		const uuid: any = uuidv4();
		return uuid;
	}

	getChild(route: ActivatedRoute): any {
		if (route.firstChild) {
			return this.getChild(route.firstChild);
		} else {
			return route;
		}
	}

}
