<app-breadcrumb></app-breadcrumb>
<section>
	<div class="faq">
		<div class="container">
			<div class="row justify-content-center">
				<div class="col-xl-12">
					<div class="faq-title">
						<h1>FAQs</h1>
					</div>
				</div>
				<div class="col-xl-12">
					<div class="faq-box">
						<div class="faq-content">
							<div class="accordion accordion-flush" id="accordionFlushExample">
								<div class="accordion-item">
								  <h2 class="accordion-header" id="flush-headingOne">
									<button class="accordion-button  collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
									Does the age matter?
									</button>
								  </h2>
								  <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
									<div class="accordion-body">There is no established age limit for student visa requirements in Australia. There are no limitations for those who wish to apply. However, there are some restrictions if you are under the age of 18.</div>
								  </div>
								</div>


								<div class="accordion-item">
								  <h2 class="accordion-header" id="flush-headingTwo">
									<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
									What about the qualifications?
									</button>
								  </h2>
								  <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
									<div class="accordion-body">Each university will have its own set of academic and English language requirements. To understand more, we recommend that you speak with one of our counselors directly.</div>
								  </div>
								</div>


								<div class="accordion-item">
								  <h2 class="accordion-header" id="flush-headingThree">
									<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
										What is a certified copy?
									</button>
								  </h2>
								  <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
									<div class="accordion-body">A certified copy of a document is a <b> copy of an original document </b> which is <b>certified as a true copy by an authorized person.</b></div>
								  </div>
								</div>


								<div class="accordion-item">
								  <h2 class="accordion-header" id="flush-headingFour">
									<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
									Do I need to send my original certificates?
									</button>
								  </h2>
								  <div id="flush-collapseFour" class="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
									<div class="accordion-body">Original documents are not acknowledged; only translated certified copies are acknowledged.</div>
								  </div>
								</div>


								<div class="accordion-item">
								  <h2 class="accordion-header" id="flush-headingFive">
									<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
										What are the documents I need?
									</button>
								  </h2>
								  <div id="flush-collapseFive" class="accordion-collapse collapse" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample">
									<div class="accordion-body">A few documents must be given in order to receive a student visa, including proof of enrollment to proof of English proficiency. There's a lot to stay on top of. Please contact one of our counselors for assistance.</div>
								  </div>
								</div>

								<div class="accordion-item">
								  <h2 class="accordion-header" id="flush-headingSix">
									<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix">
										If approved, how long do I have before I must travel?
									</button>
								  </h2>
								  <div id="flush-collapseSix" class="accordion-collapse collapse" aria-labelledby="flush-headingSix" data-bs-parent="#accordionFlushExample">
									<div class="accordion-body">Once you have received your visa you can enter Australia at the earliest 90 days before the course start date printed on your CoE.</div>
								  </div>
								</div>


								<div class="accordion-item">
								  <h2 class="accordion-header" id="flush-headingSeven">
									<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSeven" aria-expanded="false" aria-controls="flush-collapseSeven">
										Who can translate my documents into English for me?
									</button>
								  </h2>
								  <div id="flush-collapseSeven" class="accordion-collapse collapse" aria-labelledby="flush-headingSeven" data-bs-parent="#accordionFlushExample">
									<div class="accordion-body">Any document that you are asked to provide to support your student visa application must be translated by accredited translators. If any of the documents have been translated in another country apart from Australia then the translator has to provide his or her full name, telephone number, address, and experience and qualifications in the language that has been translated.</div>
								  </div>
								</div>


								<div class="accordion-item">
								  <h2 class="accordion-header" id="flush-headingEight">
									<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEight" aria-expanded="false" aria-controls="flush-collapseEight">
										What are my choices if my visa application is refused?
									</button>
								  </h2>
								  <div id="flush-collapseEight" class="accordion-collapse collapse" aria-labelledby="flush-headingEight" data-bs-parent="#accordionFlushExample">
									<div class="accordion-body">In the case where your student visa is being refused while you are outside of Australia, you will be able to apply for the same or another type of visa at any time.<br>
										<br>
										If you are in Australia when your visa is refused, you have 21 days to lodge an appeal to the Migration Review Tribunal (MRT) for a review of their decision. </div>
								  </div>
								</div>


								<div class="accordion-item">
								  <h2 class="accordion-header" id="flush-headingNine">
									<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseNine" aria-expanded="false" aria-controls="flush-collapseNine">
										What English language tests are acceptable for Australian student visas?
									</button>
								  </h2>
								  <div id="flush-collapseNine" class="accordion-collapse collapse" aria-labelledby="flush-headingNine" data-bs-parent="#accordionFlushExample">
									<div class="accordion-body">
										<li>Test of English as a Foreign Language (TOEFL)</li>
										<li>International English Language Testing System (IELTS)</li>
										<li>Cambridge English: Advanced (CAE)</li>
										<li>Occupational English Test (OET)</li>
										<li>Pearson Test of English (PTE)</li>
									</div>
								  </div>
								</div>


								<div class="accordion-item">
								  <h2 class="accordion-header" id="flush-headingTen">
									<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTen" aria-expanded="false" aria-controls="flush-collapseTen">
										How long will it take for the result once the application is lodged?
									</button>
								  </h2>
								  <div id="flush-collapseTen" class="accordion-collapse collapse" aria-labelledby="flush-headingTen" data-bs-parent="#accordionFlushExample">
									<div class="accordion-body">
										The processing time of an application can vary and will be different for everyone. Typically, though, the approval of your application can take between two weeks and four months.
									</div>
								  </div>
								</div>


								<div class="accordion-item">
								  <h2 class="accordion-header" id="flush-headingEleven">
									<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEleven" aria-expanded="false" aria-controls="flush-collapseEleven">
										How long is my police clearance valid for?
									</button>
								  </h2>
								  <div id="flush-collapseEleven" class="accordion-collapse collapse" aria-labelledby="flush-headingEleven" data-bs-parent="#accordionFlushExample">
									<div class="accordion-body">
										The clearance certificate is regarded as valid for only 12 months from the date of issue. 
									</div>
								  </div>
								</div>


								<div class="accordion-item">
								  <h2 class="accordion-header" id="flush-headingTwelve">
									<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwelve" aria-expanded="false" aria-controls="flush-collapseTwelve">
										What will happen to my visa if I don’t continue my study?
									</button>
								  </h2>
								  <div id="flush-collapseTwelve" class="accordion-collapse collapse" aria-labelledby="flush-headingTwelve" data-bs-parent="#accordionFlushExample">
									<div class="accordion-body">
										You can apply for another type of visa which is more aligned to your intentions.<br>
										<br>
										This should occur as soon as possible, as a visa cancellation decision may impact your future Australian immigration options.
									</div>
								  </div>
								</div>


								<div class="accordion-item">
								  <h2 class="accordion-header" id="flush-headingThirteen">
									<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThirteen" aria-expanded="false" aria-controls="flush-collapseThirteen">
										What if I don’t want to continue studying for the time being but would like to stay in Australia?
									</button>
								  </h2>
								  <div id="flush-collapseThirteen" class="accordion-collapse collapse" aria-labelledby="flush-headingThirteen" data-bs-parent="#accordionFlushExample">
									<div class="accordion-body">
										If you do not intend to study, you can apply for another type of visa which is more aligned to your intentions.<br><br>
										This should occur as soon as possible, as a visa cancellation decision may impact your future Australian immigration options.
									</div>
								  </div>
								</div>


								<div class="accordion-item">
								  <h2 class="accordion-header" id="flush-headingFourteen">
									<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFourteen" aria-expanded="false" aria-controls="flush-collapseFourteen">
										What if I am unvaccinated and want to study in Australia?
									</button>
								  </h2>
								  <div id="flush-collapseFourteen" class="accordion-collapse collapse" aria-labelledby="flush-headingFourteen" data-bs-parent="#accordionFlushExample">
									<div class="accordion-body">
										Restrictions on travel to Australia have been lifted from 6 July 2022. Unvaccinated travellers can now enter Australia without a travel exemption.   
									</div>
								  </div>
								</div>


								<div class="accordion-item">
								  <h2 class="accordion-header" id="flush-headingFifteen">
									<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFifteen" aria-expanded="false" aria-controls="flush-collapseFifteen">
										Can I leave Australia and return to my home country for a holiday?
									</button>
								  </h2>
								  <div id="flush-collapseFifteen" class="accordion-collapse collapse" aria-labelledby="flush-headingFifteen" data-bs-parent="#accordionFlushExample">
									<div class="accordion-body">
										All visa holders can arrive back in Australia after a holiday without needing an approved travel exemption.<br>
										<br>
										This means that temporary visa holders in Australia can plan to visit their home country, if border restrictions in that country allow, and return to Australia to continue their studies.
									</div>
								  </div>
								</div>


							  </div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

