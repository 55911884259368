import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BodyOutputType, ToasterConfig } from 'angular2-toaster';

@Component({
	selector: 'app-main',
	templateUrl: './main.component.html',
	styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
	public config: ToasterConfig = new ToasterConfig({
		positionClass: 'top5 show toast-top-right',
		animation: 'flyRight',
		tapToDismiss: true,
		showCloseButton: { 'warning': true, 'success': true, 'error': false },
		mouseoverTimerStop: false,
		bodyOutputType: BodyOutputType.TrustedHtml,
		preventDuplicates: true,
		newestOnTop: true,
		defaultToastType: 'info',
		// timeout: null,
	});
	width: number = 1200;
	isHome: boolean = false;
	scrolled: boolean = false;
	constructor(
		private router : Router,
	) { }

	ngOnInit(): void {
		this.width = window.innerWidth;
		let uri = this.router.url.split('/')[1];
		if(uri === 'home'){
			this.isHome = true;
		}
		this.scrolled = window.screenTop > 108 ? true : false;
	}

	onResize(event:any) {
		let marTop:string = '127px';
		this.width = window.innerWidth;
		if(this.width < 1400){
			marTop = '127px';
		}
		if(this.width < 1200){
			marTop = '125px';
		}
		if(this.width < 992){
			marTop = '81px';
		}
		return marTop
	}

	getTopMargin() {
		let marTop:string = '127px';
		this.width = window.innerWidth;
		if(this.width < 1400){
			marTop = '127px';
		}
		if(this.width < 1200){
			marTop = '125px';
		}
		if(this.width < 992){
			marTop = '81px';
		}
		if(this.width < 768){
			marTop = '81px';
		}
		return marTop
	}

}
