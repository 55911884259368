import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Lightbox, LightboxConfig } from 'ngx-lightbox';
import { HttpService, JsonSchemaService } from 'src/app/services';

export interface OG {
	title: string;
	description: string;
	url: string;
	image: string;
}

export interface Twitter {
	card: string;
	title: string;
	description: string;
	image: string;
	image_alt: string;
}

export interface MetaSchema {
	title: string;
	description: string;
	keywords: string;
	image: string;
	canonical: string;
	subject: string;
	url: string;
	og: OG,
	twitter: Twitter
}


@Component({
  selector: 'app-gallery-detail',
  templateUrl: './gallery-detail.component.html',
  styleUrls: ['./gallery-detail.component.scss']
})
export class GalleryDetailComponent implements OnInit {
	album: any = [];
	gallery:any = {
		_id: "625fe9c365808f3e69db8941",
		images: [
			{
				image: "y7MW7d8fb1Y",
				type: "video"
			},
			{
				image: "Gallery/1O95aC.png",
				type: "image"
			},
			{
				image: "Gallery/y6z8rl.jpeg",
				type: "image"
			},
			{
				image: "Gallery/5CIcD7.jpeg",
				type: "image"
			}
		],
		status: true,
		name: "Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. Pellentesque in ipsum id orci porta dapibus.",
		slug: "curabitur-non-nulla-sit-amet-nisl-tempus-convallis-quis-ac-lectus.-pellentesque-in-ipsum-id-orci-porta-dapibus.",
		createdAt: "2022-04-20T11:08:51.809Z",
		updatedAt: "2022-04-22T07:33:35.181Z",
		image: "",
		meta: {
			title: "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
			description: "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
			keywords: "Gallery, Divine Gallery, Photo Gallery, Video Gallery",
			image: "http://165.232.180.214:8081/Gallery/1O95aC.png",
			canonical: "https://www.divine.eu/service/curabitur-non-nulla-sit-amet-nisl-tempus-convallis-quis-ac-lectus.-pellentesque-in-ipsum-id-orci-porta-dapibus.",
			subject: "curabitur non nulla sit amet nisl tempus",
			url: "https://www.divine.eu/service/curabitur-non-nulla-sit-amet-nisl-tempus-convallis-quis-ac-lectus.-pellentesque-in-ipsum-id-orci-porta-dapibus.",
			og: {
				title: "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
				description: "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
				url: "https://www.divine.eu/service/curabitur-non-nulla-sit-amet-nisl-tempus-convallis-quis-ac-lectus.-pellentesque-in-ipsum-id-orci-porta-dapibus.",
				image: "http://165.232.180.214:8081/Gallery/1O95aC.png"
			},
			twitter: {
				card: "",
				title: "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
				description: "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus.",
				image: "http://165.232.180.214:8081/Gallery/1O95aC.png",
				image_alt: "curabitur non nulla sit amet nisl tempus convallis quis ac lectus. pellentesque in ipsum id orci porta dapibus."
			}
		}

	}
	constructor(
		private metaService: Meta,
		private titleService: Title,
		private schemaService: JsonSchemaService,
		private route: ActivatedRoute,
		public httpService: HttpService,
		private http: HttpClient,
		private router: Router,
		private _lightboxConfig: LightboxConfig,
		private _lightbox: Lightbox,
	) {
		_lightboxConfig.showDownloadButton = true;
		_lightboxConfig.showZoom = false;
	}

	ngOnInit(): void {

		this.gallery.images.map((item:any) => {
			if(item.type === 'image'){
				this.album.push({
					src: this.httpService.generateImagePreview(item.image),
					caption: this.gallery.name,
					thumb: this.httpService.generateImagePreview(item.image),
				});
			}
		})

		const meta: MetaSchema = this.gallery.meta;
		const meta_description = meta.description ? meta.description : "";
		const meta_keywords = meta.keywords ? meta.keywords : "";
		const meta_subject = meta.subject ? meta.subject : "";
		const meta_title = meta.title ? meta.title : "";
		const og_url = meta.canonical ? meta.canonical : "";
		const og_image = meta.image ? meta.image : "";
		this.gallery.image = og_image;
		const og:OG = meta.og;
		const twitter:Twitter = meta.twitter;

		const data = {
			title: 'Divine | ' + meta.title,
			subject: meta.subject,
			keywords: meta.keywords,
			descrption: meta.description,
			canonical: meta.canonical,
			robots: 'index, follow',
			ogUrl: og.url,
			ogTitle: og.title,
			ogDescription: og.description,
			ogImage: og.image,
			ogImageAlt: og.title,
			twTitle: twitter.title,
			twDescription: twitter.description,
			twImage: twitter.image,
			twImageAlt: twitter.image_alt,
			schema: {
				"@context": "https://schema.org",
				"@id": og_url,
				"@type": "LocalBusiness",
				name: "Divine Migration",
				url: og_url,
				image: og_image,
				telephone: "+1 123 456 7890",
				address: {
					"@type": "PostalAddress",
					streetAddress: "ABC, First Evenue, USA",
					addressLocality: "USA",
					postalCode: "36051",
					addressCountry: "USA",
					addressRegion: "California"
				},
				priceRange: "10000-1000000",
				sameAs: [
					"https://www.facebook.com/rktechnology/",
					"https://www.linkedin.com/company/rk-technology/",
					"https://www.twitter.com/rktechnology"
				],
				openingHoursSpecification: [
					{
						"@type": "OpeningHoursSpecification",
						dayOfWeek: [
							"Monday",
							"Tuesday",
							"Wednesday",
							"Thursday",
							"Friday"
						],
						opens: "09:00",
						closes: "19:00"
					},
					{
						"@type": "OpeningHoursSpecification",
						dayOfWeek: ["Saturday"],
						opens: "09:00",
						closes: "13:00"
					}
				]
			},
		}
		// settings title
		this.titleService.setTitle(data.title)
		// setting json schema
		this.schemaService.removeStructuredData();
		this.schemaService.insertSchema(data.schema)
		if (data.title) {
			this.metaService.updateTag({ name: 'title', content: data.title })
		} else {
			this.metaService.removeTag("name='title'")
		}

		if (data.subject) {
			this.metaService.updateTag({ name: 'subject', content: data.subject })
		} else {
			this.metaService.removeTag("name='subject'")
		}

		if (data.keywords) {
			this.metaService.updateTag({ name: 'keywords', content: data.keywords })
		} else {
			this.metaService.removeTag("name='keywords'")
		}

		if (data.descrption) {
			this.metaService.updateTag({ name: 'description', content: data.descrption })
		} else {
			this.metaService.removeTag("name='description'")
		}

		if (data.robots) {
			this.metaService.updateTag({ name: 'robots', content: data.robots })
		} else {
			this.metaService.updateTag({ name: 'robots', content: "follow, index" })
		}

		if (data.ogUrl) {
			this.metaService.updateTag({ property: 'og:url', content: data.ogUrl })
		} else {
			this.metaService.updateTag({ property: 'og:url', content: this.router.url })
		}

		if (data.ogTitle) {
			this.metaService.updateTag({ property: 'og:title', content: data.ogTitle })
		} else {
			this.metaService.removeTag("property='og:title'")
		}

		if (data.ogDescription) {
			this.metaService.updateTag({ property: 'og:description', content: data.ogDescription })
		} else {
			this.metaService.removeTag("property='og:description'")
		}

		if (data.twTitle) {
			this.metaService.updateTag({ name: 'twitter:title', content: data.twTitle })
		} else {
			this.metaService.removeTag("name='twitter:title'")
		}

		if (data.twDescription) {
			this.metaService.updateTag({ name: 'twitter:description', content: data.twDescription })
		} else {
			this.metaService.removeTag("name='twitter:description'")
		}

		if (data.ogImage) {
			this.metaService.updateTag({ property: 'og:image', content: data.ogImage })
		} else {
			this.metaService.removeTag("property='og:image'")
		}
		if (data.twImage) {
			this.metaService.updateTag({ name: 'twitter:image', content: data.twImage })
		} else {
			this.metaService.removeTag("name='twitter:image'")
		}
		if (data.twImageAlt) {
			this.metaService.updateTag({ name: 'twitter:image:alt', content: data.twImageAlt })
		} else {
			this.metaService.removeTag("name='twitter:image:alt'")
		}

		if (data.canonical) {
			const canon = document.querySelectorAll("link[rel='canonical']")[0];
			canon.setAttribute("href", data.canonical);
		}
	}

	getGalleryDetail(slug:string = '') {
		this.http.post(this.httpService.mainUrl + 'serviceDetail',{slug: slug}).subscribe((res:any) => {
			if(res.status === '1'){
				this.gallery = res.data;
				const meta_description = this.gallery.meta_description ? this.gallery.meta_description : "";
				const meta_keywords = this.gallery.meta_keywords ? this.gallery.meta_keywords : "";
				const meta_schema = this.gallery.meta_schema ? this.gallery.meta_schema : "";
				const meta_subject = this.gallery.meta_subject ? this.gallery.meta_subject : "";
				const meta_title = this.gallery.meta_title ? this.gallery.meta_title : "";
				const og_url = this.gallery.slug ? 'http://165.232.180.214/divine/' + this.gallery.slug : "";
				const og_image = this.gallery.image ? this.httpService.imgURL + this.gallery.image : "http://165.232.180.214/divine/assets/gallery-2-large.png";
				this.gallery.image = og_image;

				const data = {
					title: meta_title,
					subject: meta_subject,
					keywords: meta_keywords,
					descrption: meta_description,
					robots: 'index,follow',
					ogUrl: og_url,
					ogTitle: meta_title,
					ogDescription: meta_description,
					ogImage: og_image,
					twTitle: meta_title,
					twDescription: meta_description,
					schema: {
						"@context": "https://schema.org",
						"@id": og_url,
						"@type": "LocalBusiness",
						name: "Divine Migration",
						url: og_url,
						image: og_image,
						telephone: "+1 123 456 7890",
						address: {
							"@type": "PostalAddress",
							streetAddress: "ABC, First Evenue, USA",
							addressLocality: "USA",
							postalCode: "36051",
							addressCountry: "USA",
							addressRegion: "California"
						},
						priceRange: "10000-1000000",
						sameAs: [
							"https://www.facebook.com/rktechnology/",
							"https://www.linkedin.com/company/rk-technology/",
							"https://www.twitter.com/rktechnology"
						],
						openingHoursSpecification: [
							{
								"@type": "OpeningHoursSpecification",
								dayOfWeek: [
									"Monday",
									"Tuesday",
									"Wednesday",
									"Thursday",
									"Friday"
								],
								opens: "09:00",
								closes: "19:00"
							},
							{
								"@type": "OpeningHoursSpecification",
								dayOfWeek: ["Saturday"],
								opens: "09:00",
								closes: "13:00"
							}
						]
					},
				}
				// settings title
				this.titleService.setTitle(data.title)
				// setting json schema
				this.schemaService.removeStructuredData();
				this.schemaService.insertSchema(data.schema)
				if (data.title) {
					this.metaService.updateTag({ name: 'title', content: data.title })
				} else {
					this.metaService.removeTag("name='title'")
				}

				if (data.subject) {
					this.metaService.updateTag({ name: 'subject', content: data.subject })
				} else {
					this.metaService.removeTag("name='subject'")
				}

				if (data.keywords) {
					this.metaService.updateTag({ name: 'keywords', content: data.keywords })
				} else {
					this.metaService.removeTag("name='keywords'")
				}

				if (data.descrption) {
					this.metaService.updateTag({ name: 'description', content: data.descrption })
				} else {
					this.metaService.removeTag("name='description'")
				}

				if (data.robots) {
					this.metaService.updateTag({ name: 'robots', content: data.robots })
				} else {
					this.metaService.updateTag({ name: 'robots', content: "follow,index" })
				}

				if (data.ogUrl) {
					this.metaService.updateTag({ property: 'og:url', content: data.ogUrl })
				} else {
					this.metaService.updateTag({ property: 'og:url', content: this.router.url })
				}

				if (data.ogTitle) {
					this.metaService.updateTag({ property: 'og:title', content: data.ogTitle })
				} else {
					this.metaService.removeTag("property='og:title'")
				}

				if (data.ogDescription) {
					this.metaService.updateTag({ property: 'og:description', content: data.ogDescription })
				} else {
					this.metaService.removeTag("property='og:description'")
				}

				if (data.twTitle) {
					this.metaService.updateTag({ property: 'twitter:title', content: data.twTitle })
				} else {
					this.metaService.removeTag("property='twitter:title'")
				}

				if (data.twDescription) {
					this.metaService.updateTag({ property: 'twitter:description', content: data.twDescription })
				} else {
					this.metaService.removeTag("property='twitter:description'")
				}

				if (data.ogImage) {
					this.metaService.updateTag({ property: 'og:image', content: data.ogImage })
				} else {
					this.metaService.removeTag("property='og:image'")
				}
				if (data.ogImage) {
					this.metaService.updateTag({ property: 'twitter:image', content: data.ogImage })
				} else {
					this.metaService.removeTag("property='twitter:image'")
				}
			}
		})
	}

	isImage(images:any) {
		return images[0].type === 'image' || false;
	}

	getMedia(media:any){
		if(media.type === 'image'){
			return this.httpService.generateImagePreview(media.image)
		}
		if(media.type === 'video'){
			return this.httpService.getSafeURL(`https://www.youtube.com/embed/${media.image}`, 'RESOURCE');
		}
	}

	openLightBox(index: number): void {
		// open lightbox
		this._lightbox.open(this.album, index);
	}

	closeLightBox(): void {
		// close lightbox programmatically
		this._lightbox.close();
	}

}
