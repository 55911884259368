<section (window:scroll)="onScroll($event)">
	<!-- slider start here -->
	<div class="home_slider">
		<div id="main-slider" *ngIf="mainSlider && mainSlider.length > 0">
			<owl-carousel-o [options]="mainSliderConfig">
				<ng-container *ngFor="let slide of mainSlider">
					<ng-template carouselSlide [id]="slide._id">
						<img class="item slider" [src]="
								httpService.generateImagePreview(slide.image)
							" [alt]="slide.title" />
						<div class="container">
							<div class="row text-center justify-content-center align-items-center">
								<div class="col-lg-7">
									<div class="home-content text-center align-items-center">
										<h3>{{ slide.title }}</h3>
										<p>{{ slide.description }}</p>
										
										
										<button type="button" class=" btn-1" data-bs-toggle="modal"
										data-bs-target="#exampleModal" data-bs-whatever="@getbootstrap">
										Inquire Now
									</button>
									

									<div class="modal fade" id="exampleModal" tabindex="-1"
										aria-labelledby="exampleModalLabel" aria-hidden="true">
										<div class="modal-dialog modal-lg">
											<div class="modal-content">
												<div class="modal-header">
													<h5 style="color: black;" class="modal-title" id="exampleModalLabel">
														Inquire Now
													</h5>
													<button type="button" class="btn-close" data-bs-dismiss="modal"
														aria-label="Close"></button>
												</div>
												<div class="modal-body">
													<section>
														<div class="contact-us">
															<div class="container">
																<div class="row justify-content-center">
																	<div class="col-xl-12">
																		<div class="contact-title">
																			<!-- <h1>Contact Us</h1> -->
																		</div>
																	</div>
																	<div class="col-xl-12">
																		<div class="contact-box">
																			<div class="contact-content">
																				<form class="form contact-form" (submit)="submitForm($event)"  [formGroup]="contactForm">
																					<div class="row">
																						<div class="col-md-6">
																							<div class="form-group">
																								<label for="name">Your Name*</label>
																								<input name="name" id="name" formControlName="name" class="form-control" required/>
																							</div>
																						</div>
																						<div class="col-md-6">
																							<div class="form-group">
																								<label for="email">Your Email*</label>
																								<input name="email" id="email" formControlName="email" class="form-control" required/>
																							</div>
																						</div>
																					</div>
																					<div class="row">
																						<div class="col-md-6">
																							<div class="form-group">
																								<label for="mobile">Your Mobile*</label>
																								<input type="number"  name="mobile" id="mobile" formControlName="mobile" class="form-control" required oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
																								maxlength = "10"/>
																								
																							</div>
																						</div>
																						<div class="col-md-6">
																							<div class="form-group">
																								<label for="subject">Your Subject*</label>
																								<input name="subject" id="subject" formControlName="subject" class="form-control" required/>
																							</div>
																						</div>
																					</div>
																					<div class="row">
																						<div class="col-md-12">
																							<div class="form-group">
																								<label for="message">Your Query*</label>
																								<textarea rows="4" name="message" id="message" formControlName="message" class="form-control" required></textarea>
																							</div>
																						</div>
																					</div>
																					<div class="row">
																						<div class="col-md-12">
																							<div class="form-group submitbtn">
																								<button type="button" class="btn btn-secondary mx-2"
																								data-bs-dismiss="modal">
																								Close
																							</button>
																								<button class="btn btn-lg btn-theme" type="submit">Submit</button>
																							</div>
																						</div>
																					</div>
																					
																				</form>
																			</div>
													
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</section>
												</div>
											</div>
										</div>
									</div>
									</div>
								</div>
							</div>
						</div>
					</ng-template>
				</ng-container>
			</owl-carousel-o>
		</div>
	</div>
	<!-- slider ends here -->

	<!-- Services start -->
	<div class="services">
		<div class="container">
			<div class="row">
				<div class="col-lg-12 text-center">
					<div class="service-heading">
						<p>OUR SERVICES</p>
						<h2>Education and Visa Services</h2>
					</div>
				</div>
			</div>
			<div class="row justify-content-center">
				<div class="col-sm-12 col-md-12 text-center">
					<div id="service-slider" *ngIf="serviceSlider && serviceSlider.length > 0">
						<owl-carousel-o [options]="serviceSliderConfig">
							<ng-container *ngFor="let slide of serviceSlider">
								<ng-template carouselSlide [id]="slide._id">
									<div class="item">
										<div class="service-box">
											<img [src]="slide.meta.image" [alt]="
													(slide.title
														| slice : 0 : 50) +
													'...'
												" class="img-fluid" />
											<h6>
												{{
												(slide.title
												| slice : 0 : 20) +
												"..."
												}}
											</h6>
											<!-- <p [innerHTML]="
													(slide.description
														| slice : 0 : 100) +
													'...'
												"></p> -->
											<a class="mb-5" *ngIf="slide.slug" [routerLink]="
													'/service/' +
													httpService.compressSLUG(
														slide.slug
													)
												" [queryParams]="{
													type: slide.type
												}">Read more</a>
										</div>
									</div>
								</ng-template>
							</ng-container>
						</owl-carousel-o>
					</div>
				</div>
			</div>
		</div>
	</div>

	
	<!-- Service End -->

	<!-- Migration Agent Section Start -->
	<div class="agent-section">
		<div class="container-fluid p0 m0">
			<div class="row m0">
				<div class="col-lg-6 p0 text-center">
					<div class="agent-bg"></div>
				</div>
				<div class="col-lg-6 p0 text-center">
					<div class="agent-content">
						<img src="assets/img/logo-no-text.png" alt="Logo Agent" />
						<h3>#1 Trusted Migration Agents</h3>
						<p>
							Presha Pandya is the brainchild behind Divine
							Migration & Education Consultant Specialists. She
							lived in the UK for 3 years pursuing her studies and
							then settled in Brisbane, Australia. Having been in
							the UK and now in Australia, she understands the
							challenges faced by migrants. Having interacted
							closely with similar individuals like her, she set
							her target on a career path wanting to help students
							and migrants.
						</p>
						<a [routerLink]="'/about-us/'">READ MORE</a>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- Migration Agent Section End -->

	<!-- <div class="blog">
		<div class="container">
			<div class="row position-relative">
				<div class="col-lg-8 p0 text-center">
					<div class="blog-content">
						<div class="blog-heading">
							<h2>Latest Blog</h2>
							<p>Learn the latest from our team of experts</p>
						</div>
						<div class="row">
							<div class="col-md-6 col-lg-6 p0" *ngFor="let item of blogs">
								<div class="blog-box" style="margin: 20px">
									<div class="blog-box-image">
										<img [src]="item.meta.image" [alt]="
												(item.title | slice : 0 : 20) +
												'...'
											" />
									</div>
									<div class="blog-box-content">
										<div class="title">
											{{
											(item.title | slice : 0 : 50) +
											"..."
											}}
										</div>
	
										<div class="description">
											<p [innerHTML]="
													(item.small_description
														| slice : 0 : 100) +
													'...'
												"></p>
										</div>
										<div class="read-more">
											<a [routerLink]="
													'/blog/' +
													httpService.compressSLUG(
														item.slug
													)
												">Read Blog
												<i class="fa fa-long-arrow-right"></i></a>
										</div>
									</div>
								</div>
							</div>
						</div>
						<a class="blog-button" [routerLink]="'/blog/'">VIEW ALL</a>
					</div>
					<div class="blog-bg">
						<img class="blogimg" src="assets/img/blog-bg.png" alt="" />
					</div>
				</div>
			</div>
		</div>
	</div> -->


	<div class="blogs my-3">
		<div class="container">
			<div class="row">
				<div class="col-lg-8 col-sm-12 latest-blog">
					<div class="blog-heading">
						<h2>Latest Blog</h2>
						<p>Learn the latest from our team of experts</p>
					</div>
					<div class="row" style="margin-top: 20px;">
						<div class="col-lg-6 col-md-6 col-sm-6 gap-1 blogbox mb-4" *ngFor="let item of blogs" >
							<div class="row">
								<div class="col-6 ">
									<div class="blog-img">
										<img [src]="item.meta.image" [alt]="
												(item.title | slice : 0 : 20) + '...'
											" />
									</div>
								</div>
								<div class="col-6">
									<div class="blog-box-content">
										<div class="title">
											{{ (item.title | slice : 0 : 50) + "..." }}
										</div>
		
										<div class="description mt-2">
											<p [innerHTML]="
													(item.small_description
														| slice : 0 : 100) + '...'
												"></p>
										</div>
										<div class="read-more mt-2">
											<a [routerLink]="
													'/blog/' +
													httpService.compressSLUG(item.slug)
												">Read Blog
												<i class="fa fa-long-arrow-right"></i></a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<a style="margin-bottom: 25px;" class="blog-button" [routerLink]="'/blog/'">VIEW ALL</a>
				</div>
				<div class="col-lg-4">
					<div class="">
						<img class="blogimg mb-lg-4" src="assets/img/blog-bg.png" width="126%" alt="" />
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- Blog End -->

	<!-- Video Request Start -->
	<div class="video-section">
		<div class="container">
			<div class="row">
				<div class="col-lg-10">
					<div class="video-content">
						<div class="video-heading">
							<h3>Request A Video Consultation</h3>
							<p>
								Lorem ipsum dolor sit amet, consectetur
								adipiscing elit, sed do eiusmod tempor
								incididunt ut labore et dolore magna aliqua. Ut
								enim ad minim veniam, quis nostrud exercitation
								ullamco laboris nisi ut aliquip ex ea commodo
								consequat.
							</p>
						</div>
						<div class="video-form">
							<form class="form" (submit)="submitForm($event)" [formGroup]="videoRequestForm">
								<div class="row">
									<div class="col-md-4 form-group">
										<label>Your Full Name*</label>
										<input name="name" id="name" formControlName="name" class="form-control"
											required />
									</div>
									<div class="col-md-4 form-group">
										<label>Email*</label>
										<input name="email" id="email" formControlName="email" class="form-control"
											required />
									</div>
									<div class="col-md-4 form-group">
										<label>Phone*</label>
										<input type="number"  name="mobile" id="mobile" formControlName="mobile" class="form-control" required oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
										maxlength = "10"/>
									</div>

									
								</div>
								<div class="row mt-4">
									<div class="col-md-12">
										<div class="form-group">
											<label>Message*</label>
											<textarea rows="6" name="message" id="message" formControlName="message"
												class="form-control" required></textarea>
										</div>
									</div>
									<div class="col-md-12 mt-4">
										<div class="form-group">
											<button class="btn btn-md btn-theme" type="submit">
												SUBMIT
											</button>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- Video Request End -->

	<!-- Testimonial Start -->
	<!-- <div class="testimonial">
		<div class="container">
			<div class="row">
				<div class="col-lg-12 text-center">
					<div class="testimonial-heading">
						<p>CUSTOMER TESTIMONIALS</p>
						<h2>What Our Customers Say</h2>
					</div>
				</div>
			</div>
			<div class="row m0">
				<div class="col-lg-12 text-center">
					<div class="testimonial-content">
						<div class="row">
							<div class="col-sm-12 col-md-6 col-lg-4 p0 m0" *ngFor="let item of testimonials">
								<div class="testimonial-box">
									<div class="ratings">
										<span class="fa fa-star"></span>
										<span class="fa fa-star"></span>
										<span class="fa fa-star"></span>
										<span class="fa fa-star"></span>
										<span class="fa fa-star"></span>
									</div>
									<img class="card-img-top" [src]="
											httpService.generateImagePreview(
												item.author_image
											)
										" [alt]="item.title" style="height: 100px; width: 100px" />
									<div class="testimonial-author">
										<h6>{{ item.title }}</h6>
										<p>{{ item.visa_type }}</p>
									</div>
									<div class="testimonial-description">
										<p [innerHTML]="
												(item.description
													| slice : 0 : 100) + '...'
											"></p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div> -->

	
	<div class="testimonials">
		<div class="container">
			<div class="row">
				<div class="col-lg-12 text-center">
					<div class="testimonial-heading">
						<p>CUSTOMER TESTIMONIALS</p>
						<h2>What Our Customers Say</h2>
					</div>
				</div>
			</div>
			<div class="row justify-content-center">
				<div class="col-sm-12 col-md-12 text-center">
					<div id="testimonial-slider" *ngIf="testimonials && testimonials.length > 0">
						<owl-carousel-o [options]="testimonialConfig">
							<ng-container *ngFor="let slide of testimonials">
								<ng-template carouselSlide [id]="slide._id">
									<div class="item">
										<div class="testimonial-box">
											<img class="card-img-top" [src]="
												httpService.generateImagePreview(
													slide.author_image
												)
											" [alt]="slide.title" />
											<!-- <h6>{{ slide.title }}</h6>
											<p>{{ slide.visa_type }}</p> -->
											<!-- <p [innerHTML]="
													(slide.description
														| slice : 0 : 100) + '...'
												"></p> -->
										</div>
									</div>
								</ng-template>
							</ng-container>
						</owl-carousel-o>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- Testimonial End -->

	<!-- Associate Start -->
	<div class="container">
		<div class="row justify-content-center text-center" style="margin-top: 35px">
			<div class="col-12">
				<div class="associate-bg">
					<h4>Our Associates</h4>
					<p>
						Meet our School and University associates. We have so
						many associates in the entire country of Australia and
						soon around the world.
					</p>
				</div>
			</div>
			<div class="row justify-content-center text-center" style="margin: 35px 0px 20px 0px">
				<div class="col-lg-12">
					<div class="associate-box">
						<img src="assets/img/Sage.jpg" alt="Australian Institute" />
					</div>
					<div class="associate-box">
						<img src="assets/img/reach.jpg" alt="Australian Institute" />
					</div>
					<div class="associate-box">
						<img src="assets/img/everthought.jpg" alt="Australian Institute" />
					</div>
					<div class="associate-box">
						<img src="assets/img/Brisbane.jpg" alt="Australian Institute" />
					</div>
					<div class="associate-box">
						<img src="assets/img/avta.jpg" alt="Australian Institute" />
					</div>
					<div class="associate-box">
						<img src="assets/img/page.jpg" alt="Australian Institute" />
					</div>
					<div class="associate-box">
						<img src="assets/img/npa.jpg" alt="Australian Institute" />
					</div>
					<div class="associate-box">
						<img src="assets/img/aibt.jpg" alt="Australian Institute" />
					</div>
				</div>
			</div>
		</div>
	</div>

	<!-- <div class="associate">
		<div class="container-fluid p0 m0">
			<div class="row m0">
				<div class="col-lg-12 p0">
					<div class="associate-bg">
						<h4>Our Associates</h4>
						<p>Meet our School and University associates. We have so many associates in the entire country
							of Australia and soon around the world.</p>
					</div>
					<div class="associate-content">
						<div class="associate-box">
							<img src="assets/img/australian-institue.png" alt="Australian Institute" />
						</div>
						<div class="associate-box">
							<img src="assets/img/chisolm.png" alt="Australian Institute" />
						</div>
						<div class="associate-box">
							<img src="assets/img/university-of-tasmania.png" alt="Australian Institute" />
						</div>
						<div class="associate-box">
							<img src="assets/img/victoria-university.png" alt="Australian Institute" />
						</div>
						<div class="associate-box">
							<img src="assets/img/victorian-i-t.png" alt="Australian Institute" />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div> -->
	<!-- Associate End -->

	<!-- Counts Start -->
	<!-- <div class="success-rate-section">
		<div class="container position-relative">
			<div class="success-logo"></div>
			<div class="row">
				<div class="col-lg-12">
					<div class="section-container">
						<div class="section-heading">
							<h3>Request A Video Consultation</h3>
							<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
								incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
								exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
						</div>
						<div class="section-content">
							<div class="row">
								<div class="col-md-6 col-lg-3">
									<div class="success-box">
										<h1 [countUp]="successRate.students">
											0
											<span>+</span>
										</h1>
										<p>Students</p>
									</div>
								</div>
								<div class="col-md-6 col-lg-3">
									<div class="success-box">
										<h1 [countUp]="successRate.visa">
											0
											<span>+</span>
										</h1>
										<p>Visas processed</p>
									</div>
								</div>
								<div class="col-md-6 col-lg-3">
									<div class="success-box">
										<h1 [countUp]="successRate.processing">
											0
											<span>+</span>
										</h1>
										<p>Currently processing</p>
									</div>
								</div>
								<div class="col-md-6 col-lg-3">
									<div class="success-box">
										<h1 [countUp]="successRate.nationality">
											0
											<span>+</span>
										</h1>
										<p>Nationalities</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div> -->
	<!-- Counts End -->
</section>