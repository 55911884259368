<app-breadcrumb></app-breadcrumb>
<section>
	<div class="gallery-details">
		<div class="container">
			<div class="row justify-content-center">
				<div class="col-xl-12">
					<div class="gallery-title">
						<h1>{{gallery.name}}</h1>
					</div>
				</div>
				<div class="col-xl-12">
					<div class="gallery-box">
						<div class="gallery-album" *ngIf="album && album.length > 0">
							<div class="album-title">Image Gallery</div>
							<ng-container *ngFor="let item of album; let i=index">
								<div class="album-item">
									<img [src]="item.src" (click)="openLightBox(i)" [alt]="gallery.name" />
								</div>
							</ng-container>
						</div>
						<div class="gallery-album" *ngIf="gallery && gallery.images && gallery.images.length > 0">
							<div class="album-title">Video Gallery</div>
							<ng-container *ngFor="let item of gallery.images; let i=index">
								<div class="album-item" *ngIf="item.type ==='video'">
									<iframe
										[src]="getMedia(item)"
										[title]="gallery.name"
										frameborder="0"
										allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
										allowfullscreen
									></iframe>
								</div>
							</ng-container>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
