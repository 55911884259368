<div class="header fixed-top">
	<div class="mini-header">
		<div class="container">
			<div class="row">
				<div class="col-md-4">
					<div class="title">
						<div class="social">
							<ul>
								<li><a href="https://www.facebook.com/divinemigration?mibextid=LQQJ4d" target="_blank"><i class="fa fa-facebook"></i></a></li>
								<li><a href="https://instagram.com/divinemigration?igshid=YmMyMTA2M2Y=" target="_blank"><i class="fa fa-instagram"></i></a></li>
								<li><a href="https://www.linkedin.com/company/divinemigration/" target="_blank"><i class="fa fa-linkedin"></i></a></li>
								<!-- <li><a href="#"><i class="fa fa-youtube"></i></a></li>
								<li><a href="#"><i class="fa fa-twitter"></i></a></li> -->
							</ul>
						</div>
					</div>
				</div>
				<div class="col-md-8 pe ps">
					<div class="social-info">
						<div style="margin-top: 10px;" class="info">
							<p>Talk to Our Expert</p>
							<a href="tel:+61 470 203 314"> <p>+61 470 203 314</p></a>
							<button type="button" class="btn" data-bs-toggle="modal"
										data-bs-target="#exampleModal" data-bs-whatever="@getbootstrap">
										Inquire Now
									</button>
									

									<div class="modal fade" id="exampleModal" tabindex="-1"
										aria-labelledby="exampleModalLabel" aria-hidden="true">
										<div class="modal-dialog modal-lg">
											<div class="modal-content">
												<div class="modal-header">
													<h5 style="color: black;" class="modal-title" id="exampleModalLabel">
														Inquire Now
													</h5>
													<button type="button" class="btn-close" data-bs-dismiss="modal"
														aria-label="Close"></button>
												</div>
												<div class="modal-body">
													<section>
														<div class="contact-us">
															<div class="container">
																<div class="row justify-content-center">
																	<div class="col-xl-12">
																		<div class="contact-title">
																			<!-- <h1>Contact Us</h1> -->
																		</div>
																	</div>
																	<div class="col-xl-12">
																		<div class="contact-box">
																			<div class="contact-content">
																				<form class="form contact-form" (submit)="submitForm($event)"  [formGroup]="contactForm">
																					<div class="row">
																						<div class="col-md-6">
																							<div class="form-group">
																								<label for="name">Your Name*</label>
																								<input name="name" id="name" formControlName="name" class="form-control" required/>
																							</div>
																						</div>
																						<div class="col-md-6">
																							<div class="form-group">
																								<label for="email">Your Email*</label>
																								<input name="email" id="email" formControlName="email" class="form-control" required/>
																							</div>
																						</div>
																					</div>
																					<div class="row">
																						<div class="col-md-6">
																							<div class="form-group">
																								<label for="mobile">Your Mobile*</label>
																								<input type="number"  name="mobile" id="mobile" formControlName="mobile" class="form-control" required oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
																								maxlength = "10"/>
																								
																							</div>
																						</div>
																						<div class="col-md-6">
																							<div class="form-group">
																								<label for="subject">Your Subject*</label>
																								<input name="subject" id="subject" formControlName="subject" class="form-control" required/>
																							</div>
																						</div>
																					</div>
																					<div class="row">
																						<div class="col-md-12">
																							<div class="form-group">
																								<label for="message">Your Query*</label>
																								<textarea rows="4" name="message" id="message" formControlName="message" class="form-control" required></textarea>
																							</div>
																						</div>
																					</div>
																					<div class="row">
																						<div class="col-md-12">
																							<div class="form-group submitbtn">
																								<button type="button" class="btn btn-secondary mx-2"
																								data-bs-dismiss="modal">
																								Close
																							</button>
																								<button class="btn btn-lg btn-theme" type="submit">Submit</button>
																							</div>
																						</div>
																					</div>
																					
																				</form>
																			</div>
													
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</section>
												</div>
											</div>
										</div>
									</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	

<!-- Navbar -->
<nav class="navbar navbar-expand-lg navbar-light bg-light">
	<a class="navbar-brand" routerLink="/home">
		<img src="assets/img/logo.svg" alt="Logo"/>
	</a>
  
	<button class="navbar-toggler" type="button"
			(click)="toggleNavbar()" style="margin-right: 1rem;">
	  <span class="navbar-toggler-icon"></span>
	</button>
  
  
	<div class="collapse navbar-collapse"
		 [ngClass]="{ 'show': navbarOpen }">
		 <ul class="navbar-nav me-auto mb-2 mb-lg-0 justify-content-end">
			<li class="nav-item">
				<a class="nav-link" aria-current="page"  routerLink="/home" routerLinkActive="active">Home</a>
			</li>
			<!-- <li class="nav-item">
				<a class="nav-link" routerLink="/visa-service" routerLinkActive="active">Visa</a>
			</li>
			<li class="nav-item">
				<a class="nav-link" routerLink="/Other-service" routerLinkActive="active">Services</a>
			</li> -->

			  <li class="nav-item dropdown">
				<a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
				  Services
				</a>
				<ul class="dropdown-menu" aria-labelledby="navbarDropdown">
				  <li><a class="nav-link dropdown-item" routerLink="/eduction-service" >Education Services</a></li>
				  <li><a class="nav-link dropdown-item" routerLink="/immigration-service">Immigration Services</a></li>
				 
				</ul>
			  </li>

			<li class="nav-item">
				<a class="nav-link" routerLink="/about-us" routerLinkActive="active">Abous Us</a>
			</li>
			<li class="nav-item">
				<a class="nav-link" routerLink="/blog" routerLinkActive="active">Blog</a>
			</li>
			
			<li class="nav-item">
				<a class="nav-link" routerLink="/gallery" routerLinkActive="active">Gallery</a>
			</li>
			<li class="nav-item">
				<a class="nav-link" routerLink="/testimonial" routerLinkActive="active">Testimonial</a>
			</li>
			<li class="nav-item">
				<a class="nav-link" routerLink="/contact-us" routerLinkActive="active">Contact Us</a>
			</li>
			<li class="nav-item">
				<a class="nav-link" routerLink="/faq" routerLinkActive="active">FAQs</a>
			</li>
		</ul>
	</div>
  </nav>



  <!-- Navbar -->
</div>
