<app-breadcrumb></app-breadcrumb>
<section>
	<div class="terms-conditions">
		<div class="container">
			<div class="row justify-content-center">
				<div class="col-xl-12">
					<div class="terms-conditions-title">
						<h1>Terms & Conditions</h1>
					</div>
				</div>
				<div class="col-xl-12">
					<div class="terms-conditions-box">
						<div class="terms-conditions-content">
							<p>
								Cras ultricies ligula sed magna dictum porta. Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. Curabitur aliquet quam id dui posuere blandit. Sed porttitor lectus nibh. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a. Quisque velit nisi, pretium ut lacinia in, elementum id enim. Sed porttitor lectus nibh. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras ultricies ligula sed magna dictum porta. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec rutrum congue leo eget malesuada.
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

