import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { HttpService, ToastService } from 'src/app/services';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
	perPageBlog: number = 4;
	perPage: number = 6;
	pageNo: number = 1;
	loading: boolean = false;
	hasPage: boolean = true;
	public serviceSlider: any = [];
  constructor(
		public httpService: HttpService,
		public toaster: ToastService,
		private http: HttpClient,
		
	) {}
  navbarOpen = false;
	public contactForm = new FormGroup({
		name: new FormControl(null),
		email: new FormControl(null),
		subject: new FormControl(null),
		mobile: new FormControl(null),
		message: new FormControl(null)
	});
  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }
  ngOnInit(): void {
  }
  submitForm(event:any){
		event.preventDefault();
		if(!this.contactForm.valid){
			this.toaster.generateToast('info', 'Validation Error!', "Please fill out the form!");
			return
		}
		this.http.post(this.httpService.mainUrl + 'submitQuery', this.contactForm.value).subscribe((res:any) => {
			if(res.status === '1'){
				this.toaster.generateToast('success', 'Success!', res.message);
				this.contactForm.patchValue({
					name: "",
					email: "",
					subject: "",
					mobile: "",
					message: "",
				})
			}else {
				this.toaster.generateToast('warning', 'Failed!', res.message);
			}
		})

	}
	getService() {

		this.http.post(this.httpService.mainUrl + 'getServices', { pageNo: this.pageNo, perPage: this.perPage }).subscribe((res: any) => {
			if (res.status === '1') {
				const data = res.data && Array.isArray(res.data) ? res.data : [];
				if (data.length < this.perPage) {
					this.hasPage = false;
				}
				if (this.pageNo === 1) {
					this.serviceSlider = data;
				} else {
					res.data.map((item: any) => {
						this.serviceSlider.push(item);
					})
				}
			}
		})
	}
}
