import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { HttpService, ToastService } from 'src/app/services';

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
	public mainSliderConfig: any = {
		autoplay: true,
		loop: true,
		margin: 10,
		dots: false,
		nav: false,
		animateOut: 'fadeOut',
		animateIn: 'fadeIn',
		mouseDrag: false,
		autoplayTimeout: 6000,
		smartSpeed: 4000,
		responsiveClass: true,
		responsive: {
			0: {
				items: 1
			},
			600: {
				items: 1
			},
			1000: {
				items: 1
			}
		}
	};

	public serviceSliderConfig: any = {
		autoplay: true,
		loop: true,
		margin: 10,
		dots: true,
		nav: false,
		items: 3,
		animateOut: 'fadeOut',
		animateIn: 'fadeIn',
		mouseDrag: true,
		autoplayTimeout: 6000,
		smartSpeed: 4000,
		responsiveClass: true,
		responsive: {
			0: {
				items: 1
			},
			584: {
				items: 1
			},
			768: {
				items: 2
			},
			992: {
				items: 2
			},
			1200: {
				items: 3
			},
			1600: {
				items: 3
			}
		}
	};
	public testimonialConfig: any = {
		autoplay: true,
		loop: true,
		margin: 10,
		dots: true,
		nav: false,
		items: 2,
		animateOut: 'fadeOut',
		animateIn: 'fadeIn',
		mouseDrag: true,
		autoplayTimeout: 6000,
		smartSpeed: 4000,
		responsiveClass: true,
		responsive: {
			0: {
				items: 1
			},
			584: {
				items: 1
			},
			768: {
				items: 1
			},
			992: {
				items: 2
			},
			1200: {
				items: 2
			},
			1600: {
				items: 2
			}
		}
		
		
	};

	public mainSlider: any = [];

	public serviceSlider: any = [];

	public videoRequestForm = new FormGroup({
		name: new FormControl(null),
		email: new FormControl(null),
		mobile: new FormControl(null),
		message: new FormControl(null)
	});

	public blogs: any = [];

	public testimonials: any = [];

	successRate = {
		students: undefined || 0,
		visa: undefined || 0,
		processing: undefined || 0,
		nationality: undefined || 0,
	}
	counterUpConfig = {
	}
	constructor(
		public httpService: HttpService,
		public toaster: ToastService,
		private http: HttpClient,
	) { }
	perPageBlog: number = 4;
	perPage: number = 6;
	pageNo: number = 1;
	loading: boolean = false;
	hasPage: boolean = true;

	ngOnInit(): void {
		this.getService();
		this.getSlider();
		this.getBlogs();
		this.getTestimonialItems();
	}
	public contactForm = new FormGroup({
		name: new FormControl(null),
		email: new FormControl(null),
		subject: new FormControl(null),
		mobile: new FormControl(null),
		message: new FormControl(null)
	});

	getService() {

		this.http.post(this.httpService.mainUrl + 'getServices', { pageNo: this.pageNo, perPage: this.perPage }).subscribe((res: any) => {
			if (res.status === '1') {
				const data = res.data && Array.isArray(res.data) ? res.data : [];
				if (data.length < this.perPage) {
					this.hasPage = false;
				}
				if (this.pageNo === 1) {
					this.serviceSlider = data;
				} else {
					res.data.map((item: any) => {
						this.serviceSlider.push(item);
					})
				}
			}
		})
	}

	getSlider() {
		this.http.post(this.httpService.mainUrl + 'getSlider', {}).subscribe((res: any) => {
			if (res.status === '1') {
				const data = res.data && Array.isArray(res.data) ? res.data : [];

				if (data.length < this.perPage) {
					this.hasPage = false;
				}
				if (this.pageNo === 1) {
					this.mainSlider = data;
				} else {
					res.data.map((item: any) => {
						this.mainSlider.push(item);
					})
				}
				console.log(data);
				console.log("data  "+data.image);
			}

		})
	}

	getBlogs() {
		this.http.post(this.httpService.mainUrl + 'getBlogs', { pageNo: this.pageNo, perPage: this.perPageBlog }).subscribe((res: any) => {
			// console.log("data", res);

			if (res.status === '1') {
				const data = res.data && Array.isArray(res.data) ? res.data : [];
				if (data.length < this.perPage) {
					this.hasPage = false;
				}
				if (this.pageNo === 1) {
					this.blogs = data;
				} else {
					data.map((data: any) => {
						this.blogs.push(data);
					})
				}
			}
		})
	}

	submitForm(event: any) {
		event.preventDefault();
		if (!this.videoRequestForm.valid) {
			this.toaster.generateToast('info', 'Validation Error!', "Please fill out the form!");
			return
		}
		this.http.post(this.httpService.mainUrl + 'submitRequest', this.videoRequestForm.value).subscribe((res: any) => {
			console.log("res", res);

			if (res.status === '1') {
				this.toaster.generateToast('success', 'Success!', res.message);
				this.videoRequestForm.patchValue({
					name: "",
					email: "",
					mobile: "",
					message: "",
				})
			} else {
				this.toaster.generateToast('warning', 'Failed!', res.message);
			}
		})
	}

	getTestimonialItems() {
		this.http.post(this.httpService.mainUrl + 'getTestimonials', { pageNo: this.pageNo, perPage: this.perPage }).subscribe((res: any) => {
			if (res.status === '1') {
				const data = res.data && Array.isArray(res.data) ? res.data : [];
				if (data.length < this.perPage) {
					this.hasPage = false;
				}
				if (this.pageNo === 1) {
					this.testimonials = data;
				} else {
					data.map((item: any) => {
						this.testimonials.push(item);
					})
				}
			}
		})
	}

	onScroll(event: any) {
		event.stopPropagation();
		var B = document.body; //IE 'quirks'
		var D = document.documentElement; //IE with doctype
		D = (D.clientHeight) ? D : B;
		const scrollTop = D.scrollTop || 0;
		console.log('scrollTop', scrollTop)
		this.successRate = {
			students: undefined || 0,
			visa: undefined || 0,
			processing: undefined || 0,
			nationality: undefined || 0,
		}
		if (scrollTop > 5299 && scrollTop < 5980) {
			this.successRate = {
				students: 25,
				visa: 45,
				processing: 99,
				nationality: 50,
			}
		}
	}
	submitForm2(event:any){
		event.preventDefault();
		if(!this.contactForm.valid){
			this.toaster.generateToast('info', 'Validation Error!', "Please fill out the form!");
			return
		}
		this.http.post(this.httpService.mainUrl + 'submitQuery', this.contactForm.value).subscribe((res:any) => {
			if(res.status === '1'){
				this.toaster.generateToast('success', 'Success!', res.message);
				this.contactForm.patchValue({
					name: "",
					email: "",
					subject: "",
					mobile: "",
					message: "",
				})
			}else {
				this.toaster.generateToast('warning', 'Failed!', res.message);
			}
		})

	}
}
