import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Form, FormControl, FormGroup } from '@angular/forms';
import { HttpService, ToastService } from 'src/app/services';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

	constructor(
		public httpService: HttpService,
		private http: HttpClient,
		public toaster: ToastService,
	) { }

	public contactForm = new FormGroup({
		name: new FormControl(null),
		email: new FormControl(null),
		subject: new FormControl(null),
		mobile: new FormControl(null),
		message: new FormControl(null)
	});

	ngOnInit(): void {
	}


	submitForm(event:any){
		event.preventDefault();
		if(!this.contactForm.valid){
			this.toaster.generateToast('info', 'Validation Error!', "Please fill out the form!");
			return
		}
		this.http.post(this.httpService.mainUrl + 'submitQuery', this.contactForm.value).subscribe((res:any) => {
			if(res.status === '1'){
				this.toaster.generateToast('success', 'Success!', res.message);
				this.contactForm.patchValue({
					name: "",
					email: "",
					subject: "",
					mobile: "",
					message: "",
				})
			}else {
				this.toaster.generateToast('warning', 'Failed!', res.message);
			}
		})

	}
}
