<app-breadcrumb></app-breadcrumb>
<section>
	<div class="privacy-policy">
		<div class="container">
			<div class="row justify-content-center">
				<div class="col-xl-12">
					<div class="privacy-policy-title">
						<h1>Privacy Policy</h1>
					</div>
				</div>
				<div class="col-xl-12">
					<div class="privacy-policy-box">
						<div class="privacy-policy-content">
							<p>
								Proin eget tortor risus. Vivamus magna justo, lacinia eget consectetur sed, convallis at
								tellus. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a. Vivamus magna
								justo, lacinia eget consectetur sed, convallis at tellus. Vestibulum ac diam sit amet
								quam vehicula elementum sed sit amet dui. Sed porttitor lectus nibh. Mauris blandit
								aliquet elit, eget tincidunt nibh pulvinar a. Lorem ipsum dolor sit amet, consectetur
								adipiscing elit. Nulla porttitor accumsan tincidunt. Curabitur arcu erat, accumsan id
								imperdiet et, porttitor at sem. Vivamus suscipit tortor eget felis porttitor volutpat.
								Donec sollicitudin molestie malesuada. Vestibulum ac diam sit amet quam vehicula
								elementum sed sit amet dui.
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>