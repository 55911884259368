<app-breadcrumb></app-breadcrumb>
<section>
	<div class="service-details">
		<div class="container">
			<div class="row justify-content-center">
				<div class="col-xl-12">
					<div class="service-title">
						<h1>{{service.title}}</h1>
					</div>
				</div>
				<div class="col-xl-12">
					<div class="service-box">
						<div class="service-content">
							<div class="service-image d-flex justify-content-center">
								<img [src]="service.meta.image" [alt]="service.title"/>
							</div>
							<div class="service-info">
								<span><i class="fa fa-calendar"></i>{{service.createdAt | date : 'dd/MM/YYYY'}}</span>
							</div>
							<div class="service-description" [innerHTML]="service.description"></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
