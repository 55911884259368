<app-breadcrumb></app-breadcrumb>
<section>
	<div class="about-us">
		<div class="container">
			<div class="row justify-content-center">
				<div class="col-xl-12">
					<div class="about-title">
						<h1>WHO ARE WE?</h1>
					</div>
				</div>
				<div class="col-xl-12">
					<div class="about-box">
						<div class="about-content">
							<p>
								Presha Pandya is the brainchild behind <i><b>Divine Migration & Education Consultant Specialists</b></i>. She lived in the UK for 3 years pursuing her studies and then settled in Brisbane, Australia. Having been in the UK and now in Australia, she understands the challenges faced by migrants. Having interacted closely with similar individuals like her, she set her target on a career path wanting to help students and migrants. She perused Graduate Diploma in Migration Law from the University of Technology (UTS). She worked in the migration industry assisting with different visas ranging from student visa to work visas until she finally wanted to spread her wings and start her own journey and Divine Migration and Education Consultants was successfully established in 2022. With years of experience behind her she is popular for her diligence and extensive track record. Presha spearheads a team of well experienced individuals who are experts in many fields to give you an overall positive migration experience. 
							</p>
						</div>
					</div>
				</div>
			</div>

			<div class="row justify-content-center">
				<div class="col-xl-12">
					<div class="about-title">
						<h1>WHY CHOOSE US?</h1>
					</div>
				</div>
				<div class="col-xl-12">
					<div class="about-box">
						<div class="about-content">
							<p>
								Australia is a dream destination for thousands of students from across the globe and choosing to study abroad is a dream for many as it changes the trajectory of their entire future! We at Divine Migration & Education Consultant Specialists strive to make the process easy for you. We help you navigate and guide you through the entire process letting you avoid the stress and confusion of it all!
							</p>
							<br>
							<p>
								While many migration and education consultants focus on a specific region or country, Divine Migration & Education Consultant Specialists cater globally with local agents in many countries. This gives us the advantage of in-depth knowledge of your local market and your requirements.
							</p>
							<br>
							<p>
								Equipped with specialized and certified visa consultants, we have a very positive track record of being one of the most efficient migration consultants in Australia.
							</p>
							<br>
							<p>
								Our certified visa consultants will go that extra mile for you because client satisfaction above all is what keeps us focused. We keep you updated every step of the way because interaction with our clients helps us build and strengthen our relationship before you actually arrive in Australia. We ensure that your effort is minimal because we assure you a quality service not many can match!
							</p>
							<br>
							<p>
								Our job does not end by getting you your visa approval. Once you arrive in Australia, we ensure that we help you settle in your accommodation and giving you tips and techniques to find your footing and guide you on how to secure employment as well. 
							</p>
							<br>
							<p>
								Divine Migration & Education Consultant Specialists is your link to making your migration experience stress-free!
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

