import { HttpInterceptor, HttpRequest, HttpHandler, HttpErrorResponse, HttpEvent, HttpHeaders, HttpClient } from '@angular/common/http';
import { catchError, mergeMap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Auth, AppState, mainUrl } from 'src/app/store';

const REFRESH_TOKEN_URL = "http://maaschoice.in:1790/api/";

@Injectable({
	providedIn: 'root'
})
export class ApiInterceptor implements HttpInterceptor {
	auth:typeof Auth = Auth;
	apiURL = mainUrl;
	constructor(
		private http: HttpClient,
		private store: Store<AppState>,
	) {}

	intercept(request: HttpRequest<any>, next: HttpHandler) {
		// return as it was if url is bellow
		// let originalRequest = request.clone({body: {...request.body, end_user_id: null}})
		if (request.url.includes("login") || request.url.includes("forgot-password"))
		return next.handle(request);

		this.store.forEach(data => { this.auth = data.main && data.main.auth ? data.main.auth: Auth});
		// console.log("auth", this.auth)
		let headers = new HttpHeaders({
			'Authorization': `Baerer ${this.auth && this.auth.secret ? this.auth.secret : ''}`,
			'Content-MD5': `${this.auth && this.auth.secret ? this.auth.secret :''}`,
			'Content-Type': 'application/json'
		});
		const whitelisted = [
            "https://165.232.180.214:8081/Development/api/uploadUsersImage",
            "https://165.232.180.214:8081/Development/api/uploadReviewImage",
			"https://divine.in:3011/Development/api/uploadUsersImage",
            "https://divine.in:3011/Development/api/uploadReviewImage",
            "https://localhost:8081/Development/api/uploadUsersImage",
            "https://localhost:8081/Development/api/uploadReviewImage",
        ];
		//Excellent Product
		// console.log('is File Type Request', (whitelisted.includes(request.url)))
        if(whitelisted.includes(request.url) === true){
			headers = new HttpHeaders({
				'Authorization': `Baerer ${this.auth && this.auth.secret ? this.auth.secret : ''}`,
				'Content-MD5': `${this.auth && this.auth.secret ? this.auth.secret :''}`,
				// 'Content-Type': 'multipart/form-data',
			});
        }
		if(typeof request.body === 'string'){

			request.body + `&end_user_id=${this.auth && this.auth._id ? this.auth._id :''}`;
		}else {
			request.body.end_user_id = null;
			request.body.end_user_id = `${this.auth && this.auth._id ? this.auth._id :''}`;
		}
		const newRequest = request.clone({headers})
		return next.handle(newRequest).pipe(catchError(err => {
			// if(err instanceof HttpErrorResponse) {
			// 	return throwError(err);
			// 	if(err.status === 400) {
			// 	// Handle unauthorized error

			// 	const body = { refreshToken: window.localStorage.getItem("refresh-token") };
			// 	return this.http.post(REFRESH_TOKEN_URL, body)
			// 		.pipe(mergeMap((res) => {
			// 		window.localStorage.setItem("token", res['token']);
			// 		window.localStorage.setItem("refresh-token", res['refreshToken']);

			// 		// Making request with new token.
			// 		return next.handle(newRequest.clone(
			// 			{ headers: newRequest.headers.set('Authorization', res['token']) }
			// 		));
			// 		}), catchError(err => {
			// 		if (err instanceof HttpErrorResponse && err.status === 400) {
			// 			// Refresh Token is also expired
			// 			// So logout user
			// 			// logout()
			// 		}
			// 		return throwError(err);
			// 		}));
			// 	}
			// }
			return new Observable<HttpEvent<any>>();
		}));
	}

}
