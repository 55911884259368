// export const mainUrl = 'http://localhost:3010/Development/api/';
// export const imageUrl = 'http://localhost:3010/';
// export const chatUrl = 'http://localhost:3020/Development/api/';
// export const chatApiUrl = 'http://localhost:3020/Development/api/';
// export const chatImgUrl = 'http://localhost:3020/';

// Old Server Live 
// export const mainUrl = 'http://165.232.180.214:8081/Development/api/';
// export const imageUrl = 'http://165.232.180.214:8081/';
// export const chatUrl = 'http://165.232.180.214:8081/Development/api/';
// export const chatApiUrl = 'http://165.232.180.214:8081/Development/api/';
// export const chatImgUrl = 'http://165.232.180.214:8081/';

// New Server Live 
export const mainUrl = 'http://139.59.1.7:8081/Development/api/';
export const imageUrl = 'http://139.59.1.7:8081/';
export const chatUrl = 'http://139.59.1.7:8081/Development/api/';
export const chatApiUrl = 'http://139.59.1.7:8081/Development/api/';
export const chatImgUrl = 'http://139.59.1.7:8081/';

//Local
// export const mainUrl = 'http://127.0.0.1:8081/Development/api/';
// export const imageUrl = 'http://127.0.0.1:8081/';
// export const chatUrl = 'http://127.0.0.1:8081/Development/api/';
// export const chatApiUrl = 'http://127.0.0.1:8081/Development/api/';
// export const chatImgUrl = 'http://127.0.0.1:8081/';


export const Auth = {
	_id: "",
	email: "",
    user_type: false,
    social_id: null,
    pass_string: "",
    mobile: "",
    secret: "",
    first_name: null,
    last_name: null,
    gender: true,
    image: null,
    status: true,
};

export const Company = {
	_id: "",
	name : "",
    logo : "",
    office_address : "",
    lat : "",
    long : "",
	mapURL: "",
    contact_number : "",
    contact_email : "",
    support_email : "",
    sales_email : "",
    facebook_link : "",
    instagram_link : "",
    youtube_link : "",
    twitter_link : "",
    pinterest_link : "",
    linkedin_link : "",
    google_plus_link : "",
};

export const Rights = [];

export const Cart = [];

export const Category = [];

export const Review = [];

export const Order = [];
