<footer>
	<div class="main-footer">
		<div class="container">
			<div class="row">
				<div class="col-lg-2 d-flex justify-content-center mb-4">
					<div class="footer-logo">
						<img src="assets/img/logo.svg" alt="Logo" />
					</div>
				</div>
				<div class="col-lg-2 col-sm-12 col-md-12">
					<div class="footer-tab tab-address" style="margin-left: -10px; margin-top: -5px;">
						<div class="footer-link">
							<h4 class="">CONTACT</h4>
							<ul>
								<li>
									<!-- <a href="#">
										<i
											class="fa fa-map-marker"
										></i>
										Suite 3.18 , 433 Logan Road,
										Greenslopes, QLD, 4120.
										Australia.
									</a> -->
								</li>
								<li>
									<a href="tel:+61 470 203 314">
										<i class="fa fa-phone"></i>
										+61 470 203 314
									</a>
								</li>
								<li>
									<a href="mailto:info@divinemigration.com">
										<i class="fa fa-envelope-o"></i>
										info@divinemigration.com</a>
								</li>
							</ul>
						</div>
						<div class="footer-link">
							<!-- <h4>TOLL FREE NUMBER</h4> -->
							<ul>
								<li>
									<!-- <a href="#">
										<i class="fa fa-phone"></i>
										1800 233 0999
									</a> -->
								</li>
							</ul>
						</div>
					</div>
				</div>
				<div class="col-lg-2 col-sm-12 col-md-12">
					<div class="" *ngIf="serviceSlider && serviceSlider.length > 0">
						<div class="footer-link">
							<h4>Education Services</h4>
							<ul *ngFor="let slide of serviceSlider let i = index">
								<li *ngIf="i<6">
									<a class="mb-5" *ngIf="slide.slug" [routerLink]="
											'/service/' +
											httpService.compressSLUG(slide.slug)
										" [queryParams]="{
											type: slide.type
										}">{{
											(slide.title
											| slice : 0 : 20) +
											"..."
											}}</a>
								</li>

								<!-- <li><a href="#">Temporary skill</a></li>
												<li><a href="#">shortage Partner Visa (On-shore)</a></li>
												<li><a href="#">Employer Nomination Scheme Visa</a></li>
												<li><a href="#">Graduate Visa </a></li>
												<li><a href="#">OBA PROCESS</a></li>
												<li><a href="#">Partner visa off-share</a></li> -->
							</ul>
						</div>
					</div>
				</div>
				<div class="col-lg-2 col-sm-12 col-md-12">
					<div class="" *ngIf="serviceSlider && serviceISlider.length > 0">
						<div class="footer-link">
							<h4>Immigration Services</h4>
							<ul *ngFor="let slide of serviceISlider let i = index">
								<li *ngIf="i<6">
									<a class="mb-5" *ngIf="slide.slug" [routerLink]="
											'/service/' +
											httpService.compressSLUG(slide.slug)
										" [queryParams]="{
											type: slide.type
										}">{{
											(slide.title
											| slice : 0 : 20) +
											"..."
											}}</a>
								</li>

								<!-- <li><a href="#">Temporary skill</a></li>
												<li><a href="#">shortage Partner Visa (On-shore)</a></li>
												<li><a href="#">Employer Nomination Scheme Visa</a></li>
												<li><a href="#">Graduate Visa </a></li>
												<li><a href="#">OBA PROCESS</a></li>
												<li><a href="#">Partner visa off-share</a></li> -->
							</ul>
						</div>
					</div>
				</div>
				<div class="col-lg-2 col-sm-12 col-md-12">
					<div class="footer-tab tab-support" style="margin-top: -5px;">
						<div class="footer-link">
							<h4>HELP & SUPPORT</h4>
							<ul>
								<li>
									<a routerLink="/terms-and-conditioins" routerLinkActive="active">Terms &
										Conditions</a>
								</li>
								<li>
									<a routerLink="/privacy-policy" routerLinkActive="active">Privacy Policy</a>
								</li>
								<li>
									<button type="button" class="btn btn-primary" data-bs-toggle="modal"
										data-bs-target="#exampleModal1" data-bs-whatever="@getbootstrap">
										Inquire Now
									</button>


									<div class="modal fade" id="exampleModal1" tabindex="-1"
										aria-labelledby="exampleModalLabel" aria-hidden="true">
										<div class="modal-dialog modal-lg">
											<div class="modal-content">
												<div class="modal-header">
													<h5 class="modal-title" id="exampleModalLabel">
														Inquire Now
													</h5>
													<button type="button" class="btn-close" data-bs-dismiss="modal"
														aria-label="Close"></button>
												</div>
												<div class="modal-body">
													<section>
														<div class="contact-us">
															<div class="container">
																<div class="row justify-content-center">
																	<div class="col-xl-12">
																		<div class="contact-title">
																			<!-- <h1>Contact Us</h1> -->
																		</div>
																	</div>
																	<div class="col-xl-12">
																		<div class="contact-box">
																			<div class="contact-content">
																				<form class="form contact-form"
																					(submit)="submitForm($event)"
																					[formGroup]="contactForm">
																					<div class="row">
																						<div class="col-md-6">
																							<div class="form-group">
																								<label for="name">Your
																									Name*</label>
																								<input name="name"
																									id="name"
																									formControlName="name"
																									class="form-control"
																									required />
																							</div>
																						</div>
																						<div class="col-md-6">
																							<div class="form-group">
																								<label for="email">Your
																									Email*</label>
																								<input name="email"
																									id="email"
																									formControlName="email"
																									class="form-control"
																									required />
																							</div>
																						</div>
																					</div>
																					<div class="row">
																						<div class="col-md-6">
																							<div class="form-group">
																								<label for="mobile">Your
																									Mobile*</label>
																								<input type="number"
																									name="mobile"
																									id="mobile"
																									formControlName="mobile"
																									class="form-control"
																									required
																									oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
																									maxlength="10" />

																							</div>
																						</div>
																						<div class="col-md-6">
																							<div class="form-group">
																								<label
																									for="subject">Your
																									Subject*</label>
																								<input name="subject"
																									id="subject"
																									formControlName="subject"
																									class="form-control"
																									required />
																							</div>
																						</div>
																					</div>
																					<div class="row">
																						<div class="col-md-12">
																							<div class="form-group">
																								<label
																									for="message">Your
																									Query*</label>
																								<textarea rows="4"
																									name="message"
																									id="message"
																									formControlName="message"
																									class="form-control"
																									required></textarea>
																							</div>
																						</div>
																					</div>
																					<div class="row">
																						<div class="col-md-12">
																							<div class="form-group submitbtn">
																								<button type="button"
																									class="btn btn-secondary mx-2"
																									data-bs-dismiss="modal">
																									Close
																								</button>
																								<button
																									class="btn btn-lg btn-theme"
																									type="submit">Submit</button>
																							</div>
																						</div>
																					</div>
																				</form>
																			</div>

																		</div>
																	</div>
																</div>
															</div>
														</div>
													</section>
												</div>
											</div>
										</div>
									</div>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<div class="col-lg-2 col-sm-12 col-md-12">
					<div class="footer-tab tab-social" style="margin-top: -5px;">
						<div class="footer-link">
							<h4>OFFICE TIMINGS</h4>
							<ul>
								<li>
									<a>Monday - Friday</a>
								</li>
								<li>
									<a>09:00 AM - 05:00 PM</a>
								</li>
							</ul>
						</div>
						<div class="footer-social d-flex justify-content-center">
							<ul>
								<li>
									<a href="https://www.facebook.com/divinemigration?mibextid=LQQJ4d" target="_blank"><i class="fa fa-facebook"></i></a>
								</li>
								<li>
									<a href="https://instagram.com/divinemigration?igshid=YmMyMTA2M2Y=" target="_blank"><i class="fa fa-instagram"></i></a>
								</li>
								<li>
									<a href="https://www.linkedin.com/company/divinemigration/" target="_blank"><i class="fa fa-linkedin"></i></a>
								</li>
								<!-- <li>
									<a href="#"><i class="fa fa-youtube"></i></a>
								</li>
								<li>
									<a href="#"><i class="fa fa-twitter"></i></a>
								</li> -->
							</ul>
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-12 text-center">
				<div class="copy-right">
					<p>
						© COPYRIGHT {{ currentYear }} Divine Migration &
						Education Consultants
					</p>
				</div>
			</div>
		</div>
	</div>
</footer>