import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { HttpService, ToastService } from 'src/app/services';
const dummyData: any = [];

@Component({
	selector: 'app-blog',
	templateUrl: './blog.component.html',
	styleUrls: ['./blog.component.scss']
})

export class BlogComponent implements OnInit {
	blogs: any = [];
	// blogs: any;
	perPage: number = 4;
	pageNo: number = 1;
	loading: boolean = false;
	hasPage: boolean = true;
	constructor(
		public httpService: HttpService,
		public toaster: ToastService,
		private http: HttpClient,
		private metaTagService: Meta,
		private route: ActivatedRoute,
	) { }
	ngOnInit(): void {
		this.getBlogItems()
	}
	getArray() {
		const length = this.blogs.length > 0 ? this.blogs.length : 4;
		let array = Array(length).fill(0).map((x, i) => i + 1);
		return array;
	}
	getBlogItems() {
		this.http.post(this.httpService.mainUrl + 'getBlogs', { pageNo: this.pageNo, perPage: this.perPage }).subscribe((res: any) => {
			// console.log("data", res);

			if (res.status === '1') {
				const data = res.data && Array.isArray(res.data) ? res.data : [];
				if (data.length < this.perPage) {
					this.hasPage = false;
				}
				if (this.pageNo === 1) {
					this.blogs = data;
				} else {
					data.map((data: any) => {
						this.blogs.push(data);
					})
				}
			}
		})
	}
	loadMoreItem(event: any) {
		this.loading = false;
		event.preventDefault();
		this.pageNo = this.pageNo + 1;
		this.getBlogItems();
		// console.log("teststts");
	}
}
