import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserModule, Meta } from '@angular/platform-browser';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { StoreModule } from '@ngrx/store';
import { reducer } from './store/reducers';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { CountUpModule } from 'ngx-countup';
import { LightboxModule } from 'ngx-lightbox';
// import{BlogComponent} from './components/blog/blog.component';
import {
	HeaderComponent,
	FooterComponent,
	BreadcrumbComponent,
	HomeComponent,
	ServiceComponent,
	ServiceDetailComponent,
	BlogComponent,
	BlogDetailComponent,
	AboutUsComponent,
	ContactUsComponent,
	PrivacyPolicyComponent,
	TermsConditionsComponent,
	FaqComponent,
	GalleryComponent,
	GalleryDetailComponent,
	TestimonialComponent,
} from './components';
import {
	Error404Component,
	MainComponent
} from './container';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { ToasterModule } from 'angular2-toaster';
import { A2hsService, ApiInterceptor, EmiterService, HttpService, ToastService } from './services';
import { AuthGuard, ModifyMetaGuard } from './guard';
import { EductionService } from './components/eductionService/eductionService.component';

@NgModule({
	schemas:[
		NO_ERRORS_SCHEMA,
		CUSTOM_ELEMENTS_SCHEMA,
	],
	declarations: [
		AppComponent,
		Error404Component,
		MainComponent,
		HeaderComponent,
		FooterComponent,
		BreadcrumbComponent,
		HomeComponent,
		ServiceComponent,
		EductionService,
		ServiceDetailComponent,
		BlogComponent,
		BlogDetailComponent,
		AboutUsComponent,
		ContactUsComponent,
		PrivacyPolicyComponent,
		TermsConditionsComponent,
		FaqComponent,
		GalleryComponent,
		GalleryDetailComponent,
		TestimonialComponent,
	],
	imports: [
		ServiceWorkerModule.register('ngsw-worker.js', {
			enabled: environment.production,
			// Register the ServiceWorker as soon as the application is stable
			// or after 30 seconds (whichever comes first).
			registrationStrategy: 'registerWhenStable:30000'
		}),
		BrowserModule.withServerTransition({ appId: 'serverApp' }),
		AppRoutingModule,
		HttpClientModule,
		FormsModule,
		ReactiveFormsModule,
		CommonModule,
		BrowserAnimationsModule,
		StoreModule.forRoot({main: reducer}),
		ToasterModule.forRoot(),
		CarouselModule,
		NgxSkeletonLoaderModule.forRoot({ animation: 'pulse', loadingText: 'loading...' }),
		CountUpModule,
		LightboxModule,
	],
	providers: [
		HttpService,
		EmiterService,
		ToastService,
		Meta,
		A2hsService,
		AuthGuard,
		ModifyMetaGuard,
		{provide: LocationStrategy, useClass: PathLocationStrategy},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: ApiInterceptor,
			multi: true
		},
		Meta,
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
