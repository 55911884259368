<app-breadcrumb></app-breadcrumb>
<section>
	<div class="testimonial">
		<div class="container">
			<div class="row justify-content-center">
				<div class="col-sm-12">
					<div class="testimonial-box">
						<div class="testimonial-content">
							<div class="row testimonial-list" *ngIf="testimonials.length == 0 || loading">
								<ng-container *ngFor="let item of getArray(); let i=index">
									<div class="col-sm-12 col-md-6">
										<div class="card testimonial-item">
											<ngx-skeleton-loader count="1" class="card-img-top" appearance="" animation="progress"></ngx-skeleton-loader>
											<div class="card-body">
												<h5 class="card-title">
													<ngx-skeleton-loader count="1" appearance="" animation="progress"></ngx-skeleton-loader>
												</h5>
												<p class="card-text">
													<ngx-skeleton-loader count="3" appearance="" animation="progress"></ngx-skeleton-loader>
												</p>
												<span>
													<ngx-skeleton-loader count="1" appearance="" animation="progress"></ngx-skeleton-loader>
												</span>
												<span>
													<ngx-skeleton-loader count="1" appearance="" animation="progress"></ngx-skeleton-loader>
												</span>
											</div>
										</div>
									</div>
								</ng-container>
							</div>
							<div class="row testimonial-list" *ngIf="testimonials.length > 0 && !loading">
								<ng-container *ngFor="let item of testimonials;">
									<div class="col-sm-12 col-md-6">
										<div class="card testimonial-item">
											<ng-container *ngIf="item.type == true; else videoTemplate">
												<img style="margin-top: 25px;" class="card-img-top" [src]="httpService.generateImagePreview(item.author_image)" [alt]="item.title">
												<div class="card-body">
													<!-- <h5 class="card-title">{{item.title}}</h5> -->
													<!-- <p class="card-text" [innerHTML]="item.description">
													</p> -->
													<!-- <span>{{item.visa_type}} For: {{item.country}}</span> -->
												</div>
											</ng-container>
											<ng-template #videoTemplate>
												<iframe
													height="315"
													[src]="getYoutubeEmbededLink(item.description)"
													[title]="item.title"
													frameborder="0"
													allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
													allowfullscreen
												></iframe>
												<div class="card-body">
													<!-- <h5 class="card-title">{{item.title}}</h5> -->
													<!-- <span>{{item.visa_type}} For: {{item.country}}</span> -->
												</div>
											</ng-template>
										</div>
									</div>
								</ng-container>
							</div>
							<div class="load-more" *ngIf="hasPage">
								<button class="btn btn-md btn-theme" (click)="loadMoreItem($event)">Load More</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
