<app-breadcrumb></app-breadcrumb>
<section>
	<div class="blog-details">
		<div class="container">
			<div class="row justify-content-center">
				<div class="col-xl-12">
					<div class="blog-title">
						<h1>{{blog.title}}</h1>
					</div>
				</div>
				<div class="col-xl-12">
					<div class="blog-box">
						<div class="blog-content">
							<div class="blog-image d-flex justify-content-center">
								<!-- <img [src]="blog.meta.image" [alt]="blog.title"/> -->
								<img [src]="blog.image" [alt]="blog.title"/>
							</div>
							<div class="blog-info">
								<span><i class="fa fa-calendar"></i>{{blog.createdAt | date : 'dd/MM/YYYY'}}</span>
								<!-- <span><i class="fa fa-th"></i>{{blog.category_id}}</span>
								<span><i class="fa fa-tag"></i>{{blog.tags_name}}</span> -->
							</div>
							<div class="blog-description" [innerHTML]="blog.description"></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>