<app-breadcrumb></app-breadcrumb>
<section>
	<div class="blog">
		<div class="container">
			<div class="row justify-content-center">
				<div class="col-sm-12 col-md-6 col-xl-9">
					<div class="blog-box">
						<div class="blog-content">
							<div class="blog-list" *ngIf="blogs.length == 0 || loading">
								<ng-container *ngFor="let item of getArray(); let i=index">
									<div class="blog-item">
										<div class="blog-image">
											<ngx-skeleton-loader count="1" appearance=""
												animation="progress"></ngx-skeleton-loader>
										</div>
										<div class="blog-header">
											<h1 class="title">
												<a>
													<ngx-skeleton-loader count="2" appearance=""
														animation="progress"></ngx-skeleton-loader>
												</a>
											</h1>
										</div>
										<div class="blog-content">
											<span>
												<ngx-skeleton-loader count="3" appearance=""
													animation="progress"></ngx-skeleton-loader>
											</span>
											<p>
												<ngx-skeleton-loader count="6" appearance=""
													animation="progress"></ngx-skeleton-loader>
											</p>
											<a><ngx-skeleton-loader count="1" appearance=""
													animation="progress"></ngx-skeleton-loader></a>
										</div>
									</div>
								</ng-container>
							</div>
							<div class="blog-list" *ngIf="blogs.length > 0 && !loading">
								<ng-container *ngFor="let item of blogs">
									<div class="blog-item" trackBy:item._id>
										<div class="blog-image">
											<img [src]="item.meta.image" [alt]="item.title" />
											<!-- <img [src]="item.image" [alt]="item.title"/> -->
										</div>
										<div class="blog-header">
											<h1 class="title">
												<h2 class="title">
													<a [routerLink]="'/blog/' + httpService.compressSLUG(item.slug)"
														[queryParams]="{slug: item.slug}">
														{{item.title}}
													</a>
												</h2>
											</h1>
										</div>
										<div class="blog-content">
										
											<p [innerHTML]="item.small_description"></p>
											<a [routerLink]="'/blog/' + httpService.compressSLUG(item.slug)">Read More
												<i class="fa fa-long-arrow-right"></i></a>

											
										</div>
									</div>
								</ng-container>
							</div>
							<div class="load-more" *ngIf="hasPage">
								<button class="btn btn-md btn-theme" (click)="loadMoreItem($event)">Load More</button>
							</div>
						</div>
					</div>
				</div>
				<div class="col-sm-12 col-md-6 col-xl-3">
					<div class="blog-sidebar">
						<div class="popular-category">
							<h5>Popular Category</h5>
							<div class="list">
								<div class="item">
									<!-- <p>Student Visa <span>(4)</span></p> -->
									<p>Student Visa</p>
								</div>
								<div class="item">
									<!-- <p>Migration Visa <span>(6)</span></p> -->
									<p>Migration Visa</p>
								</div>
								<div class="item">
									<!-- <p>Other Visa <span>(8)</span></p> -->
									<p>Other Visa</p>
								</div>
								<div class="item">
									<!-- <p>Education Visa <span>(5)</span></p> -->
									<p>Education Visa</p>
								</div>
							</div>
						</div>
						<div class="popular-tags">
							<h5>Popular Tags</h5>
							<div class="list">
								<h4>Visa</h4>
								<h4>Trending</h4>
								<h4>Migration</h4>
								<h4>Education</h4>
								<h4>Student</h4>
							</div>
						</div>
						<div class="latest-posts">
							<h5>Latest Post</h5>
							<div class="list" *ngFor="let item of blogs">
								<div class="item">
									<!-- <img src="assets/img/blog-1.png"> -->
									<!-- <img [src]="item.meta.image" [alt]="item.title"/> -->
									<img [src]="item.meta.image" [alt]="item.title" />
									<div class="content">
										<!-- <p>
											<a routerLink="/blog/details">Cras ultricies ligula sed magna dictum porta.</a>
										</p> -->
										<a [routerLink]="'/blog/' + httpService.compressSLUG(item.slug)"
											[innerHTML]="(item.small_description | slice:0:100) + '...'"></a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>