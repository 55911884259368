<app-breadcrumb></app-breadcrumb>
<section>
	<div class="service">
		<div class="container">
			<div class="row justify-content-center">
				<!-- <div class="col-xl-12">
					<div class="service-title">
						<h1>Visa Services</h1>
					</div>
				</div> -->
				<div class="col-xl-12">
					<div class="service-box">
						<div class="service-content">
							<div class="service-list" *ngIf="services.length == 0 || loading">
								<div class="row">
									<ng-container *ngFor="let item of getArray(); let i=index">
										<div class="col-sm-12 col-md-6 col-lg-4">
											<div class="service-item noHeight">
												<div class="service-image">
													<ngx-skeleton-loader count="1" appearance="line"
														animation="progress"></ngx-skeleton-loader>
												</div>
												<div class="service-content">
													<h6>
														<ngx-skeleton-loader count="1" appearance=""
															animation="progress"></ngx-skeleton-loader>
													</h6>
													<p>
														<ngx-skeleton-loader count="3" appearance=""
															animation="progress"></ngx-skeleton-loader>
													</p>
													<a><ngx-skeleton-loader count="1" appearance=""
															animation="progress"></ngx-skeleton-loader></a>
												</div>
											</div>
										</div>
									</ng-container>
								</div>
							</div>
							<div class="service-list" *ngIf="services.length > 0 && !loading">
								<div class="row">
									<ng-container *ngFor="let item of services; let i=index">
										<div class="col-sm-12 col-md-6 col-lg-4">
											<div class="service-item">
												<div class="service-image">
													<img [src]="item.meta.image" [alt]="item.title" class="img-fluid">
													<!-- <img [src]="item.image" [alt]="item.title" class="img-fluid"> -->
												</div>
												<div class="service-content">
													<h6>{{(item.title  | slice:0:20)+'...'}}</h6>
													<!-- <p>{{item.description}}</p> -->
													<!-- <p>{{ (item.description | slice:0:100)+'...' }}</p> -->
													<!-- <p [innerHTML]="(item.description.length>6)? (item.description |
														slice:0:100)+'...':(item.description)"></p> -->
													<!-- <a *ngIf="item.slug"
														[routerLink]="'/service/' + httpService.compressSLUG(item.slug)"
														[queryParams]="{'slug': item.slug}">Read more</a> -->
													
													<div class="service-content">
														<a [routerLink]="'/service/' + httpService.compressSLUG(item.slug)">Read More
															<i class="fa fa-long-arrow-right"></i></a>
													</div>
												</div>
											</div>
										</div>
									</ng-container>
								</div>
							</div>
							<!-- <div class="load-more" *ngIf="hasPage">
								<button class="btn btn-md btn-theme" (click)="loadMoreItem($event)">Load More</button>
							</div> -->
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>