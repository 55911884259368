import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { HttpService, ToastService } from 'src/app/services';

@Component({
  selector: 'app-service',
  templateUrl: './eductionService.component.html',
  styleUrls: ['./eductionService.component.scss']
})
export class EductionService implements OnInit {
	services:any = [
		
	];
	
	loading: boolean = false;
	hasPage: boolean = true;
	constructor(
		public httpService: HttpService,
		public toaster: ToastService,
		private http: HttpClient,
	) { }

	ngOnInit(): void {
this.getService();
	}

	getArray() {
		const length = this.services.length > 0 ? this.services.length : 4;
		let array = Array(length).fill(0).map((x,i)=>i+1);
		return array;
	}
	// getDemoData() {
	// 	let i = 3;
	// 	dummyData.map((item:any) => {
	// 		if(i < this.perPage){
	// 			this.services.push(dummyData.splice(Math.floor(Math.random() * dummyData.length - 1), 1)[0]);
	// 		}
	// 		i++;
	// 	})
	// 	this.loading = false;
	// }

	getService() {
		// setTimeout(() => {
		// 	this.getDemoData();
		// }, 1500)
		this.http.post(this.httpService.mainUrl + 'getServices', {}).subscribe((res: any) => {
			if(res.status === '1'){
				const data = res.data && Array.isArray(res.data) ? res.data : [];
				
					data.map((item:any) => {
						if(item.type === true){

							this.services.push(item);
						}
					})
				
			}
		})
	}

	// loadMoreItem(event:any){
	// 	this.loading = false;
	// 	event.preventDefault();
	// 	// this.pageNo = this.pageNo + 1;
	// 	this.getService();
	// }

}
