import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { HttpService, ToastService } from 'src/app/services';

@Component({
  selector: 'app-service',
  templateUrl: './service.component.html',
  styleUrls: ['./service.component.scss']
})
export class ServiceComponent implements OnInit {
	services:any = [
		
	];
	// perPage:number = 6;
	// pageNo: number = 1;
	loading: boolean = false;
	hasPage: boolean = true;
	constructor(
		public httpService: HttpService,
		public toaster: ToastService,
		private http: HttpClient,
	) { }

	ngOnInit(): void {
this.getService();
	}

	getArray() {
		const length = this.services.length > 0 ? this.services.length : 4;
		let array = Array(length).fill(0).map((x,i)=>i+1);
		return array;
	}
	
	getService() {
		this.http.post(this.httpService.mainUrl + 'getServices', {}).subscribe((res: any) => {
			if(res.status === '1'){
				const data = res.data && Array.isArray(res.data) ? res.data : [];
				
					data.map((item:any) => {
						if(item.type === false){

							this.services.push(item);
						}
					})
				
			}
		})
	}
}
