import { ErrorHandler, Injectable, NgZone, OnInit} from '@angular/core';
import {ToasterService} from 'angular2-toaster';

@Injectable({
  providedIn: 'root',
})

export class ToastService implements ErrorHandler{
	constructor(
		private toasterService: ToasterService,
		private ngZone : NgZone

	) {
		this.toasterService = toasterService;
	}

	handleError(error: any) {
        this.ngZone.run(() => {
            this.toasterService.clear();
			this.toasterService.pop('error', "Something Wrong!", error)
        });
    }

	generateToast(type: any, title: string, body: string) {
		this.toasterService.clear();
		this.toasterService.pop(type, title, body);
		this.ngZone.run(() => {
            this.toasterService.clear();
			this.toasterService.pop(type, title, body);
        });
	}
}
