import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class EmiterService {
	private subject = new Subject<any>();

	emit(type:string, data: any) {
		this.subject.next({ type: type, data: data });
	}

	on(type:string): Observable<any> {
		return this.subject.asObservable();
	}
}
