import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RoutesRecognized } from '@angular/router';
import { HttpService } from 'src/app/services';
const visa_service = {
	path: '/visa-service',
	name: 'VISA SERVICE',
	isLink: true,
};
const other_service = {
	path: '/other-service',
	name: 'OTHER SERVICE',
	isLink: true,
};

const blog = {
	path: '/blog',
	name: 'BLOG',
	isLink: true,
};

const gallery = {
	path: '/gallery',
	name: 'GALLERY',
	isLink: true,
}

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {
	titleCharacterLimit = 25;
	title:any = "Home";
	titleLink:any = "Home";
	links:any = [
		{
			path: '/home',
			name: 'HOME',
			isLink: true,
		},
	];
	constructor(
		private router: Router,
		private route: ActivatedRoute,
		public cd: ChangeDetectorRef,
		private httpService: HttpService
	){
		this.router.events.forEach((event) => {
			if(event instanceof NavigationStart) {
			}
			if(event instanceof NavigationEnd) {
				let uri:string = event.url ? event.url : '';
				this.title = uri.split("/")[1].toLocaleUpperCase();
				let component:any = "";
				if(this.route.snapshot){
					component = this.route.snapshot.component;
				}
				let params:any = "";
				let query:any = "";
				if(this.route.snapshot){
					params = this.route.snapshot.params;
				}
				if(this.route.snapshot){
					query = this.route.snapshot.queryParams;
				}
				//GalleryDetailComponent
				if(component.name === 'BlogDetailComponent'){
					this.links.push(blog);
					if(this.route.snapshot.params){
						const params:any = this.route.snapshot.params;
						if(params.slug){
							this.title = decodeURIComponent(params.slug).toLocaleUpperCase();
							this.title = this.title.indexOf('?') > -1 ? this.title.split('?')[0] : this.title;
							this.title = this.title.split('-').join(" ");
							this.titleLink = this.title.length > this.titleCharacterLimit ? this.title.substring(0, this.titleCharacterLimit) + '...' : this.title;
							this.links.push({
								path: '',
								name: this.titleLink,
								isLink: false,
							});
						}
					}
				} else if(component.name === 'GalleryDetailComponent'){
					this.links.push(gallery);
					if(this.route.snapshot.params){
						const params:any = this.route.snapshot.params;
						if(params.slug){
							this.title = decodeURIComponent(params.slug).toLocaleUpperCase();
							this.title = this.title.indexOf('?') > -1 ? this.title.split('?')[0] : this.title;
							this.title = this.title.split('-').join(" ");
							this.titleLink = this.title.length > this.titleCharacterLimit ? this.title.substring(0, this.titleCharacterLimit) + '...' : this.title;
							this.links.push({
								path: '',
								name: this.titleLink,
								isLink: false,
							});
						}
					}
				} else if(component.name === 'ServiceDetailComponent'){
					if(params.type){
						if(params.type === 'visa'){
							this.links.push(visa_service);
						}
						if(params.type === 'other'){
							this.links.push(other_service);
						}
					}
					if(this.route.snapshot.params){
						const params:any = this.route.snapshot.params;
						if(params.slug){
							this.title = decodeURIComponent(params.slug).toLocaleUpperCase();
							this.title = this.title.indexOf('?') > -1 ? this.title.split('?')[0] : this.title;
							this.title = this.title.split('-').join(" ");
							this.titleLink = this.title.length > this.titleCharacterLimit ? this.title.substring(0, this.titleCharacterLimit) + '...' : this.title;
							this.links.push({
								path: '',
								name: this.titleLink,
								isLink: false,
							});
						}
					}
				}else {
					this.title = this.title.indexOf('?') > -1 ? this.title.split('?')[0] : this.title;
					this.links.push({
						path: '',
						name: this.title,
						isLink: false,
					});
				}
			}
		  });

	}

	ngOnInit(): void {
	}

}
