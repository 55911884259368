import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
	HeaderComponent,
	FooterComponent,
	BreadcrumbComponent,
	HomeComponent,
	ServiceComponent,
	ServiceDetailComponent,
	BlogComponent,
	BlogDetailComponent,
	AboutUsComponent,
	ContactUsComponent,
	PrivacyPolicyComponent,
	TermsConditionsComponent,
	FaqComponent,
	GalleryComponent,
	GalleryDetailComponent,
	TestimonialComponent,
} from './components';
import {
	Error404Component,
	MainComponent,
} from './container';

import { AuthGuard, ModifyMetaGuard } from './guard';
import { HashLocationStrategy, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { EductionService } from './components/eductionService/eductionService.component';
const routes: Routes = [
	{ path: '', redirectTo: '/home', pathMatch: 'full', resolve: [ModifyMetaGuard] },
	// full container
	{ path: '', component: MainComponent, children: [
		{
			path: 'home',component: HomeComponent, canActivate: [ModifyMetaGuard],
			data: {
				title: 'Divine Migration | Home',
				subject: 'Divine, Visa Service, Other Service, Student Visa, Migration, Australiya Migration',
				keywords: 'Divine, Visa Service, Other Service, Student Visa, Migration, Australiya Migration',
				descrption: 'Divine Migration Is Visa Service Provider',
				robots: 'index, follow',
				ogUrl: 'http://divine.in/',
				ogTitle: 'Divine Migration | Home',
				ogDescription: 'Divine Migration Is Visa Service Provider',
				ogImage: 'http://divine.in/assets/icons/app-icon-128x128.png',
				twTitle: 'Divine Migration | Home',
				twDescription: 'Divine Migration Is Visa Service Provider',
				schema: {
					"@context": "http://schema.org",
					"@id": "http://divine.in/",
					"@type": "LocalBusiness",
					name: "Octoweb Infotech",
					url: "http://divine.in/",
					image: "http://divine.in/assets/images/assets/img/LOGO-240x64.png",
					telephone: "+91 932 707 2722",
					address: {
						"@type": "PostalAddress",
						streetAddress: "55-D-GULAB VIHAR PRIVATE PLOTS, BEHIND BIG BAZAAR, 150FT RING ROAD",
						addressLocality: "USA",
						postalCode: "36051",
						addressCountry: "USA",
						addressRegion: "California"
					},
					priceRange: "10000-10000000",
					sameAs: [
						"http://www.facebook.com/maas-choice/",
						"http://www.linkedin.com/company/maas-choice/",
						"http://www.twitter.com/maas-choice"
					],
					openingHoursSpecification: [
						{
							"@type": "OpeningHoursSpecification",
							dayOfWeek: [
								"Monday",
								"Tuesday",
								"Wednesday",
								"Thursday",
								"Friday"
							],
							opens: "09:00",
							closes: "19:00"
						},
						{
							"@type": "OpeningHoursSpecification",
							dayOfWeek: ["Saturday"],
							opens: "09:00",
							closes: "13:00"
						}
					]
				},
			}
		},

		{
			path: 'eduction-service', component: EductionService, canActivate: [ModifyMetaGuard],
			data: {
				title: 'Divine Migration | Visa Service',
				subject: 'Divine Migration Is Visa Service Provider & Migration Helper',
				keywords: 'Divine, Visa Service, Other Service, Student Visa, Migration, Australiya Migration',
				descrption: 'Divine Migration Is Visa Service Provider',
				robots: 'index, follow',
				ogUrl: 'http://divine.in/visa-service',
				ogTitle: 'Divine Migration | Visa Service',
				ogDescription: 'Divine Migration Is Visa Service Provider',
				ogImage: 'http://divine.in/assets/icons/app-icon-128x128.png',
				twTitle: 'Divine Migration | Visa Service',
				twDescription: 'Divine Migration Is Visa Service Provider',
				schema: {
					"@context": "http://schema.org",
					"@id": "http://divine.in/visa-service",
					"@type": "LocalBusiness",
					name: "Octoweb Infotech",
					url: "http://divine.in/visa-service",
					image: "http://divine.in/assets/icons/app-icon-128x128.png",
					telephone: "+91 932 707 2722",
					address: {
						"@type": "PostalAddress",
						streetAddress: "55-D-GULAB VIHAR PRIVATE PLOTS, BEHIND BIG BAZAAR, 150FT RING ROAD",
						addressLocality: "USA",
						postalCode: "36051",
						addressCountry: "USA",
						addressRegion: "California"
					},
					priceRange: "10000-10000000",
					sameAs: [
						"http://www.facebook.com/maas-choice/",
						"http://www.linkedin.com/company/maas-choice/",
						"http://www.twitter.com/maas-choice"
					],
					openingHoursSpecification: [
						{
							"@type": "OpeningHoursSpecification",
							dayOfWeek: [
								"Monday",
								"Tuesday",
								"Wednesday",
								"Thursday",
								"Friday"
							],
							opens: "09:00",
							closes: "19:00"
						},
						{
							"@type": "OpeningHoursSpecification",
							dayOfWeek: ["Saturday"],
							opens: "09:00",
							closes: "13:00"
						}
					]
				},
			}
		},
		{
			path: 'immigration-service', component: ServiceComponent, canActivate: [ModifyMetaGuard],
			data: {
				title: 'Divine Migration | Other Service',
				subject: 'Divine Migration Is Visa Service Provider & Migration Helper',
				keywords: 'Divine, Visa Service, Other Service, Student Visa, Migration, Australiya Migration',
				descrption: 'Divine Migration Is Visa Service Provider',
				robots: 'index, follow',
				ogUrl: 'http://divine.in/service',
				ogTitle: 'Divine Migration | Other Service',
				ogDescription: 'Divine Migration Is Visa Service Provider',
				ogImage: 'http://divine.in/assets/icons/app-icon-128x128.png',
				twTitle: 'Divine Migration | Other Service',
				twDescription: 'Divine Migration Is Visa Service Provider',
				schema: {
					"@context": "http://schema.org",
					"@id": "http://divine.in/service",
					"@type": "LocalBusiness",
					name: "Octoweb Infotech",
					url: "http://divine.in/service",
					image: "http://divine.in/assets/icons/app-icon-128x128.png",
					telephone: "+91 932 707 2722",
					address: {
						"@type": "PostalAddress",
						streetAddress: "55-D-GULAB VIHAR PRIVATE PLOTS, BEHIND BIG BAZAAR, 150FT RING ROAD",
						addressLocality: "USA",
						postalCode: "36051",
						addressCountry: "USA",
						addressRegion: "California"
					},
					priceRange: "10000-10000000",
					sameAs: [
						"http://www.facebook.com/maas-choice/",
						"http://www.linkedin.com/company/maas-choice/",
						"http://www.twitter.com/maas-choice"
					],
					openingHoursSpecification: [
						{
							"@type": "OpeningHoursSpecification",
							dayOfWeek: [
								"Monday",
								"Tuesday",
								"Wednesday",
								"Thursday",
								"Friday"
							],
							opens: "09:00",
							closes: "19:00"
						},
						{
							"@type": "OpeningHoursSpecification",
							dayOfWeek: ["Saturday"],
							opens: "09:00",
							closes: "13:00"
						}
					]
				},
			}
		},
		{
			path: 'service/:slug', component: ServiceDetailComponent, canActivate: [ModifyMetaGuard],
			data: {
				title: 'Divine Migration | Service Details',
				subject: 'Divine Migration Is Visa Service Provider & Migration Helper',
				keywords: 'Divine, Visa Service, Other Service, Student Visa, Migration, Australiya Migration',
				descrption: 'Divine Migration Is Visa Service Provider',
				robots: 'index, follow',
				ogUrl: 'http://divine.in/service/:slug',
				ogTitle: 'Divine Migration | Service Details',
				ogDescription: 'Divine Migration Is Visa Service Provider',
				ogImage: 'http://divine.in/assets/icons/app-icon-128x128.png',
				twTitle: 'Divine Migration | Service Details',
				twDescription: 'Divine Migration Is Visa Service Provider',
				schema: {
					"@context": "http://schema.org",
					"@id": "http://divine.in/service/:slug",
					"@type": "LocalBusiness",
					name: "Octoweb Infotech",
					url: "http://divine.in/service/:slug",
					image: "http://divine.in/assets/icons/app-icon-128x128.png",
					telephone: "+91 932 707 2722",
					address: {
						"@type": "PostalAddress",
						streetAddress: "55-D-GULAB VIHAR PRIVATE PLOTS, BEHIND BIG BAZAAR, 150FT RING ROAD",
						addressLocality: "USA",
						postalCode: "36051",
						addressCountry: "USA",
						addressRegion: "California"
					},
					priceRange: "10000-10000000",
					sameAs: [
						"http://www.facebook.com/maas-choice/",
						"http://www.linkedin.com/company/maas-choice/",
						"http://www.twitter.com/maas-choice"
					],
					openingHoursSpecification: [
						{
							"@type": "OpeningHoursSpecification",
							dayOfWeek: [
								"Monday",
								"Tuesday",
								"Wednesday",
								"Thursday",
								"Friday"
							],
							opens: "09:00",
							closes: "19:00"
						},
						{
							"@type": "OpeningHoursSpecification",
							dayOfWeek: ["Saturday"],
							opens: "09:00",
							closes: "13:00"
						}
					]
				},
			}
		},
		{
			path: 'blog', component: BlogComponent, canActivate: [ModifyMetaGuard],
			// path: 'blog', component: BlogComponent,
			data: {
				title: 'Divine Migration | Blog',
				subject: 'Divine Migration Is Visa Service Provider & Migration Helper',
				keywords: 'Divine, Visa Service, Other Service, Student Visa, Migration, Australiya Migration',
				descrption: 'Divine Migration Is Visa Service Provider',
				robots: 'index, follow',
				ogUrl: 'http://divine.in/blog',
				ogTitle: 'Divine Migration | Blog',
				ogDescription: 'Divine Migration Is Visa Service Provider',
				ogImage: 'http://divine.in/assets/icons/app-icon-128x128.png',
				twTitle: 'Divine Migration | Blog',
				twDescription: 'Divine Migration Is Visa Service Provider',
				schema: {
					"@context": "http://schema.org",
					"@id": "http://divine.in/blog",
					"@type": "LocalBusiness",
					name: "Octoweb Infotech",
					url: "http://divine.in/blog",
					image: "http://divine.in/assets/icons/app-icon-128x128.png",
					telephone: "+91 932 707 2722",
					address: {
						"@type": "PostalAddress",
						streetAddress: "55-D-GULAB VIHAR PRIVATE PLOTS, BEHIND BIG BAZAAR, 150FT RING ROAD",
						addressLocality: "USA",
						postalCode: "36051",
						addressCountry: "USA",
						addressRegion: "California"
					},
					priceRange: "10000-10000000",
					sameAs: [
						"http://www.facebook.com/maas-choice/",
						"http://www.linkedin.com/company/maas-choice/",
						"http://www.twitter.com/maas-choice"
					],
					openingHoursSpecification: [
						{
							"@type": "OpeningHoursSpecification",
							dayOfWeek: [
								"Monday",
								"Tuesday",
								"Wednesday",
								"Thursday",
								"Friday"
							],
							opens: "09:00",
							closes: "19:00"
						},
						{
							"@type": "OpeningHoursSpecification",
							dayOfWeek: ["Saturday"],
							opens: "09:00",
							closes: "13:00"
						}
					]
				},
			}
		},
		{
			path: 'blog/:slug', component: BlogDetailComponent, canActivate: [ModifyMetaGuard],
			data: {
				title: 'Divine Migration | Blog Details',
				subject: 'Divine Migration Is Visa Service Provider & Migration Helper',
				keywords: 'Divine, Visa Service, Other Service, Student Visa, Migration, Australiya Migration',
				descrption: 'Divine Migration Is Visa Service Provider',
				robots: 'index, follow',
				ogUrl: 'http://divine.in/blog/:slug',
				ogTitle: 'Divine Migration | Blog Details',
				ogDescription: 'Divine Migration Is Visa Service Provider',
				ogImage: 'http://divine.in/assets/icons/app-icon-128x128.png',
				twTitle: 'Divine Migration | Blog Details',
				twDescription: 'Divine Migration Is Visa Service Provider',
				schema: {
					"@context": "http://schema.org",
					"@id": "http://divine.in/blog/:slug",
					"@type": "LocalBusiness",
					name: "Octoweb Infotech",
					url: "http://divine.in/blog/:slug",
					image: "http://divine.in/assets/icons/app-icon-128x128.png",
					telephone: "+91 932 707 2722",
					address: {
						"@type": "PostalAddress",
						streetAddress: "55-D-GULAB VIHAR PRIVATE PLOTS, BEHIND BIG BAZAAR, 150FT RING ROAD",
						addressLocality: "USA",
						postalCode: "36051",
						addressCountry: "USA",
						addressRegion: "California"
					},
					priceRange: "10000-10000000",
					sameAs: [
						"http://www.facebook.com/maas-choice/",
						"http://www.linkedin.com/company/maas-choice/",
						"http://www.twitter.com/maas-choice"
					],
					openingHoursSpecification: [
						{
							"@type": "OpeningHoursSpecification",
							dayOfWeek: [
								"Monday",
								"Tuesday",
								"Wednesday",
								"Thursday",
								"Friday"
							],
							opens: "09:00",
							closes: "19:00"
						},
						{
							"@type": "OpeningHoursSpecification",
							dayOfWeek: ["Saturday"],
							opens: "09:00",
							closes: "13:00"
						}
					]
				},
			}
		},
		{
			path: 'testimonial', component: TestimonialComponent, canActivate: [ModifyMetaGuard],
			data: {
				title: 'Divine Migration | Testimonial',
				subject: 'Divine Migration Is Visa Service Provider & Migration Helper',
				keywords: 'Divine, Visa Service, Other Service, Student Visa, Migration, Australiya Migration',
				descrption: 'Divine Migration Is Visa Service Provider',
				robots: 'index, follow',
				ogUrl: 'http://divine.in/testimonial',
				ogTitle: 'Divine Migration | Testimonial',
				ogDescription: 'Divine Migration Is Visa Service Provider',
				ogImage: 'http://divine.in/assets/icons/app-icon-128x128.png',
				twTitle: 'Divine Migration | Testimonial',
				twDescription: 'Divine Migration Is Visa Service Provider',
				schema: {
					"@context": "http://schema.org",
					"@id": "http://divine.in/testimonial",
					"@type": "LocalBusiness",
					name: "Octoweb Infotech",
					url: "http://divine.in/testimonial",
					image: "http://divine.in/assets/icons/app-icon-128x128.png",
					telephone: "+91 932 707 2722",
					address: {
						"@type": "PostalAddress",
						streetAddress: "55-D-GULAB VIHAR PRIVATE PLOTS, BEHIND BIG BAZAAR, 150FT RING ROAD",
						addressLocality: "USA",
						postalCode: "36051",
						addressCountry: "USA",
						addressRegion: "California"
					},
					priceRange: "10000-10000000",
					sameAs: [
						"http://www.facebook.com/maas-choice/",
						"http://www.linkedin.com/company/maas-choice/",
						"http://www.twitter.com/maas-choice"
					],
					openingHoursSpecification: [
						{
							"@type": "OpeningHoursSpecification",
							dayOfWeek: [
								"Monday",
								"Tuesday",
								"Wednesday",
								"Thursday",
								"Friday"
							],
							opens: "09:00",
							closes: "19:00"
						},
						{
							"@type": "OpeningHoursSpecification",
							dayOfWeek: ["Saturday"],
							opens: "09:00",
							closes: "13:00"
						}
					]
				},
			}
		},
		{
			path: 'gallery', component: GalleryComponent, canActivate: [ModifyMetaGuard],
			data: {
				title: 'Divine Migration | Gallery',
				subject: 'Divine Migration Is Visa Service Provider & Migration Helper',
				keywords: 'Divine, Visa Service, Other Service, Student Visa, Migration, Australiya Migration',
				descrption: 'Divine Migration Is Visa Service Provider',
				robots: 'index, follow',
				ogUrl: 'http://divine.in/gallery',
				ogTitle: 'Divine Migration | Gallery',
				ogDescription: 'Divine Migration Is Visa Service Provider',
				ogImage: 'http://divine.in/assets/icons/app-icon-128x128.png',
				twTitle: 'Divine Migration | Gallery',
				twDescription: 'Divine Migration Is Visa Service Provider',
				schema: {
					"@context": "http://schema.org",
					"@id": "http://divine.in/gallery",
					"@type": "LocalBusiness",
					name: "Octoweb Infotech",
					url: "http://divine.in/gallery",
					image: "http://divine.in/assets/icons/app-icon-128x128.png",
					telephone: "+91 932 707 2722",
					address: {
						"@type": "PostalAddress",
						streetAddress: "55-D-GULAB VIHAR PRIVATE PLOTS, BEHIND BIG BAZAAR, 150FT RING ROAD",
						addressLocality: "USA",
						postalCode: "36051",
						addressCountry: "USA",
						addressRegion: "California"
					},
					priceRange: "10000-10000000",
					sameAs: [
						"http://www.facebook.com/maas-choice/",
						"http://www.linkedin.com/company/maas-choice/",
						"http://www.twitter.com/maas-choice"
					],
					openingHoursSpecification: [
						{
							"@type": "OpeningHoursSpecification",
							dayOfWeek: [
								"Monday",
								"Tuesday",
								"Wednesday",
								"Thursday",
								"Friday"
							],
							opens: "09:00",
							closes: "19:00"
						},
						{
							"@type": "OpeningHoursSpecification",
							dayOfWeek: ["Saturday"],
							opens: "09:00",
							closes: "13:00"
						}
					]
				},
			}
		},
		{
			path: 'gallery/:slug', component: GalleryDetailComponent, canActivate: [ModifyMetaGuard],
			data: {
				title: 'Divine Migration | Gallery Details',
				subject: 'Divine Migration Is Visa Service Provider & Migration Helper',
				keywords: 'Divine, Visa Service, Other Service, Student Visa, Migration, Australiya Migration',
				descrption: 'Divine Migration Is Visa Service Provider',
				robots: 'index, follow',
				ogUrl: 'http://divine.in/gallery:slug',
				ogTitle: 'Divine Migration | Gallery Details',
				ogDescription: 'Divine Migration Is Visa Service Provider',
				ogImage: 'http://divine.in/assets/icons/app-icon-128x128.png',
				twTitle: 'Divine Migration | Gallery Details',
				twDescription: 'Divine Migration Is Visa Service Provider',
				schema: {
					"@context": "http://schema.org",
					"@id": "http://divine.in/gallery:slug",
					"@type": "LocalBusiness",
					name: "Octoweb Infotech",
					url: "http://divine.in/gallery:slug",
					image: "http://divine.in/assets/icons/app-icon-128x128.png",
					telephone: "+91 932 707 2722",
					address: {
						"@type": "PostalAddress",
						streetAddress: "55-D-GULAB VIHAR PRIVATE PLOTS, BEHIND BIG BAZAAR, 150FT RING ROAD",
						addressLocality: "USA",
						postalCode: "36051",
						addressCountry: "USA",
						addressRegion: "California"
					},
					priceRange: "10000-10000000",
					sameAs: [
						"http://www.facebook.com/maas-choice/",
						"http://www.linkedin.com/company/maas-choice/",
						"http://www.twitter.com/maas-choice"
					],
					openingHoursSpecification: [
						{
							"@type": "OpeningHoursSpecification",
							dayOfWeek: [
								"Monday",
								"Tuesday",
								"Wednesday",
								"Thursday",
								"Friday"
							],
							opens: "09:00",
							closes: "19:00"
						},
						{
							"@type": "OpeningHoursSpecification",
							dayOfWeek: ["Saturday"],
							opens: "09:00",
							closes: "13:00"
						}
					]
				},
			}
		},

		//static pages
		{
			path: 'contact-us',component: ContactUsComponent, canActivate: [ModifyMetaGuard],
			data: {
				title: 'Divine Migration | Contact Us',
				subject: 'Contact Octoweb Infotech company for Website Development, Mobile Application Development, Database Management, UI UX Design',
				keywords: 'Divine, Visa Service, Other Service, Student Visa, Migration, Australiya Migration, get in touch',
				descrption: 'Octoweb Infotech is a software development company offering Website Development, Mobile Application Development, Database Management, UI UX Design, etc.',
				robots: 'index, follow',
				ogUrl: 'http://divine.in/contact-us',
				ogTitle: 'Divine Migration | Contact Us',
				ogDescription: 'Octoweb Infotech is a software development company offering Website Development, Mobile Application Development, Database Management, UI UX Design, etc.',
				ogImage: 'http://divine.in/assets/icons/app-icon-128x128.png',
				twTitle: 'Divine Migration | Contact Us',
				twDescription: 'Octoweb Infotech is a software development company offering Website Development, Mobile Application Development, Database Management, UI UX Design, etc.',
				schema: {
					"@context": "http://schema.org",
					"@id": "http://divine.in/contact-us",
					"@type": "LocalBusiness",
					name: "Octoweb Infotech",
					url: "http://divine.in/contact-us",
					image: "http://divine.in/assets/icons/app-icon-128x128.png",
					telephone: "+91 932 707 2722",
					address: {
						"@type": "PostalAddress",
						streetAddress: "55-D-GULAB VIHAR PRIVATE PLOTS, BEHIND BIG BAZAAR, 150FT RING ROAD",
						addressLocality: "USA",
						postalCode: "36051",
						addressCountry: "USA",
						addressRegion: "California"
					},
					priceRange: "10000-10000000",
					sameAs: [
						"http://www.facebook.com/maas-choice/",
						"http://www.linkedin.com/company/maas-choice/",
						"http://www.twitter.com/maas-choice"
					],
					openingHoursSpecification: [
						{
							"@type": "OpeningHoursSpecification",
							dayOfWeek: [
								"Monday",
								"Tuesday",
								"Wednesday",
								"Thursday",
								"Friday"
							],
							opens: "09:00",
							closes: "19:00"
						},
						{
							"@type": "OpeningHoursSpecification",
							dayOfWeek: ["Saturday"],
							opens: "09:00",
							closes: "13:00"
						}
					]
				},
			}
		},
		{
			path: 'about-us', component: AboutUsComponent, canActivate: [ModifyMetaGuard],
			data: {
				title: 'Divine Migration | About Us',
				subject: 'Divine Migration Is Visa Service Provider & Migration Helper',
				keywords: 'Divine, Visa Service, Other Service, Student Visa, Migration, Australiya Migration',
				descrption: 'Divine Migration Is Visa Service Provider',
				robots: 'index, follow',
				ogUrl: 'http://divine.in/about-us',
				ogTitle: 'Divine Migration | About Us',
				ogDescription: 'Divine Migration Is Visa Service Provider',
				ogImage: 'http://divine.in/assets/icons/app-icon-128x128.png',
				twTitle: 'Divine Migration | About Us',
				twDescription: 'Divine Migration Is Visa Service Provider',
				schema: {
					"@context": "http://schema.org",
					"@id": "http://divine.in/about-us",
					"@type": "LocalBusiness",
					name: "Octoweb Infotech",
					url: "http://divine.in/about-us",
					image: "http://divine.in/assets/icons/app-icon-128x128.png",
					telephone: "+91 932 707 2722",
					address: {
						"@type": "PostalAddress",
						streetAddress: "55-D-GULAB VIHAR PRIVATE PLOTS, BEHIND BIG BAZAAR, 150FT RING ROAD",
						addressLocality: "USA",
						postalCode: "36051",
						addressCountry: "USA",
						addressRegion: "California"
					},
					priceRange: "10000-10000000",
					sameAs: [
						"http://www.facebook.com/maas-choice/",
						"http://www.linkedin.com/company/maas-choice/",
						"http://www.twitter.com/maas-choice"
					],
					openingHoursSpecification: [
						{
							"@type": "OpeningHoursSpecification",
							dayOfWeek: [
								"Monday",
								"Tuesday",
								"Wednesday",
								"Thursday",
								"Friday"
							],
							opens: "09:00",
							closes: "19:00"
						},
						{
							"@type": "OpeningHoursSpecification",
							dayOfWeek: ["Saturday"],
							opens: "09:00",
							closes: "13:00"
						}
					]
				},
			}
		},
		{
			path: 'faq', component: FaqComponent, canActivate: [ModifyMetaGuard],
			data: {
				title: 'Divine Migration | FAQ',
				subject: 'Divine Migration Is Visa Service Provider & Migration Helper',
				keywords: 'Divine, Visa Service, Other Service, Student Visa, Migration, Australiya Migration',
				descrption: 'Divine Migration Is Visa Service Provider',
				robots: 'index, follow',
				ogUrl: 'http://divine.in/faq',
				ogTitle: 'Divine Migration | FAQ',
				ogDescription: 'Divine Migration Is Visa Service Provider',
				ogImage: 'http://divine.in/assets/icons/app-icon-128x128.png',
				twTitle: 'Divine Migration | FAQ',
				twDescription: 'Divine Migration Is Visa Service Provider',
				schema: {
					"@context": "http://schema.org",
					"@id": "http://divine.in/faq",
					"@type": "LocalBusiness",
					name: "Octoweb Infotech",
					url: "http://divine.in/faq",
					image: "http://divine.in/assets/icons/app-icon-128x128.png",
					telephone: "+91 932 707 2722",
					address: {
						"@type": "PostalAddress",
						streetAddress: "55-D-GULAB VIHAR PRIVATE PLOTS, BEHIND BIG BAZAAR, 150FT RING ROAD",
						addressLocality: "USA",
						postalCode: "36051",
						addressCountry: "USA",
						addressRegion: "California"
					},
					priceRange: "10000-10000000",
					sameAs: [
						"http://www.facebook.com/maas-choice/",
						"http://www.linkedin.com/company/maas-choice/",
						"http://www.twitter.com/maas-choice"
					],
					openingHoursSpecification: [
						{
							"@type": "OpeningHoursSpecification",
							dayOfWeek: [
								"Monday",
								"Tuesday",
								"Wednesday",
								"Thursday",
								"Friday"
							],
							opens: "09:00",
							closes: "19:00"
						},
						{
							"@type": "OpeningHoursSpecification",
							dayOfWeek: ["Saturday"],
							opens: "09:00",
							closes: "13:00"
						}
					]
				},
			}
		},

		{
			path: 'terms-and-conditioins', component: TermsConditionsComponent, canActivate: [ModifyMetaGuard],
			data: {
				title: 'Divine Migration | Terms & Conditions',
				subject: 'Divine Migration Is Visa Service Provider & Migration Helper',
				keywords: 'Divine, Visa Service, Other Service, Student Visa, Migration, Australiya Migration',
				descrption: 'Divine Migration Is Visa Service Provider',
				robots: 'index, follow',
				ogUrl: 'http://divine.in/terms-and-conditioins',
				ogTitle: 'Divine Migration | Terms & Conditions',
				ogDescription: 'Divine Migration Is Visa Service Provider',
				ogImage: 'http://divine.in/assets/icons/app-icon-128x128.png',
				twTitle: 'Divine Migration | Terms & Conditions',
				twDescription: 'Divine Migration Is Visa Service Provider',
				schema: {
					"@context": "http://schema.org",
					"@id": "http://divine.in/terms-and-conditioins",
					"@type": "LocalBusiness",
					name: "Octoweb Infotech",
					url: "http://divine.in/terms-and-conditioins",
					image: "http://divine.in/assets/icons/app-icon-128x128.png",
					telephone: "+91 932 707 2722",
					address: {
						"@type": "PostalAddress",
						streetAddress: "55-D-GULAB VIHAR PRIVATE PLOTS, BEHIND BIG BAZAAR, 150FT RING ROAD",
						addressLocality: "USA",
						postalCode: "36051",
						addressCountry: "USA",
						addressRegion: "California"
					},
					priceRange: "10000-10000000",
					sameAs: [
						"http://www.facebook.com/maas-choice/",
						"http://www.linkedin.com/company/maas-choice/",
						"http://www.twitter.com/maas-choice"
					],
					openingHoursSpecification: [
						{
							"@type": "OpeningHoursSpecification",
							dayOfWeek: [
								"Monday",
								"Tuesday",
								"Wednesday",
								"Thursday",
								"Friday"
							],
							opens: "09:00",
							closes: "19:00"
						},
						{
							"@type": "OpeningHoursSpecification",
							dayOfWeek: ["Saturday"],
							opens: "09:00",
							closes: "13:00"
						}
					]
				},
			}
		},

		{
			path: 'privacy-policy', component: PrivacyPolicyComponent, canActivate: [ModifyMetaGuard],
			data: {
				title: 'Divine Migration | Privacy Policy',
				subject: 'Divine Migration Is Visa Service Provider & Migration Helper',
				keywords: 'Divine, Visa Service, Other Service, Student Visa, Migration, Australiya Migration',
				descrption: 'Divine Migration Is Visa Service Provider',
				robots: 'index, follow',
				ogUrl: 'http://divine.in/privacy-policy',
				ogTitle: 'Divine Migration | Privacy Policy',
				ogDescription: 'Divine Migration Is Visa Service Provider',
				ogImage: 'http://divine.in/assets/icons/app-icon-128x128.png',
				twTitle: 'Divine Migration | Privacy Policy',
				twDescription: 'Divine Migration Is Visa Service Provider',
				schema: {
					"@context": "http://schema.org",
					"@id": "http://divine.in/privacy-policy",
					"@type": "LocalBusiness",
					name: "Octoweb Infotech",
					url: "http://divine.in/privacy-policy",
					image: "http://divine.in/assets/icons/app-icon-128x128.png",
					telephone: "+91 932 707 2722",
					address: {
						"@type": "PostalAddress",
						streetAddress: "55-D-GULAB VIHAR PRIVATE PLOTS, BEHIND BIG BAZAAR, 150FT RING ROAD",
						addressLocality: "USA",
						postalCode: "36051",
						addressCountry: "USA",
						addressRegion: "California"
					},
					priceRange: "10000-10000000",
					sameAs: [
						"http://www.facebook.com/maas-choice/",
						"http://www.linkedin.com/company/maas-choice/",
						"http://www.twitter.com/maas-choice"
					],
					openingHoursSpecification: [
						{
							"@type": "OpeningHoursSpecification",
							dayOfWeek: [
								"Monday",
								"Tuesday",
								"Wednesday",
								"Thursday",
								"Friday"
							],
							opens: "09:00",
							closes: "19:00"
						},
						{
							"@type": "OpeningHoursSpecification",
							dayOfWeek: ["Saturday"],
							opens: "09:00",
							closes: "13:00"
						}
					]
				},
			}
		},

		// 404
		{ path: 'not-found',  pathMatch: 'full', component: Error404Component, canActivate: [ModifyMetaGuard] }
	], canActivate: [ModifyMetaGuard] },
	// misc routes
	{ path: '**', redirectTo: '/not-found'},
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { useHash: false, scrollPositionRestoration: 'enabled', enableTracing: false, initialNavigation: 'enabledBlocking' })],
	providers: [
		{
			provide: LocationStrategy, useClass: PathLocationStrategy
		}
	],
	exports: [RouterModule]
})
export class AppRoutingModule { }
