<app-breadcrumb></app-breadcrumb>
<section>
	<div class="contact-us">
		<div class="container">
			<div class="row justify-content-center">
				<div class="col-xl-12">
					<div class="contact-title">
						<h1>Contact Us</h1>
					</div>
				</div>
				<div class="col-xl-12">
					<div class="contact-box">
						<div class="contact-content">
							<form class="form contact-form" (submit)="submitForm($event)"  [formGroup]="contactForm">
								<div class="row">
									<div class="col-md-6">
										<div class="form-group">
											<label for="name">Your Name*</label>
											<input name="name" id="name" formControlName="name" class="form-control" required/>
										</div>
									</div>
									<div class="col-md-6">
										<div class="form-group">
											<label for="email">Your Email*</label>
											<input name="email" id="email" formControlName="email" class="form-control" required/>
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col-md-6">
										<div class="form-group">
											<label for="mobile">Your Mobile*</label>
											<input type="number"  name="mobile" id="mobile" formControlName="mobile" class="form-control" required oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
											maxlength = "10"/>
											
										</div>
									</div>
									<div class="col-md-6">
										<div class="form-group">
											<label for="subject">Your Subject*</label>
											<input name="subject" id="subject" formControlName="subject" class="form-control" required/>
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col-md-12">
										<div class="form-group">
											<label for="message">Your Query*</label>
											<textarea rows="4" name="message" id="message" formControlName="message" class="form-control" required></textarea>
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col-md-12">
										<div class="form-group">
											<button class="btn btn-lg btn-theme" type="submit">Submit</button>
										</div>
									</div>
								</div>
							</form>
						</div>

					</div>
				</div>
			</div>
		</div>
		<div class="contanct-map">
			<!-- <iframe
				src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6302.412289382197!2d144.96305699806456!3d-37.832059605235564!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad646b5d2ba4df7%3A0x4045675218ccd90!2sMelbourne%20VIC%2C%20Australia!5e0!3m2!1sen!2sin!4v1652506851511!5m2!1sen!2sin"
				width="100%"
				height="450"
				style="border:0;"
				allowfullscreen=""
				loading="lazy"
				referrerpolicy="no-referrer-when-downgrade"
			></iframe> -->
		</div>
	</div>
</section>
