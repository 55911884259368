import { createAction, props } from '@ngrx/store';
import {
    Auth,
    Cart,
    Order,
    Review,
    Rights,
    imageUrl,
    mainUrl
} from 'src/app/store/models';
import { Company } from '.';
// company
export const GET_COMPANY = createAction('GET_COMPANY');
export const SET_COMPANY = createAction('SET_COMPANY', props<{company: typeof Company}>());
// session
export const GET_SESSION = createAction('GET_SESSION');
export const SET_SESSION = createAction('SET_SESSION', props<{session:any}>());
// auth
export const GET_CONFIG = createAction('GET_CONFIG');
export const GET_AUTH = createAction('GET_AUTH');
export const SET_AUTH = createAction('SET_AUTH', props<{auth: typeof Auth}>());
export const IS_LOGGEDIN = createAction('IS_LOGGEDIN');
export const LOG_OUT = createAction('LOG_OUT');
// set active page
export const GET_PAGE = createAction('GET_PAGE');
export const SET_PAGE = createAction('SET_PAGE', props<{name:string}>());
// set active route
export const GET_ROUTE = createAction('GET_PAGE');
export const SET_ROUTE = createAction('SET_PAGE', props<{url:string}>());
// set cart
export const GET_CART = createAction('GET_CART');
export const SET_CART = createAction('SET_CART', props<{cart:any}>());
// set order
export const GET_ORDER = createAction('GET_ORDER');
export const SET_ORDER = createAction('SET_ORDER', props<{order:any}>());
// set category
export const GET_CATEGORY = createAction('GET_CATEGORY');
export const SET_CATEGORY = createAction('SET_CATEGORY', props<{category:any}>());


