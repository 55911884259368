import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { HttpService, ToastService } from 'src/app/services';
// const dummyData:any = [
// 	{
// 		"_id": "625fe4f025881a317fdbb66f",
// 		"type": true,
// 		"status": true,
// 		"title": "Sed porttitor lectus nibh. Quisque velit nisi, pretium ut lacinia in, elementum id enim.",
// 		"slug": "sed porttitor lectus nibh. quisque velit nisi, pretium ut lacinia in, elementum id enim.",
// 		"description": "<p>Curabitur aliquet quam id dui posuere blandit. Cras ultricies ligula sed magna dictum porta. Praesent sapien massa, convallis a pellentesque nec, egestas non nisi. Cras ultricies ligula sed magna dictum porta. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Curabitur aliquet quam id dui posuere blandit. Vivamus suscipit tortor eget felis porttitor volutpat. Nulla quis lorem ut libero malesuada feugiat. Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem.</p>",
// 		"country": "India",
// 		"visa_type": "Student Visa",
// 		"author_image": "Testimonial/lmtHzD.jpeg",
// 		"createdAt": "2022-04-20T10:48:16.219Z",
// 		"updatedAt": "2022-04-20T10:48:16.219Z"
// 	},
// 	{
// 		"_id": "627dd990812a1817e81e160c",
// 		"type": false,
// 		"status": true,
// 		"title": "Curabitur aliquet quam id dui posuere blandit.",
// 		"slug": "curabitur-aliquet-quam-id-dui-posuere-blandit.",
// 		"description": "https://www.youtube.com/watch?v=A-iYga8kaFI",
// 		"country": "India",
// 		"visa_type": "Travel Visa",
// 		"author_image": "Testimonial/Cj4wmL.png",
// 		"createdAt": "2022-05-13T04:07:44.735Z",
// 		"updatedAt": "2022-05-13T04:21:03.497Z"
// 	},
// 	{
// 		"_id": "625fe4f025881a317fdbb66f",
// 		"type": true,
// 		"status": true,
// 		"title": "Sed porttitor lectus nibh. Quisque velit nisi, pretium ut lacinia in, elementum id enim.",
// 		"slug": "sed porttitor lectus nibh. quisque velit nisi, pretium ut lacinia in, elementum id enim.",
// 		"description": "<p>Curabitur aliquet quam id dui posuere blandit. Cras ultricies ligula sed magna dictum porta. Praesent sapien massa, convallis a pellentesque nec, egestas non nisi. Cras ultricies ligula sed magna dictum porta. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Curabitur aliquet quam id dui posuere blandit. Vivamus suscipit tortor eget felis porttitor volutpat. Nulla quis lorem ut libero malesuada feugiat. Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem.</p>",
// 		"country": "India",
// 		"visa_type": "Student Visa",
// 		"author_image": "Testimonial/lmtHzD.jpeg",
// 		"createdAt": "2022-04-20T10:48:16.219Z",
// 		"updatedAt": "2022-04-20T10:48:16.219Z"
// 	},
// 	{
// 		"_id": "625fe4f025881a317fdbb66f",
// 		"type": true,
// 		"status": true,
// 		"title": "Sed porttitor lectus nibh. Quisque velit nisi, pretium ut lacinia in, elementum id enim.",
// 		"slug": "sed porttitor lectus nibh. quisque velit nisi, pretium ut lacinia in, elementum id enim.",
// 		"description": "<p>Curabitur aliquet quam id dui posuere blandit. Cras ultricies ligula sed magna dictum porta. Praesent sapien massa, convallis a pellentesque nec, egestas non nisi. Cras ultricies ligula sed magna dictum porta. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Curabitur aliquet quam id dui posuere blandit. Vivamus suscipit tortor eget felis porttitor volutpat. Nulla quis lorem ut libero malesuada feugiat. Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem.</p>",
// 		"country": "India",
// 		"visa_type": "Student Visa",
// 		"author_image": "Testimonial/lmtHzD.jpeg",
// 		"createdAt": "2022-04-20T10:48:16.219Z",
// 		"updatedAt": "2022-04-20T10:48:16.219Z"
// 	},
// 	{
// 		"_id": "627dd990812a1817e81e160c",
// 		"type": false,
// 		"status": true,
// 		"title": "Curabitur aliquet quam id dui posuere blandit.",
// 		"slug": "curabitur-aliquet-quam-id-dui-posuere-blandit.",
// 		"description": "https://www.youtube.com/watch?v=A-iYga8kaFI",
// 		"country": "India",
// 		"visa_type": "Travel Visa",
// 		"author_image": "Testimonial/Cj4wmL.png",
// 		"createdAt": "2022-05-13T04:07:44.735Z",
// 		"updatedAt": "2022-05-13T04:21:03.497Z"
// 	},
// 	{
// 		"_id": "625fe4f025881a317fdbb66f",
// 		"type": true,
// 		"status": true,
// 		"title": "Sed porttitor lectus nibh. Quisque velit nisi, pretium ut lacinia in, elementum id enim.",
// 		"slug": "sed porttitor lectus nibh. quisque velit nisi, pretium ut lacinia in, elementum id enim.",
// 		"description": "<p>Curabitur aliquet quam id dui posuere blandit. Cras ultricies ligula sed magna dictum porta. Praesent sapien massa, convallis a pellentesque nec, egestas non nisi. Cras ultricies ligula sed magna dictum porta. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Curabitur aliquet quam id dui posuere blandit. Vivamus suscipit tortor eget felis porttitor volutpat. Nulla quis lorem ut libero malesuada feugiat. Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem.</p>",
// 		"country": "India",
// 		"visa_type": "Student Visa",
// 		"author_image": "Testimonial/lmtHzD.jpeg",
// 		"createdAt": "2022-04-20T10:48:16.219Z",
// 		"updatedAt": "2022-04-20T10:48:16.219Z"
// 	},
// 	{
// 		"_id": "625fe4f025881a317fdbb66f",
// 		"type": true,
// 		"status": true,
// 		"title": "Sed porttitor lectus nibh. Quisque velit nisi, pretium ut lacinia in, elementum id enim.",
// 		"slug": "sed porttitor lectus nibh. quisque velit nisi, pretium ut lacinia in, elementum id enim.",
// 		"description": "<p>Curabitur aliquet quam id dui posuere blandit. Cras ultricies ligula sed magna dictum porta. Praesent sapien massa, convallis a pellentesque nec, egestas non nisi. Cras ultricies ligula sed magna dictum porta. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Curabitur aliquet quam id dui posuere blandit. Vivamus suscipit tortor eget felis porttitor volutpat. Nulla quis lorem ut libero malesuada feugiat. Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem.</p>",
// 		"country": "India",
// 		"visa_type": "Student Visa",
// 		"author_image": "Testimonial/lmtHzD.jpeg",
// 		"createdAt": "2022-04-20T10:48:16.219Z",
// 		"updatedAt": "2022-04-20T10:48:16.219Z"
// 	},
// 	{
// 		"_id": "627dd990812a1817e81e160c",
// 		"type": false,
// 		"status": true,
// 		"title": "Curabitur aliquet quam id dui posuere blandit.",
// 		"slug": "curabitur-aliquet-quam-id-dui-posuere-blandit.",
// 		"description": "https://www.youtube.com/watch?v=A-iYga8kaFI",
// 		"country": "India",
// 		"visa_type": "Travel Visa",
// 		"author_image": "Testimonial/Cj4wmL.png",
// 		"createdAt": "2022-05-13T04:07:44.735Z",
// 		"updatedAt": "2022-05-13T04:21:03.497Z"
// 	},
// ];

@Component({
  selector: 'app-testimonial',
  templateUrl: './testimonial.component.html',
  styleUrls: ['./testimonial.component.scss']
})
export class TestimonialComponent implements OnInit {
	testimonials:any = [
		// {
		// 	"_id": "625fe4f025881a317fdbb66f",
		// 	"type": true,
		// 	"status": true,
		// 	"title": "Sed porttitor lectus nibh. Quisque velit nisi, pretium ut lacinia in, elementum id enim.",
		// 	"slug": "sed porttitor lectus nibh. quisque velit nisi, pretium ut lacinia in, elementum id enim.",
		// 	"description": "<p>Curabitur aliquet quam id dui posuere blandit. Cras ultricies ligula sed magna dictum porta. Praesent sapien massa, convallis a pellentesque nec, egestas non nisi. Cras ultricies ligula sed magna dictum porta. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Curabitur aliquet quam id dui posuere blandit. Vivamus suscipit tortor eget felis porttitor volutpat. Nulla quis lorem ut libero malesuada feugiat. Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem.</p>",
		// 	"country": "India",
		// 	"visa_type": "Student Visa",
		// 	"author_image": "Testimonial/lmtHzD.jpeg",
		// 	"createdAt": "2022-04-20T10:48:16.219Z",
		// 	"updatedAt": "2022-04-20T10:48:16.219Z"
		// },
		// {
		// 	"_id": "627dd990812a1817e81e160c",
		// 	"type": false,
		// 	"status": true,
		// 	"title": "Curabitur aliquet quam id dui posuere blandit.",
		// 	"slug": "curabitur-aliquet-quam-id-dui-posuere-blandit.",
		// 	"description": "https://www.youtube.com/watch?v=A-iYga8kaFI",
		// 	"country": "India",
		// 	"visa_type": "Travel Visa",
		// 	"author_image": "Testimonial/Cj4wmL.png",
		// 	"createdAt": "2022-05-13T04:07:44.735Z",
		// 	"updatedAt": "2022-05-13T04:21:03.497Z"
		// },
		// {
		// 	"_id": "625fe4f025881a317fdbb66f",
		// 	"type": true,
		// 	"status": true,
		// 	"title": "Sed porttitor lectus nibh. Quisque velit nisi, pretium ut lacinia in, elementum id enim.",
		// 	"slug": "sed porttitor lectus nibh. quisque velit nisi, pretium ut lacinia in, elementum id enim.",
		// 	"description": "<p>Curabitur aliquet quam id dui posuere blandit. Cras ultricies ligula sed magna dictum porta. Praesent sapien massa, convallis a pellentesque nec, egestas non nisi. Cras ultricies ligula sed magna dictum porta. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Curabitur aliquet quam id dui posuere blandit. Vivamus suscipit tortor eget felis porttitor volutpat. Nulla quis lorem ut libero malesuada feugiat. Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem.</p>",
		// 	"country": "India",
		// 	"visa_type": "Student Visa",
		// 	"author_image": "Testimonial/lmtHzD.jpeg",
		// 	"createdAt": "2022-04-20T10:48:16.219Z",
		// 	"updatedAt": "2022-04-20T10:48:16.219Z"
		// },
		// {
		// 	"_id": "627dd990812a1817e81e160c",
		// 	"type": false,
		// 	"status": true,
		// 	"title": "Curabitur aliquet quam id dui posuere blandit.",
		// 	"slug": "curabitur-aliquet-quam-id-dui-posuere-blandit.",
		// 	"description": "https://www.youtube.com/watch?v=A-iYga8kaFI",
		// 	"country": "India",
		// 	"visa_type": "Travel Visa",
		// 	"author_image": "Testimonial/Cj4wmL.png",
		// 	"createdAt": "2022-05-13T04:07:44.735Z",
		// 	"updatedAt": "2022-05-13T04:21:03.497Z"
		// },
	];
	perPage:number = 6;
	pageNo: number = 1;
	loading: boolean = false;
	hasPage: boolean = true;
	constructor(
		public httpService: HttpService,
		public toaster: ToastService,
		private http: HttpClient,
		private metaTagService: Meta,
		private route: ActivatedRoute,
	) { }

	ngOnInit(): void {
		this.getTestimonialItems();
	}

	getArray() {
		const length = this.testimonials.length > 0 ? this.testimonials.length : 4;
		let array = Array(length).fill(0).map((x,i)=>i+1);
		return array;
	}
	// getDemoData() {
	// 	let i = 2;
	// 	dummyData.map((item:any) => {
	// 		if(i < this.perPage){
	// 			this.testimonials.push(dummyData.splice(Math.floor(Math.random() * dummyData.length - 1), 1)[0]);
	// 		} else return;
	// 		i++;
	// 	})
	// 	this.loading = false;
	// }

	getTestimonialItems() {
		// setTimeout(() => {
		// 	this.getDemoData();
		// }, 1500)
		this.http.post(this.httpService.mainUrl + 'getTestimonials', {pageNo: this.pageNo, perPage: this.perPage}).subscribe((res: any) => {
			if(res.status === '1'){
				const data = res.data && Array.isArray(res.data) ? res.data : [];
				if(data.length < this.perPage){
					this.hasPage = false;
				}
				if(this.pageNo === 1){
					this.testimonials = data;
				}else {
					data.map((item:any) => {
						this.testimonials.push(item);
					})
				}
			}
		})
	}

	loadMoreItem(event:any){
		this.loading = false;
		event.preventDefault();
		this.pageNo = this.pageNo + 1;
		this.getTestimonialItems();
	}


	getYoutubeEmbededLink(url:string) {
		//https://www.youtube.com/watch?v=A-iYga8kaFI		//format
		var video_id = url.split('v=')[1];
		var ampersandPosition = video_id.indexOf('&');
		if(ampersandPosition != -1) {
			video_id = video_id.substring(0, ampersandPosition);
		}
		return this.httpService.getSafeURL(`https://www.youtube.com/embed/${video_id}`, 'RESOURCE');
	}
}
