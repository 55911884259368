import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";

@Injectable({
	providedIn: 'root'
})
export class JsonSchemaService {
	static scriptType = 'application/json+ld';
	static websiteSchema = (url?: string, name?: string) => {
		return {
			'@context': 'http://schema.org',
			'@type'      : 'WebSite',
			url          : url || 'https:\/\/google.com',
			name         : name || 'Google',
			"sameAs": ["https://facebook.com/google",
				   "https://instagram.com/google",
				   "https://twitter.com/google"]
			};
	};

	static orgSchema = () => ({
        "@context": "https://schema.org",
        "@id": "https://divine.in/divine/",
        "@type": "LocalBusiness",
        name: "Divine Migration",
        url: "http://165.232.180.214/divine/#/",
        image: "http://165.232.180.214/divine/assets/images/logo_header.svg",
        telephone: "+1 123 456 7890",
        address: {
            "@type": "PostalAddress",
            streetAddress: "ABC, First Evenue, USA",
            addressLocality: "USA",
            postalCode: "36051",
            addressCountry: "USA",
            addressRegion: "California"
        },
        priceRange: "10000-1000000",
        sameAs: [
            "https://www.facebook.com/rktechnology/",
            "https://www.linkedin.com/company/rk-technology/",
            "https://www.twitter.com/rktechnology"
        ],
        openingHoursSpecification: [
            {
                "@type": "OpeningHoursSpecification",
                dayOfWeek: [
                    "Monday",
                    "Tuesday",
                    "Wednesday",
                    "Thursday",
                    "Friday"
                ],
                opens: "09:00",
                closes: "19:00"
            },
            {
                "@type": "OpeningHoursSpecification",
                dayOfWeek: ["Saturday"],
                opens: "09:00",
                closes: "13:00"
            }
        ]
    });

	constructor(@Inject(DOCUMENT) private _document: Document) {}

	removeStructuredData(): void {
		const els:any = [];
		[ 'structured-data', 'structured-data-org' ].forEach(c => {
			els.push(...Array.from(this._document.head.getElementsByClassName(c)));
		});
		els.forEach((el:any) => this._document.head.removeChild(el));
	}

	insertSchema(schema: Record<string, any>, className = 'structured-data'): void {
        // JsonSchemaService.orgSchema
		let script:any;
		let shouldAppend = false;
		if (this._document.head.getElementsByClassName(className).length) {
			script = this._document.head.getElementsByClassName(className)[0];
		} else {
			script = this._document.createElement('script');
			shouldAppend = true;
		}
		script.setAttribute('class', className);
		script.type = JsonSchemaService.scriptType;
		script.text = JSON.stringify(schema);
		if (shouldAppend) {
			this._document.head.appendChild(script);
		}
	}
}
